import React, { useEffect, useState } from 'react';
import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import TransferTypeDropDownList from './FormModules/TransferTypeDropDownList';
import TransferMediaDropDownList from './FormModules/TransferMediaDropDownList';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Hint } from '@progress/kendo-react-labels';
import ValidatedInput from '../FormModules/ValidatedInput';
import { requiredValidator } from '../FormModules/validators';
import UserContext from '../UserContext/UserContext';

export default function SearchTransferForm(props) {
  const { existingTransfer, onClose, onSubmit, onUpdate, onClear } = props;
  const { userPermissions } = React.useContext(UserContext);
  const [data, setData] = useState(
    existingTransfer || {
      description: '',
      organization: '',
      transferType: '',
      transferMedia: '',
      transferName: '',
    }
  );

  const onFormSubmit = () => {
    onSubmit(data);
    onClose();
  };
  const onClearFunc = () => {
    onClear();
    onClose();
    // onSubmit(data);
  };
  const updateValue = (e, name) => {
    setData((prevData) => ({
      ...prevData,
      [name]: name === 'transferFileSize' || name === 'fullTextFile' ? e.value : (e.value || '').trim(),
    }));
  };

  return (
    <Form
      initialValues={data}
      render={(fieldRenderProps) => {
        const transferType = fieldRenderProps.valueGetter('transferType');

        return (
          <div style={{ padding: '1rem' }}>
            <div className={'row'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Dept./Agency/Branch Name</label>
                <Field name={'organization'} onChange={(e) => updateValue(e, 'organization')} className="fsa-input fsa-field__item" component={ValidatedInput} />
              </FieldWrapper>
            </div>
            {console.log(existingTransfer, 'existingTransfer')}

            <div className={'row'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Transfer Name</label>
                <Field name={'transferName'} onChange={(e) => updateValue(e, 'transferName')} className="fsa-input fsa-field__item" component={ValidatedInput} />
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Transfer Type</label>
                <Field name={'transferType'} onChange={(e) => updateValue(e, 'transferType')} component={TransferTypeDropDownList} />
              </FieldWrapper>
              {/* <FieldWrapper className={'col-5'}>
                <label className="fsa-field__label">NARA Provided Transfer Number</label>
                <Field
                  name={'NARATransferNumber'}
                  onChange={(e) => updateValue(e, 'NARATransferNumber')}
                  disabled={transferType !== 'NARA'}
                  className="fsa-input fsa-field__item"
                  component={Input}
                />
                <Hint>From ARCIS if applicable</Hint>
              </FieldWrapper> */}
              {/* <FieldWrapper className={'col-5'}>
                <label className="fsa-field__label">File Code</label>
                <Field name={'fileCode'} onChange={(e) => updateValue(e, 'fileCode')} disabled={transferType !== 'NARA'} className="fsa-input fsa-field__item" component={Input} />
                <Hint>For NARA Transfers Only</Hint>
              </FieldWrapper> */}

              {/* <div className={'row mt-3'}> */}
              <FieldWrapper className={'offset-2 col-5'}>
                <label className="fsa-field__label">Hold Number</label>
                <Field
                  name={'holdNumber'}
                  onChange={(e) => updateValue(e, 'holdNumber')}
                  className="fsa-input fsa-field__item"
                  disabled={transferType !== 'Hold'}
                  component={Input}
                />
                <Hint>If applicable</Hint>
              </FieldWrapper>
              {/* </div> */}
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">General Description</label>
                <Field name={'description'} className="fsa-input fsa-field__item" onChange={(e) => updateValue(e, 'description')} component={ValidatedInput} />
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Transfer Media</label>
                <Field name={'transferMedia'} onChange={(e) => updateValue(e, 'transferMedia')} component={TransferMediaDropDownList} />
              </FieldWrapper>
              {/* {existingTransfer !== null && (
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Date of Transfer</label>
                  <Field name={'TransferDate'} onChange={(e) => updateValue(e, 'TransferDate')} className="fsa-field__item" component={FormDatepicker} />
                </FieldWrapper>
              )} */}
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Transfer File Size</label>
                <Field name={'transferFileSize'} onChange={(e) => updateValue(e, 'transferFileSize')} className="fsa-field__item" min={0} component={NumericTextBox} />
                <Hint>In Gigabytes</Hint>
              </FieldWrapper>

              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Full Text Files</label>
                <Field name={'fullTextFile'} onChange={(e) => updateValue(e, 'fullTextFile')} component={Checkbox} label={'Include Full Text Files'} />
                <Hint>If available</Hint>
              </FieldWrapper>
            </div>
            <div className={'row mt-4'}>
              <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                <button onClick={onFormSubmit} className="fsa-btn">
                  Search
                </button>
                <button onClick={onClearFunc} className="fsa-btn lite-btn">
                  Clear
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
