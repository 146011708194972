import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './InBasketPage.scss';
import moment from 'moment';
import LocationIcons from '../../fsa-style/img/svgs/1.svg';
import ReturnIcons from '../../fsa-style/img/svgs/3.svg';
import FileIcons from '../../fsa-style/img/svgs/5.svg';
import TopIcons from '../../fsa-style/img/svgs/6.svg';
// import SpredIcon from '../../fsa-style/img/svgs/spread.svg';
import FileCustomIcon from '../../fsa-style/img/svgs/file_custom.svg';
import Timer from '../TimerComponent/Timer';
import ManualIcons from '../../fsa-style/img/svgs/2.svg';
import LoadingSplash from '../LoadingSplash/LoadingSplash';
import High_msg from '../../fsa-style/img/svgs/high_message.png';
import { useDispatch } from 'react-redux';
import { getMessages, dismissMessage, getWorkgroups, getWorkgroupItems, getWorkgroupItemsByUserId, getThumbnailByBuowID, oktaLogin } from '../../services/inBasketService';

import { getMessagesCountNew, setBUOWDetailsInfo } from '../../services/actions/action';
import Spinner from 'react-activity/dist/Spinner';
import { v4 as uuidv4 } from 'uuid';
import notesIcon from './../../fsa-style/img/svgs/pushpin.svg';
import { useOktaAuth } from '@okta/okta-react';
import UserContext from '../UserContext/UserContext';
import { fixedPermissions } from '../../utils/helper/permissionFinder';
import { searchPageColumnsAPI } from '../../pages/Search/SearchServices';
import { getDoctype } from '../../services/suspenseQueueService';

// const url = 'https://images.template.net/wp-content/uploads/2018/03/Students-Technology-Concept-Paper-Template.jpg';

var _ = require('lodash');

const filters = [
  { name: 'Prioritized', value: 'prioritized' },
  { name: 'Arrival Time', value: 'arrivalTime' },
  { name: 'Work Type', value: 'workType' },
];
const InBasketPage = (props, { data }) => {
  let [workGroups, onUpdateWorkGroupList] = useState([]);
  let [workGroupItems, onUpdateWorkGroupItems] = useState([]);
  let [workGroupItems2, onUpdateWorkGroupItems2] = useState([]);
  let [dummyWorkGroup, setDummyWorkGroup] = useState([]);
  let [dummyNonWorkGroup, setDummyNonWorkGroup] = useState([]);
  const [countForNonWork, setCountForNonWork] = useState(0);
  const [dataPresentForNonWork, setDataPresentForNonWork] = useState(true);
  const [updateMessageList, onUpdateMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inBasketFilter, setInBasketFilter] = useState('prioritized');
  const [page, setPage] = useState();
  // const { messages } = useSelector((state) => state);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [countHover, setCountHover] = useState(0);
  const { authState } = useOktaAuth();

  const { setUserInfo, setUserPermissions } = React.useContext(UserContext);
  useEffect(() => {
    async function data() {
      dispatch(setBUOWDetailsInfo([]));
      await getMessages().then((response1) => {
        if (response1?.data?.length > 0) {
          // messages.forEach((element) => {
          //   element.isDeleteStarted = false;
          // });
          // onUpdateMessageList(messages);
          response1.data.forEach((obj) => {
            obj.isDeleteStarted = false;
          });
          onUpdateMessageList(response1.data);
          dispatch(getMessagesCountNew());
        }
      });

      await getWorkgroups().then(async (response) => {
        for await (const obj of response.data) {
          let filter = '&filter=' + inBasketFilter;

          await getWorkgroupItems(obj.workgroupId, filter).then((response) => {
            return (
              (obj.workGroupItemList = response.data || []),
              (obj.isExpand = true),
              (obj.page = 0),
              (obj.countForWork = 0),
              (obj.selectedFilter = 'prioritized'),
              { ...obj },
              onUpdateWorkGroupItems(obj.workGroupItemList)
            );
          });
        }
        setIsLoading(false);
        onUpdateWorkGroupList(response.data);

        let workData = _.cloneDeep(response.data);
        workData.map((obj) => {
          return (obj.originalCount = obj.workGroupItemList.length), (obj.workGroupItemList = obj.workGroupItemList.slice(obj.page, obj.page + 5));
        });
        setDummyWorkGroup(workData);
        getthumbnailsForWorkgroups(workData);
        await getUserBuow(inBasketFilter);
      });
    }

    async function fetchData() {
      let userTOken = sessionStorage.getItem('userToken');
      let oktaSession = JSON.parse(sessionStorage.getItem('okta-token-storage'));
      if(Object.keys(oktaSession).length > 0 &&  oktaSession.accessToken.accessToken)
      if (oktaSession.accessToken.accessToken !== undefined && !userTOken) {
        let userData = await oktaLogin(oktaSession.accessToken.accessToken);
        // setUserPermissions(userData.data.rolePermissions)

        let x = userData.data.rolePermissions;
        let obj = {};
        if (x !== undefined && x.length > 0) {
          await x.map((i) => {
            if (fixedPermissions.includes(i) === true) {
              obj[i] = true;
            }
            return null;
          });

          setUserPermissions(obj);
        }
        await searchPageColumnsAPI().then(async (response) => {
          let columnNewArray = [
            {
              metaId: 0,
              field: 'SELECTED_FIELD',
              title: 'Select',
            },
          ];
          await response.data.map((column, idx) => {
            columnNewArray.push({
              metaId: '_id',
              field: 'key.object_specific.' + column.name.replace(' ', ''),
              title: column.name,
              dataType: 'text',
              showMenuIcon: 'true',
              sortType: '',
              data: column,
            });
            if (response.data.length === idx + 1) {
              columnNewArray.push({
                metaId: idx + 1,
                field: 'VIEW_DETAILS',
                title: '',
              });
            }
            sessionStorage.setItem('systemFolderOptions', JSON.stringify(columnNewArray));
          });
        });

        sessionStorage.setItem('user_information', JSON.stringify(userData.data));
        sessionStorage.setItem('userToken', userData.data.jwt);

        sessionStorage.setItem('userName', userData.data.name);
        sessionStorage.setItem('isUserAuthenticated', true);
        setUserInfo({ name: userData.data.name, usdaagencycode: [''] });
      }
    }
    data();
    fetchData();
    getDoctype();
    // if (sessionStorage.getItem('userToken') === undefined || !sessionStorage.getItem('userToken') || sessionStorage.getItem('userToken') === null) {
    //   navigate.push('/login');
    // }
  }, [page]);

  const getthumbnailsForWorkgroups = (data) => {
    data.forEach((itm) => {
      itm.workGroupItemList.forEach(async (obj) => {
        let thumbnails = await getThumbnailByBuowID(obj.buowId);
        obj.buowThumbnailPreview = thumbnails.data.length > 3 ? thumbnails.data.splice(0, 3) : thumbnails.data;
      });
    });
  };

  useEffect(() => {
    let nextData = workGroupItems2[countForNonWork + 5];
    if (nextData) {
      setDataPresentForNonWork(true);
    } else {
      setDataPresentForNonWork(false);
    }
  });

  const onSelectUserFilter = async (filter) => {
    setIsLoading(true);
    setCountForNonWork(0);
    setInBasketFilter(filter.value);
    await getUserBuow(filter.value);
  };

  const getUserBuow = async (filter) => {
    setIsLoading(true);
    let url = `filter=${filter}`;
    if (filter === 'arrivalTime') {
      let data = workGroupItems2;
      data.sort((a, b) => new Date(b.inBasketInsertDttm).getTime() - new Date(a.inBasketInsertDttm).getTime());
      setInBasketFilter(filter);
      onUpdateWorkGroupItems2(data);
      let nonWorkData = data;
      setDummyNonWorkGroup(nonWorkData.slice(0, 5));
      setIsLoading(false);
      getThumbnail(data);
      return;
    } else {
      await getWorkgroupItemsByUserId(url).then((response) => {
        onUpdateWorkGroupItems2(response.data);
        let nonWorkData2 = response.data;
        setDummyNonWorkGroup(nonWorkData2.slice(0, 5));
        setIsLoading(false);
        getThumbnail(nonWorkData2);
      });
    }
  };
  const getThumbnail = async (data) => {
    data.forEach(async (item) => {
      let thumbnails = await getThumbnailByBuowID(item.buowId);
      item.buowThumbnailPreview = thumbnails.data.length > 3 ? thumbnails.data.splice(0, 3) : thumbnails.data;
    });
  };
  const previousPageForNonWork = () => {
    let previousData = workGroupItems2[countForNonWork - 1];
    if (previousData !== undefined) {
      dummyNonWorkGroup.pop();
      dummyNonWorkGroup.unshift({ ...previousData });
      setTimeout(() => {
        setDummyNonWorkGroup(dummyNonWorkGroup);
      }, 500);
      setCountForNonWork(countForNonWork - 1);
      setDataPresentForNonWork(true);
    } else {
      setDataPresentForNonWork(false);
    }
  };
  const nextPageForNonWork = () => {
    let nextData = workGroupItems2[countForNonWork + 5];
    if (nextData != undefined) {
      dummyNonWorkGroup.shift();
      dummyNonWorkGroup.push({ ...nextData });
      setTimeout(() => {
        setDummyNonWorkGroup(dummyNonWorkGroup);
      }, 500);
      setCountForNonWork(countForNonWork + 1);
      setDataPresentForNonWork(true);
    } else {
      setDataPresentForNonWork(false);
    }
  };

  const previousPageForWork = (pageNo, index) => {
    let count = workGroups[index].countForWork;

    let previousData = workGroups[index].workGroupItemList[parseInt(pageNo[index] - 1) + count];

    if (previousData != undefined) {
      dummyWorkGroup[index]?.workGroupItemList.pop();
      let newData = dummyWorkGroup.map((obj, idx) => {
        if (idx == index) {
          return (obj.workGroupItemList = dummyWorkGroup[index]?.workGroupItemList), { ...obj };
        } else {
          return { ...obj };
        }
      });
      dummyWorkGroup[index].workGroupItemList.unshift({ ...previousData });

      let newData1 = dummyWorkGroup.map((obj, idx) => {
        if (idx == index) {
          return (obj.workGroupItemList = dummyWorkGroup[index]?.workGroupItemList), { ...obj };
        } else {
          return { ...obj };
        }
      });
      setDummyWorkGroup(newData);

      setTimeout(() => {
        setDummyWorkGroup(newData1);
      }, 500);
      count--;
      workGroups[index].countForWork = count;
    }
  };

  const nextPageForWork = (pageNo, index) => {
    let count = workGroups[index].countForWork;

    let nextData = workGroups[index].workGroupItemList[parseInt(pageNo[index] + 5) + count];

    if (nextData != undefined) {
      dummyWorkGroup[index]?.workGroupItemList.shift();
      let newData = dummyWorkGroup.map((obj, idx) => {
        if (idx == index) {
          return (obj.workGroupItemList = dummyWorkGroup[index]?.workGroupItemList), { ...obj };
        } else {
          return { ...obj };
        }
      });
      dummyWorkGroup[index].workGroupItemList.push({ ...nextData });

      let newData1 = dummyWorkGroup.map((obj, idx) => {
        if (idx == index) {
          return (obj.workGroupItemList = dummyWorkGroup[index]?.workGroupItemList), { ...obj };
        } else {
          return { ...obj };
        }
      });
      setDummyWorkGroup(newData);

      setTimeout(() => {
        setDummyWorkGroup(newData1);
      }, 500);
      count++;
      workGroups[index].countForWork = count;
    }
  };

  const onClickIcon = (val) => {
    let newData = workGroups.map((obj) => {
      if (obj.workgroupId === val.workgroupId) return ((obj.isExpand = !val.isExpand), (obj.countForWork = 0)), { ...obj };
      else return { ...obj };
    });
    let workData = _.cloneDeep(newData);
    workData.map((obj) => {
      return (obj.workGroupItemList = obj.workGroupItemList.slice(0, 5)), { ...obj };
    });
    onUpdateWorkGroupList(newData);
    setDummyWorkGroup([...workData]);
  };
  // const onDeleteMessage = (obj) => {
  //   let messagesList = messages;
  //   let index = messagesList.indexOf(obj);
  //   obj.isDeleteStarted = true;

  //   let timer = window.setTimeout(() => {
  //     messagesList.splice(index, 1);
  //     onUpdateMessageList([...messagesList]);
  //   }, 3000);
  //   obj.timer = timer;
  //   onUpdateMessageList([...messagesList]);
  // };
  const onDeleteMessage = (obj) => {
    let messagesList = updateMessageList;
    let index = messagesList.indexOf(obj);
    obj.isDeleteStarted = true;

    let timer = window.setTimeout(() => {
      messagesList.splice(index, 1);
      let url = 'messageId=' + obj.messageId;
      dismissMessage(url).then((response1) => {
        onUpdateMessageList([...messagesList]);
        dispatch(getMessagesCountNew());

        // props.messageCountHandler(messagesList.length);
      });
    }, 3000);
    obj.timer = timer;
    // console.log(messagesList, "messagesList");
    onUpdateMessageList([...messagesList]);
  };

  const undoDeleteMessage = (obj) => {
    window.clearTimeout(obj.timer);
    let messagesList = updateMessageList;
    obj.isDeleteStarted = false;
    obj.timer = null;
    onUpdateMessageList([...messagesList]);
  };

  const navigateTo = (obj, block) => {
    localStorage.removeItem('buow_relation');
    localStorage.removeItem('buow_WorkgroupId');

    localStorage.setItem('buow_name', obj.buowName);
    localStorage.setItem('buow_type', obj.buowType);
    localStorage.setItem('buow_relation', block);
    if (block === 'W') localStorage.setItem('buow_WorkgroupId', obj.workgroupId);
    navigate.push(`/buow/${obj?.buowId}`);
  };

  const updateFilter = async (itm, value) => {
    setIsLoading(true);
    let filter = '&filter=' + value.value;
    if (value.value === 'arrivalTime') {
      workGroups.map((obj) => {
        if (itm.workgroupId === obj.workgroupId) {
          return obj.workGroupItemList.sort(function (a, b) {
            return new Date(b.inBasketInsertDttm).getTime() - new Date(a.inBasketInsertDttm).getTime();
          });
        }
      });

      let newData = workGroups.map((obj) => {
        if (obj.workgroupId === itm.workgroupId) {
          return ((obj.selectedFilter = value.value), (obj.countForWork = 0)), { ...obj };
        } else return { ...obj };
      });

      let workData = _.cloneDeep(newData);
      workData.map((obj) => {
        return (obj.workGroupItemList = obj.workGroupItemList.slice(0, 5)), { ...obj };
      });
      onUpdateWorkGroupList([...newData]);
      setDummyWorkGroup([...workData]);
      setIsLoading(false);
      getthumbnailsForWorkgroups(newData);
      getthumbnailsForWorkgroups([...workData]);
    } else {
      await getWorkgroupItems(itm.workgroupId, filter).then((response) => {
        let newData = workGroups.map((obj) => {
          if (obj.workgroupId === itm.workgroupId) {
            return ((obj.selectedFilter = value.value), (obj.countForWork = 0)), (obj.workGroupItemList = response.data), { ...obj };
          } else return { ...obj };
        });

        let workData = _.cloneDeep(newData);
        workData.map((obj) => {
          return (obj.workGroupItemList = obj.workGroupItemList.slice(0, 5)), { ...obj };
        });
        onUpdateWorkGroupList(newData);
        setDummyWorkGroup([...workData]);

        setIsLoading(false);
        getthumbnailsForWorkgroups(newData);
        getthumbnailsForWorkgroups([...workData]);
      });
    }
  };

  const renderTooltip = (props, data) => {
    let tooltipContent = '';
    if (data.buowThumbnailPreview !== undefined) {
      tooltipContent = (
        <>
          {data.buowThumbnailPreview.map((itm, i) => {
            if (itm.pdfThumbnail !== null) {
              return <img key={uuidv4()} src={`data:image/jpeg;base64,${itm.pdfThumbnail}`} alt={`Img is ${i}`} className="hoverImageStyle" />;
            } else {
              return (
                <div className="hoverImageStyleblock">
                  <span className="hoverTextMsg">Preview Not Available</span>
                </div>
              );
            }
          })}
        </>
      );
    } else {
      tooltipContent = (
        <div className="hoverImageStyleblock">
          <Spinner color="#727981" size={32} speed={1} animating={true} />
        </div>
      );
    }
    return (
      <Tooltip id="button-tooltip" {...props}>
        {isHovered && tooltipContent}
      </Tooltip>
    );
  };

  const handleHover = () => {
    setIsHovered(true);
    setCountHover(countHover + 1);
  };

  const handleLeave = () => {
    setIsHovered(false);
    setCountHover(0);
  };

  return (
    (isLoading && <LoadingSplash></LoadingSplash>) || (
      <>
        <main className="content contenterInbasketOuter" tabIndex="-1">
          <div className="content">
            <div className="col-md-12 row justify-content-between mt-4 mb-4 w-100">
              <div className="col-md-4 p-0 header_style">
                My In-Basket:
                <span className="header_style2"> {workGroupItems2?.length} Items</span>
              </div>
              <div className="row" style={dummyNonWorkGroup.length === 0 ? { pointerEvents: 'none' }:{}}>
                {filters.map((filter, index) => (
                  <div className="pl-3 filter_style default_color" key={uuidv4()}>
                    {inBasketFilter === filter.value ? (
                      <span className="header_style2">{filter.name}</span>
                    ) : (
                      <span
                        onClick={() => {
                          onSelectUserFilter(filter);
                        }}
                        className="cursor"
                      >
                        {filter.name}
                      </span>
                    )}
                    {filters.length - 1 !== index && <span> | </span>}
                  </div>
                ))}
              </div>
              {dummyNonWorkGroup.length === 0 ? <div className="col-md-4"> </div> : <span></span>}
            </div>
            {dummyNonWorkGroup.length > 0 && (
              <NonWorkgroupsList
                // workGroupItems2={workGroupItems2}
                dummyNonWorkGroup={dummyNonWorkGroup}
                renderTooltip={renderTooltip}
                handleHover={handleHover}
                handleLeave={handleLeave}
                navigateTo={navigateTo}
                nextPageForNonWork={nextPageForNonWork}
                countForNonWork={countForNonWork}
                previousPageForNonWork={previousPageForNonWork}
                updateFilter={updateFilter}
                dataPresentForNonWork={dataPresentForNonWork}
              />
            )}
            {dummyNonWorkGroup.length === 0 && <div className="text-center nodataFoundInbasket">No data found</div>}
            {/* Work groups */}
            <WorkGroupsComponent
              workGroups={workGroups}
              updateFilter={updateFilter}
              onClickIcon={onClickIcon}
              renderTooltip={renderTooltip}
              handleHover={handleHover}
              handleLeave={handleLeave}
              workGroupItems={workGroupItems}
              dummyWorkGroup={dummyWorkGroup}
              navigateTo={navigateTo}
              nextPageForWork={nextPageForWork}
              previousPageForWork={previousPageForWork}
            />
            {/* end of Workgroups */}
            {/* messages start  */}
            <hr className="m-0 p-0" />
            <MessageComponent updateMessageList={updateMessageList} onDeleteMessage={onDeleteMessage} undoDeleteMessage={undoDeleteMessage} />
            {/* end of messages */}
          </div>
        </main>
      </>
    )
  );
};

const NonWorkgroupsList = ({
  // workGroupItems2,
  dummyNonWorkGroup,
  renderTooltip,
  navigateTo,
  handleHover,
  handleLeave,
  nextPageForNonWork,
  countForNonWork,
  previousPageForNonWork,
  dataPresentForNonWork,
}) => {
  return (
    <>
      <div className="col-md-12 row mt-4 mb-4 w-100">
        {countForNonWork !== 0 ? (
          <div style={{ marginLeft: '-0.375rem', top: '5.5rem' }} className="arrow-left cursor" onClick={() => previousPageForNonWork()}></div>
        ) : (
          <div style={{ marginLeft: '-2.3rem', top: '5.5rem' }} className="arrow-left-disabled"></div>
        )}
        <div className="col-md-12 row mt-4 mb-4 p-0 Horizon-scrollView">
          <div style={{ marginRight: '1.875rem' }}></div>
          {dummyNonWorkGroup.map((itm, index) => {
            return (
              <OverlayTrigger placement="bottom" trigger={['hover', 'focus']} delay={{ show: 400, hide: 100 }} overlay={(e) => renderTooltip(e, itm)} key={uuidv4()}>
                <div className="custom_width" onMouseEnter={handleHover} onMouseMove={handleHover} onMouseLeave={handleLeave}>
                  <div className="basket_suspense_box cursor" onClick={() => navigateTo(itm, 'U')}>
                    <div
                      className={
                        'p-2 box_title ' +
                        (itm.routeType === 'Return' && ' Route_Type_return ') +
                        (itm.routeType === 'Manual' && ' Route_Type_manual ') +
                        (itm.routeType === 'Auto' && ' Route_Type_auto')
                      }
                    >
                      {itm.entryType}
                    </div>
                    <div className="icon_box">
                      {/* Correspondence Review */}
                      {itm.entryType === 'Correspondence Review' && (
                        <>
                          <span>
                            <img src={FileIcons} alt="FileIcons" className="top_icon" />
                          </span>
                          <span>
                            <img src={TopIcons} alt="return" className="top_icon" />
                          </span>
                          <span>
                            <img src={FileCustomIcon} alt="FileCustomIcon" style={{ width: 20 }} className="top_icon" />
                          </span>
                        </>
                      )}
                      {/* Data Validation */}
                      {itm.entryType === 'Data Validation' && (
                        <>
                          <span>
                            <img src={FileIcons} alt="FileIcons" className="top_icon" />
                          </span>
                          <span>
                            <img src={FileCustomIcon} alt="FileCustomIcon" style={{ width: 20 }} className="top_icon" />
                          </span>
                          <span>
                            <img src={TopIcons} alt="return" className="top_icon" />
                          </span>
                        </>
                      )}

                      {/* Signature Validation */}
                      {itm.entryType === 'Signature Validation' && (
                        <>
                          <span>
                            <img src={FileIcons} alt="FileIcons" className="top_icon" />
                          </span>
                          <span>
                            <img src={FileCustomIcon} alt="FileCustomIcon" style={{ width: 20 }} className="top_icon" />
                          </span>
                          <span>
                            <img src={TopIcons} alt="return" className="top_icon" />
                          </span>
                        </>
                      )}
                    </div>
                    {/* <div className="icon_box">
                      <span>
                        {itm.routeType === "Auto" && (
                          <img
                            src={FileIcons}
                            alt="FileIcons"
                            className="top_icon"
                          />
                        )}
                        {itm.routeType === "Manual" && (
                          <img
                            src={FileIcons}
                            alt="FileIcons"
                            className="top_icon"
                          />
                        )}
                        {itm.routeType === "Return" && (
                          <img
                            src={FileIcons}
                            alt="FileIcons"
                            className="top_icon"
                          />
                        )}
                      </span>
                      <span>
                        <img
                          src={SpredIcon}
                          alt="FileIcons"
                          className="top_icon"
                        />
                      </span>
                      <span>
                        <img src={TopIcons} alt="return" className="top_icon" />
                      </span>
                    </div> */}
                    <div
                      className={
                        'p-2 box_Id ' +
                        (itm.routeType === 'Return' && ' Route_Type_return ') +
                        (itm.routeType === 'Manual' && ' Route_Type_manual ') +
                        (itm.routeType === 'Auto' && ' Route_Type_auto')
                      }
                    >
                      <span className="box_number d-inline-block text-truncate">{itm.buowId}</span>
                    </div>
                    <div style={{ height: 45 }}>
                      <div className=" p-2 justify-content-between m-0 row align-items-center">
                        <div>
                          {/* for route icon */}
                          {itm.routeType === 'Manual' && <img src={LocationIcons} alt="location" className="location_icon" />}
                          {itm.routeType === 'Auto' && <img src={ManualIcons} alt="location" className="location_icon" />}
                          {itm.routeType === 'Return' && <img src={ReturnIcons} alt="return" className="location_icon" />}
                        </div>
                        <div>
                          <Timer degree={parseInt(itm.kpi_1)} />
                        </div>
                      </div>
                    </div>
                    <div className="date_style d-flex justify-content-between flex-wrap">
                      <span> {moment(itm.inBasketInsertDttm).format('ddd, DD MMM HH:MM A')}</span>
                      {itm.notesAvailable === true && <img src={notesIcon} alt="notesIcon" className="notes_IconStyle" />}
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            );
          })}
        </div>
        <div>
          {dummyNonWorkGroup.length > 4 && !!dataPresentForNonWork ? (
            <div style={{ top: '5.5rem' }} className="arrow-right cursor" onClick={() => nextPageForNonWork()}></div>
          ) : (
            <div style={{ top: '5.5rem' }} className="arrow-right-disabled"></div>
          )}
        </div>
      </div>
    </>
  );
};

const WorkGroupsComponent = ({
  workGroups,
  dummyWorkGroup,
  updateFilter,
  onClickIcon,
  renderTooltip,
  handleHover,
  handleLeave,
  workGroupItems,
  navigateTo,
  nextPageForWork,
  previousPageForWork,
}) => {
  return (
    <>
      {dummyWorkGroup.map((obj, index) => {
        return (
          <div key={uuidv4()}>
            <hr className="m-0 p-0" />
            <div className="col-md-12 row justify-content-between mt-4 mb-4 w-100">
              <div className="col-md-4 p-0 header_style">
                Workgroup &nbsp;
                <span className="header_style2">
                  {obj.workgroupDisplayName}: {obj.originalCount} Items
                </span>
              </div>
              <div className={obj.isExpand ? '' : 'disabled'}>
                <div className="row">
                  {filters.map((filter, index) => (
                    <div className="pl-3 filter_style default_color" key={uuidv4()}>
                      {obj.selectedFilter === filter.value ? (
                        <span className="header_style2">{filter.name}</span>
                      ) : (
                        <span onClick={() => updateFilter(obj, filter)} className="cursor">
                          {filter.name}
                        </span>
                      )}
                      {filters.length - 1 !== index && <span> | </span>}
                    </div>
                  ))}
                </div>
              </div>
              {obj.isExpand ? (
                <span onClick={() => onClickIcon(obj)} className="cursor">
                  <i className="fa fa-caret-down"></i>
                </span>
              ) : (
                <span onClick={() => onClickIcon(obj)} className="cursor">
                  <i className="fa fa-caret-up"></i>
                </span>
              )}
            </div>

            <div style={{ height: 154 }}>
              {obj.isExpand && (
                <div>
                  <div className="col-md-12 row mt-4 mb-4 p-0">
                    <div>
                      {workGroups[index].countForWork != 0 ? (
                        <div
                          style={{ marginLeft: '-0.375rem' }}
                          className="arrow-left cursor"
                          onClick={() =>
                            previousPageForWork(
                              dummyWorkGroup.map((obj) => {
                                return obj.page;
                              }),
                              index
                            )
                          }
                        ></div>
                      ) : (
                        <div style={{ marginLeft: '-0.8rem' }} className="arrow-left-disabled"></div>
                      )}
                    </div>
                    <div className="col-md-12 row Horizon-scrollView">
                      <div style={{ marginRight: '1.875rem' }}></div>
                      {obj?.workGroupItemList?.map((itm, index) => {
                        return (
                          <React.Fragment key={uuidv4()}>
                            {obj.workgroupId === itm.workgroupId && (
                              <OverlayTrigger placement="bottom" trigger={['hover', 'focus']} overlay={(e) => renderTooltip(e, itm)} key={uuidv4()}>
                                <div className="custom_width" key={uuidv4()} onMouseEnter={handleHover} onMouseMove={handleHover} onMouseLeave={handleLeave}>
                                  <div className="basket_suspense_box cursor" onClick={() => navigateTo(itm, 'W')}>
                                    <div
                                      className={
                                        'p-2 box_title ' +
                                        (itm.routeType === 'Return' && ' Route_Type_return ') +
                                        (itm.routeType === 'Manual' && ' Route_Type_manual ') +
                                        (itm.routeType === 'Auto' && ' Route_Type_auto')
                                      }
                                    >
                                      {itm.entryType}
                                    </div>
                                    <div className="icon_box">
                                      {/* Correspondence Review */}
                                      {itm.entryType === 'Correspondence Review' && (
                                        <>
                                          <span>
                                            <img src={FileIcons} alt="FileIcons" className="top_icon" />
                                          </span>
                                          <span>
                                            <img src={TopIcons} alt="return" className="top_icon" />
                                          </span>
                                          <span>
                                            <img src={FileCustomIcon} alt="FileCustomIcon" style={{ width: 20 }} className="top_icon" />
                                          </span>
                                        </>
                                      )}
                                      {/* Data Validation */}
                                      {itm.entryType === 'Data Validation' && (
                                        <>
                                          <span>
                                            <img src={FileIcons} alt="FileIcons" className="top_icon" />
                                          </span>
                                          <span>
                                            <img src={FileCustomIcon} alt="FileCustomIcon" style={{ width: 20 }} className="top_icon" />
                                          </span>
                                          <span>
                                            <img src={TopIcons} alt="return" className="top_icon" />
                                          </span>
                                        </>
                                      )}

                                      {/* Signature Validation */}
                                      {itm.entryType === 'Signature Validation' && (
                                        <>
                                          <span>
                                            <img src={FileIcons} alt="FileIcons" className="top_icon" />
                                          </span>
                                          <span>
                                            <img src={FileCustomIcon} alt="FileCustomIcon" style={{ width: 20 }} className="top_icon" />
                                          </span>
                                          <span>
                                            <img src={TopIcons} alt="return" className="top_icon" />
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        'p-2 box_Id ' +
                                        (itm.routeType === 'Return' && ' Route_Type_return ') +
                                        (itm.routeType === 'Manual' && ' Route_Type_manual ') +
                                        (itm.routeType === 'Auto' && ' Route_Type_auto')
                                      }
                                    >
                                      <span className="box_number d-inline-block text-truncate">{itm.buowId}</span>
                                    </div>
                                    <div style={{ height: 45 }}>
                                      <div className="p-2 justify-content-between m-0 row align-items-center">
                                        <div>
                                          {/* for route icon */}
                                          {itm.routeType === 'Manual' && <img src={LocationIcons} alt="location" className="location_icon" />}
                                          {itm.routeType === 'Auto' && <img src={ManualIcons} alt="location" className="location_icon" />}
                                          {itm.routeType === 'Return' && <img src={ReturnIcons} alt="return" className="location_icon" />}
                                        </div>
                                        <div>
                                          {/* for timer icon */}
                                          <div>
                                            <Timer degree={parseInt(itm.kpi_1)} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="date_style d-flex justify-content-between">
                                      {moment(itm.inBasketInsertDttm).format('ddd, DD MMM HH:MM A')}
                                      {itm.notesAvailable === true && <img src={notesIcon} alt="notesIcon" style={{ width: 25 }} />}
                                    </div>
                                  </div>
                                </div>
                              </OverlayTrigger>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div>
                      <div style={{ marginLeft: '1.875rem' }}></div>
                      {workGroups[index].countForWork + 5 == workGroups[index].workGroupItemList.length ? (
                        <div className="arrow-right-disabled"></div>
                      ) : workGroups[index].workGroupItemList.length > 4 ? (
                        <div
                          className="arrow-right cursor"
                          onClick={() =>
                            nextPageForWork(
                              dummyWorkGroup.map((obj11) => {
                                return obj11.page;
                              }),
                              index
                            )
                          }
                        ></div>
                      ) : (
                        <div className="arrow-right-disabled"></div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

const MessageComponent = ({ updateMessageList, onDeleteMessage, undoDeleteMessage }) => {
  return (
    <>
      <span className="header_style">
        Messages:
        <span className="header_style2"> {updateMessageList?.length} Items</span>
      </span>
      <div className="col-md-12 row mt-4 mb-4 p-0">
        {updateMessageList.map((obj, index) => {
          return (
            <div className="col-md-4 col-sm-12 mb-5" key={uuidv4()}>
              <div className={'basket_message_box ' + (obj.isDeleteStarted && obj.isDeleteStarted === true && ' deleteBoxShadow')}>
                <div className="d-flex justify-content-between p-3">
                  <span>
                    {obj.sender} ({moment(obj.messageDttm).format('ddd, DD MMM HH:MM A')})
                  </span>

                  {obj.dismissed === false && obj.isDeleteStarted === false && (
                    <span className="cursor" onClick={() => onDeleteMessage(obj)}>
                      <i className="fa fa-trash"></i>
                    </span>
                  )}

                  {!!obj.isDeleteStarted && obj.isDeleteStarted === true && (
                    <button className="cursor btn btn-secondary undoButton" onClick={() => undoDeleteMessage(obj)}>
                      Undo
                    </button>
                  )}
                </div>
                <hr className="m-0" />
                <p className="p-3 m-0" style={{ background: '#fff' }}>
                  {obj.priority === 'H' && <img src={High_msg} alt="High_msg" style={{ height: 30 }} />} {obj.messageBody}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {updateMessageList.length === 0 && <div className="text-center nodataFoundMessage w-100">No data found</div>}
    </>
  );
};

export default InBasketPage;
