import React, { useContext, useRef, useState, useEffect } from 'react';
import './Search.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { GridColumn as Column, getSelectedState } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import LoadingSplash from '../LoadingSplash/LoadingSplash';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import DrmsFields from '../../json/DrmsFields.json';
import DRMSGrid from './DRMSGrid';
import UserContext from '../UserContext/UserContext';
import DateSearchInput from './DateSearchInput';
import TextSearchInput from './TextSearchInput';
import WarningGrowl from './WarningGrowl';
import BoolInput from './BoolInput';
import searchImg from '../../fsa-style/img/searchImg.svg';
import { createSearchObject } from './CreateSearchObject';
import { CreateSearchObjectSearch } from './CreateSearchObjectSearch';
import { v4 as uuidv4 } from 'uuid';
import { searchHolds } from '../../services/recordsManagementService';
import AsyncSelect from 'react-select/async';
import { getDoctype } from '../../services/suspenseQueueService';
import MetaDataModal from './MetaDataModal/MetaDataModal';
import { EventEmitter } from '../../services/events';
import {
  search,
  initParams,
  initMetaDropList,
  indexSheetMetaDropList,
  allMetaDropList,
  initStateList,
  initCountyList,
  getDocDetail,
  getCustomers,
  deleteObjectSearch,
} from '../../services/metadataSubmissionService';
import { objectSpecificList } from '../../services/metadataSubmissionService';
import calculateSize from 'calculate-size';
import Moment from 'moment';
import CustomColumnMenu from './CustomColumnMenu';
import { searchPageColumnsAPI } from '../../pages/Search/SearchServices';
import { DeleteModal } from '../../newcomponents/DeletePopup/DeleteModal';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import _ from 'lodash';
import { fixedPermissions } from '../../utils/helper/permissionFinder';
import { useOktaAuth } from '@okta/okta-react';
import { oktaLogin } from '../../services/inBasketService';
import { groupData, sectionData, employmentStatusData, statusReasonData } from '../../utils/helper/helperData';

export default function DrmsSearch() {
  const { userInfo, userPermissions, setUserInfo, setUserPermissions } = useContext(UserContext);
  const elementRef = useRef();
  const searchIconRef = useRef();

  //TODO populate ARRAYS w/ FETCH
  const [agencySelection, setAgencySelection] = useState(null);
  const [rowsChecked, setRowsChecked] = useState([]);

  const [errorMessage, setSearchErrorMessage] = useState('No records were found matching your search criteria.');
  const [isWarningGrowlHidden, setIsWarningGrowlHidden] = useState(true);

  const [stateResponse, setStateList] = useState([]);
  const [stateFilteredData, setStateFilteredData] = useState(stateResponse.slice());
  const [stateSelection, setStateSelection] = useState(null);

  const [countyResponse, setCountyList] = useState([]);
  const [countyFilteredData, setCountyFilteredData] = useState(countyResponse.slice());
  const [countySelection, setCountySelection] = useState(null);

  const [doctypeResponse, setDocTypeList] = useState([]);
  const [docFilteredData, setDocFilteredData] = useState(doctypeResponse.slice());
  const [doctypeSelection, setDoctypeSelection] = useState(null);

  const [keywordSearch, setKeywordSearch] = useState();
  const [folderkeywordSearch, setFolderkeywordSearch] = useState();

  const [records, setRecords] = useState({ metadata: [] });
  const [metafieldResponse, setMetafieldList] = useState([]);
  const [indexFieldResponse, setIndexSheetList] = useState([]);
  const [metaFilteredData, setMetaFilteredData] = useState(metafieldResponse.slice());
  const [indexFilteredData, setIndexFilteredData] = useState(indexFieldResponse.slice());
  const [metadataSelection, setMetadataSelection] = useState(null);
  const [indexMetadataSelection, setIndexMetadataSelection] = useState(null);
  const [columnSearch, setColumnSearch] = useState('');
  const [filter, setFilter] = useState([]);

  const [searchFormObjArray, setSearchFormObjArray] = useState([]);
  const [searchFormObjArrayTemp, setSearchFormObjArrayTemp] = useState([]);
  const [searchParamsObjArray, setSearchParamsObjArray] = useState([]);
  const [searchParamsObjArrayTemp, setSearchParamsObjArrayTemp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columnsToShow, setColumnsToShow] = useState();

  const [take, setTake] = useState(25);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState([]);
  const [objectID, setObjectID] = useState('');
  const [metadataEmpty, setMetadataEmpty] = useState(true);
  const [columnSearchEmpty, setColumnSearchEmpty] = useState(true);
  const [docTypeEmpty, setDoctypeEmpty] = useState(true);
  const [keywordEmpty, setKeywordEmpty] = useState(true);
  const [layout, setLayout] = useState('stretched');
  const [popupOpen, setPopupOpen] = useState(false);
  const [modalLocation, setModalLocation] = useState();
  const [drmsSearch, setdrmsSearch] = useState(false);
  const [cellClicked, setCellClicked] = useState(null);
  const [objectMetadata, setObjectMetadata] = useState();
  const [doc, setDoc] = useState();
  const [detailOpen, setDetailOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docName, setDocName] = useState('');
  const [docType, setDocType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [isProducerSearch, setIsProducerSearch] = useState(false);
  const [scimsCustomers, setScimsCustomers] = useState(null);
  const [metadataDisplayList, setMetadataDisplayList] = useState(null);
  const [objFileCodePopupOpen, setObjFileCodePopupOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [applicationid, setApplicationId] = useState();
  const [agencyCode, setAgencyCode] = useState();
  const [sections, setSections] = useState();
  const [docDetailResponse, setDocDetailResponse] = useState();
  const [documentTitle, setDocumentTitle] = useState();
  const [dataUpdated, setDataUpdated] = useState(false);
  const [dropdownFilterCell, setDropdownFilterCell] = useState(null);
  const [dropdownFilterChanged, setDropdownFilterChanged] = useState(false);
  const [clickedSearchRow, setClickedSearchRow] = useState(null);
  const [isSearchMetadataLoading, setIsSearchMetadataLoading] = useState(false);
  const [refreshColumn, setRefreshColumn] = useState(false);

  const [groupList, setGroupList] = useState(
    groupData.sort((a, b) => {
      // If the display_name of a is before b, return -1
      if (a.display_name < b.display_name) {
        return -1;
      }
      // If the display_name of a is after b, return 1
      if (a.display_name > b.display_name) {
        return 1;
      }
      // If the display_names are the same, return 0
      return 0;
    })
  );

  const [employmentStatusList, setEmploymentStausList] = useState(
    employmentStatusData.sort((a, b) => {
      // If the display_name of a is before b, return -1
      if (a.display_name < b.display_name) {
        return -1;
      }
      // If the display_name of a is after b, return 1
      if (a.display_name > b.display_name) {
        return 1;
      }
      // If the display_names are the same, return 0
      return 0;
    })
  );

  const [statusReasonList, setStatusReasonList] = useState(
    statusReasonData.sort((a, b) => {
      // If the display_name of a is before b, return -1
      if (a.display_name < b.display_name) {
        return -1;
      }
      // If the display_name of a is after b, return 1
      if (a.display_name > b.display_name) {
        return 1;
      }
      // If the display_names are the same, return 0
      return 0;
    })
  );

  useEffect(() => {
    const handleHighlightClick = (event) => {
      const detailsViewModalElement = document.getElementById('detailsViewModalElement');
      if (searchIconRef?.current?.alt !== event?.target?.alt && event?.target?.alt?.replace(/ /g, '') !== 'crossicon' && detailsViewModalElement === null) {
        setClickedSearchRow(null);
      }
    };

    document.addEventListener('click', handleHighlightClick);

    return () => {
      document.removeEventListener('click', handleHighlightClick);
    };
  }, []);

  const employmentStatusFilter = (inputValue) => {
    return employmentStatusList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };
  const statusReasonFilter = (inputValue) => {
    return statusReasonList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };

  const groupFilterData = (inputValue) => {
    return groupList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };
  const [sectionList, setSectionList] = useState(
    _.cloneDeep(
      sectionData.sort((a, b) => {
        // If the display_name of a is before b, return -1
        if (a.display_name < b.display_name) {
          return -1;
        }
        // If the display_name of a is after b, return 1
        if (a.display_name > b.display_name) {
          return 1;
        }
        // If the display_names are the same, return 0
        return 0;
      })
    )
  );
  const [doctypeList, setDoctypeList] = useState([]);
  const [sectionFilteredData, setSectionFilteredData] = useState(sectionList.slice());
  const [systemFolderData, setSystemFolderData] = useState([]);
  const [fullTextFlag, setFullTextFlag] = useState(false);
  const sectionFilterData = (inputValue) => {
    return sectionList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };
  const defaultItem = {
    display_name: 'Start typing to search available metadata.',
  };
  const defaultAny = {
    display_name: 'any',
  };

  const DATA_ITEM_KEY = '_id';
  const SELECTED_FIELD = 'selected';
  const [detailsObjList, setDetailsObjList] = useState([]);

  const [selectedState, setSelectedState] = useState({});
  const [objectTypeToView, selectObjectTypeToView] = useState(null);

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isRequiredError, setisRequiredError] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState();
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [deleteData, setDeleteData] = useState([]);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [isMetadataLoading, setMetadataLoader] = useState(false);
  const [isBulkPopupOpen, setBulkPopupOpen] = useState(false);
  const [holdResponse, setHoldResponse] = useState([]);
  const { authState } = useOktaAuth();
  const [objectTypeList, setObjectTypeList] = useState([]);
  const [sorting, setSorting] = useState();
  const objectTypeFilterData = (inputValue) => {
    return objectTypeList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };
  const [metaModalFlag, setMetaModalFLag] = useState(false);

  Object.flatten = function (data) {
    var result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }

    recurse(data, '');
    return result;
  };

  const calculateWidth = (data, field, columnName) => {
    let maxWidth = 0;

    const size = calculateSize(columnName.replaceAll('_', ' '), {
      font: 'Arial',
      fontSize: '23px',
    });
    maxWidth = size.width + 37;
    data.metadata.forEach((item) => {
      const flattened = Object.flatten(item);
      const contentSize = flattened[field]
        ? calculateSize(flattened[field], {
            font: 'Arial',
            fontSize: '18px',
          })
        : 0;
      if (contentSize.width > maxWidth) {
        maxWidth = contentSize.width;
      }
    });

    return maxWidth;
  };

  const fullTextHandler = () => {
    setFullTextFlag(!fullTextFlag);
  };
  let tempParaArr = searchParamsObjArray;
  useEffect(() => {
    tempParaArr = searchParamsObjArray;
  }, [searchParamsObjArray]);
  //  Used when keyword search field is empty
  useEffect(() => {
    if (keywordSearch === '') {
      advSearchStateChange();
      setKeywordSearch(undefined);
      setKeywordEmpty(true);
    }
    if (folderkeywordSearch === '') {
      advSearchStateChange();
      setFolderkeywordSearch(undefined);
    }
  }, [keywordSearch, folderkeywordSearch]);

  useEffect(() => {
    if (userInfo.usdaagencycode) {
      // console.log('search user name', JSON.parse(sessionStorage.getItem("userInfo")))
      let agency;
      if ((agencySelection && agencySelection.AgencyName === userInfo.usdaagencycode) || agencySelection) {
        agency = agencySelection.AgencyName;
      } else {
        agency = userInfo.usdaagencycode[0];
      }
      initParams(agency).then((response) => {
        setDocTypeList(response.data.slice());
        setDocFilteredData(response.data.slice());
      });
    }
  }, [userInfo, agencySelection]);

  const refreshDataObject = async () => {
    setTimeout(() => {
      initStateList().then((response) => {
        setStateList(response.data.slice());
        setStateFilteredData(response.data.slice());
        // setCountyList(response.data.slice());
      });
      indexSheetMetaDropList().then((responseA) => {
        setIndexSheetList(responseA.data.slice());
        setIndexFilteredData(responseA.data.slice());
        setIsLoading(false);
      });
      allMetaDropList().then((responseA) => {
        let systemColumns = JSON.parse(sessionStorage.getItem('SystemColumns'));

        systemColumns?.map((item, i) => {
          responseA.data.push({ description: item.name.replace(' ', ''), display_name: item.name, name: 'object_specific.' + item.name, required: true, type: 'text' });
          if (i === systemColumns.length - 1) {
            setMetafieldList(responseA.data.slice());
            setMetaFilteredData(responseA.data.slice());
            return null;
          } else return null;
        });
      });

      indexSheetMetaDropList().then((responseA) => {
        setIndexSheetList(responseA.data.slice());
        setIndexFilteredData(responseA.data.slice());
        setIsLoading(false);
      });

      if (window.location.search !== '') {
        const params = new URLSearchParams(decodeURIComponent(window.location.search)); // id=123
        let ccid = atob(params.get('id')); // 123
        let name = atob(params.get('name')); // 123
        let address = atob(params.get('address')); // 123
        let returnData = new URLSearchParams(atob(params.get('returnData')));
        setSkip(returnData.get('skip'));
        setTake(returnData.get('take'));
        setSort(JSON.parse(returnData.get('sort')));

        setSearchParamsObjArray(JSON.parse(returnData.get('searchParams')));
        let searchFormParams = JSON.parse(returnData.get('searchFormParams'));
        searchFormParams =
          searchFormParams.find((element) => element.key === 'Producer Identified') === undefined
            ? searchFormParams.concat([
                {
                  systemName: 'Producer Identified',
                  friendlyName: 'Producer Identified',
                },
              ])
            : searchFormParams.replace(' ', '');
        setSearchFormObjArray(searchFormParams);

        let paramArray = JSON.parse(returnData.get('searchParams'));
        paramArray =
          paramArray.find((element) => element.key === 'Producer Identified') === undefined
            ? paramArray.concat([
                {
                  key: 'Producer Identified',
                  value: name + ' | ' + 'Address: ' + address,
                  core_customer_id: '"' + ccid + '"',
                },
              ])
            : paramArray.map((obj) =>
                obj.key === 'Producer Identified'
                  ? {
                      key: 'Producer Identified',
                      value: name + ' | ' + 'Address: ' + address,
                      core_customer_id: '"' + ccid + '"',
                    }
                  : obj
              );
        setIsProducerSearch(true);
        setSearchParamsObjArray(paramArray);

        searchMetadata(0, Number(returnData.get('take')), JSON.parse(returnData.get('sort')), paramArray, null, userInfo).then((response) => {
          setdrmsSearch(true);
          setIsLoading(false);
          if (elementRef) {
            // const divElement = elementRef.current.getBoundingClientRect();
            // console.log(divElement);
            // setModalLocation(divElement);
          }
        });
      } else {
        searchMetadata(0, take, sort, null, null, userInfo).then((response) => {
          setdrmsSearch(true);
          setIsLoading(false);
        });
      }

      setSkip(0);
    }, 2000);
  };
  useEffect(() => {
    async function fetchData() {
      let response = await getDoctype();
      if (Array.isArray(response.data)) {
        const mappedData = response.data.map((item, index) => ({
          id: index + 1,
          display_name: item.objectTypeName,
        }));
        setObjectTypeList(mappedData);
      } else {
        console.error('Invalid data format from API:', response.data);
      }
      let userTOken = sessionStorage.getItem('userToken');

      let oktaSession = JSON.parse(sessionStorage.getItem('okta-token-storage'));
      if (Object.keys(oktaSession).length > 0 && oktaSession.accessToken.accessToken) {
        if (oktaSession.accessToken.accessToken !== undefined && !userTOken) {
          let userData = await oktaLogin(oktaSession.accessToken.accessToken);
          // setUserPermissions(userData.data.rolePermissions)

          let x = userData.data?.rolePermissions;
          let obj = {};
          if (x !== undefined && x.length > 0) {
            await x.map((i) => {
              if (fixedPermissions.includes(i) === true) {
                obj[i] = true;
              }
              return null;
            });

            setUserPermissions(obj);
          }

          sessionStorage.setItem('user_information', JSON.stringify(userData.data));
          sessionStorage.setItem('userToken', userData.data.jwt);

          sessionStorage.setItem('userName', userData.data.name);
          sessionStorage.setItem('isUserAuthenticated', true);
          setUserInfo({ name: userData.data.name, usdaagencycode: [''] });
        }
      }
      await searchPageColumnsAPI().then(async (response) => {
        let columnNewArray = [
          {
            metaId: 0,
            field: 'SELECTED_FIELD',
            title: 'Select',
          },
        ];
        await response.data.map((column, idx) => {
          columnNewArray.push({
            metaId: '_id',
            field: 'key.object_specific.' + column.name.replace(' ', ''),
            title: column.name,
            dataType: 'text',
            showMenuIcon: 'true',
            sortType: '',
            data: column,
          });
          setSystemFolderData(columnNewArray);
          if (response.data.length === idx + 1) {
            columnNewArray.push({
              metaId: idx + 1,
              field: 'VIEW_DETAILS',
              title: '',
            });
          }
          sessionStorage.setItem('SystemColumns', JSON.stringify(response.data));
          sessionStorage.setItem('systemFolderOptions', JSON.stringify(columnNewArray));
        });
      });
      refreshDataObject();
    }
    fetchData();
  }, []);
  const stateFilterChange = (event) => {
    setStateFilteredData(stateFilterData(event.filter));
  };

  const stateFilterData = (filter) => {
    const data = stateResponse.slice();
    return filterBy(data, filter);
  };

  const countyFilterChange = (event) => {
    setCountyFilteredData(countyFilterData(event.filter));
  };

  const countyFilterData = (filter) => {
    const data = countyResponse.slice();
    return filterBy(data, filter);
  };

  const docFilterChange = (event) => {
    setDocFilteredData(docFilterData(event.filter));
  };

  const docFilterData = (filter) => {
    const data = doctypeResponse.slice();
    return filterBy(data, filter);
  };

  const indexFilterChange = (event) => {
    setIndexFilteredData(indexFilterData(event.filter));
  };
  const metaFilterChange = (event) => {
    setMetaFilteredData(metaFilterData(event.filter));
  };

  const metaFilterData = (filter) => {
    const data = metafieldResponse.slice();
    return filterBy(data, filter);
  };

  //1 HANDLE Agency DROPDOWN CHANGE
  const agencyDropListChange = (agency) => {
    setAgencySelection(agency);
    setDoctypeSelection(null);
    setMetadataSelection(null);
  };

  const onDoctypeFocus = () => {
    if (searchParamsObjArray.length > 0) {
      setIsWarningGrowlHidden(false);
      setTimeout(() => {
        setIsWarningGrowlHidden(true);
      }, 5000);
    }
  };

  const stateDropListChange = (state) => {
    if (state.name === 'Any') {
      setStateSelection(null);
      setCountyList([]);
      setStateFilteredData(stateResponse);
      setCountyFilteredData([]);
    } else {
      setStateSelection(state);

      initCountyList(state.code).then((response) => {
        setCountyList(response.data.slice());
        setCountyFilteredData(response.data.slice());
      });
    }
  };

  const countyDropListChange = (county) => {
    setCountySelection(county.name === 'Any' ? null : county);
    setCountyFilteredData(countyResponse);
  };

  //2 ON DOCTYPE DROPDOWN SELECT ->
  const doctypeDropListChange = (e) => {
    if (e.target.value.objectTypeName === 'Any') {
      setDoctypeSelection(null);
      allMetaDropList().then((responseA) => {
        setMetafieldList(responseA.data.slice());
        setMetaFilteredData(responseA.data.slice());
        setIsLoading(false);
        if (elementRef) {
          // const divElement = elementRef.current.getBoundingClientRect();
          // setModalLocation(divElement);
        }
      });
      setDoctypeEmpty(true);
    } else {
      setDoctypeSelection(e.target.value);
      initMetaDropList(e.target.value.objectTypeName).then((response) => {
        setMetafieldList(response.data.slice());
        setMetaFilteredData(response.data.slice());
        setMetadataSelection(null);
        setIndexMetadataSelection(null);
        setSearchFormObjArray([]);
        setSearchParamsObjArray([]);
        setIsProducerSearch(false);
      });
      setDoctypeEmpty(false);
    }
  };

  // const metaDropListChange = e => {
  //   if (e === defaultItem) {
  //     setMetadataSelection(null);
  //     // setCountyList([]);
  //     setMetadataEmpty(true);
  //   } else {
  //     setMetadataSelection(e);
  //     setMetadataEmpty(false);
  //     // if (e.syntheticEvent.code !== "ArrowDown" && e.syntheticEvent.code !== "ArrowUp") {
  //     document.getElementById("metadataSelectionDropDown").addEventListener("submit", addParam(e));
  //     addParam(e);
  //     // }
  //   }
  // };
  const metaDropListChange = (e) => {
    if (e.target.value === defaultItem) {
      setMetadataSelection(null);
      setMetadataEmpty(true);
    } else {
      setMetadataSelection(e.target.value);
      setMetadataEmpty(false);
      if (e.syntheticEvent.code !== 'ArrowDown' && e.syntheticEvent.code !== 'ArrowUp') {
        document.getElementById('metadataSelectionDropDown').addEventListener('submit', addParam(e.target.value));

        addParam(e.target.value);
      }
    }
  };

  const onChangeAsyncData = (e) => {
    if (e === defaultItem) {
      setMetadataSelection(null);
      setMetadataEmpty(true);
    } else {
      if (e.display_name === 'Document Title' || e.display_name === 'Document Name') {
        e.name = 'object_type.title';
      }
      setMetadataSelection(e);
      setMetadataEmpty(false);
      addParam(e);
    }
  };

  const indexDropListChange1 = (e) => {
    if (e === defaultItem) {
      // console.log('=========41', e);
      setIndexMetadataSelection(null);
      setMetadataEmpty(true);
    } else {
      // console.log('=========42', e);
      setIndexMetadataSelection(e);
      setMetadataEmpty(false);
      // if (e.syntheticEvent.code !== "ArrowDown" && e.syntheticEvent.code !== "ArrowUp") {
      addParam(e);
      // }
    }
  };
  const indexDropListChange = (e) => {
    if (e.target.value === defaultItem) {
      setIndexMetadataSelection(null);
      setMetadataEmpty(true);
    } else {
      setIndexMetadataSelection(e.target.value);
      setMetadataEmpty(false);
      if (e.syntheticEvent.code !== 'ArrowDown' && e.syntheticEvent.code !== 'ArrowUp') {
        addParam(e.target.value);
      }
    }
  };

  //ADD METADATA FIELD TO SEARCH FORM
  const addParam = (value) => {
    //e.preventDefault();
    const selection = value;
    if (selection != null && selection.display_name != undefined) {
      setSearchFormObjArray((prevSearchFormObjArray) => {
        return prevSearchFormObjArray.some((element) => element.friendlyName === selection.display_name) === false
          ? prevSearchFormObjArray.concat([
              {
                systemName: selection.name,
                friendlyName: selection.display_name,
              },
            ])
          : prevSearchFormObjArray;
      });
      setSearchParamsObjArray((prevSearchParamsObjArray) => {
        return prevSearchParamsObjArray.find((element) => element.key === selection.name) === undefined
          ? prevSearchParamsObjArray.concat([
              {
                key: selection.name,
                friendlyName: selection.display_name,
                value: '',
              },
            ])
          : prevSearchParamsObjArray.map((obj) =>
              obj.key === selection.name
                ? {
                    key: selection.name,
                    friendlyName: selection.display_name,
                    value: '',
                  }
                : obj
            );
      });
    }
  };

  const addCustomerNode = (data, node, ccids) => {
    if (data.object_specific[node]) {
      if (Array.isArray(data.object_specific[node])) {
        data.object_specific[node].map((producer) => {
          if (producer.core_customer_id) {
            ccids.push(producer.core_customer_id);
          }
        });
      } else {
        if (data.object_specific[node].core_customer_id) {
          ccids.push(data.object_specific[node].core_customer_id);
        }
      }
    }

    return ccids;
  };

  const populateScimsCustomerData = (data) => {
    let ccids = [];
    if (data) {
      ccids = addCustomerNode(data, 'producer', ccids);
      ccids = addCustomerNode(data, 'entity', ccids);
      ccids = addCustomerNode(data, 'member_info', ccids);
      ccids = addCustomerNode(data, 'participant', ccids);
      ccids = addCustomerNode(data, 'guardian', ccids);

      if (ccids.length > 0) {
        getCustomers(ccids).then((response) => {
          setScimsCustomers(response.data);
        });
      }
    }
  };

  const removeParam = (name) => {
    setSearchFormObjArray(searchFormObjArray.filter((param) => param.systemName !== name));
    let params = searchParamsObjArray === undefined ? [] : searchParamsObjArray;
    let filteredArr;
    filteredArr = params.filter((param) => param.key !== name);
    setSearchParamsObjArray(filteredArr === undefined ? [] : filteredArr);
    setMetadataSelection(null);
    // onSearchDetails();
    return filteredArr;
  };

  const searchMetadata = (skip, take, sort = undefined, paramsArray = undefined, latestFilter = undefined, userInfo = undefined) => {
    setTake(take);
    setSkip(skip);
    setIsSearchMetadataLoading(true);
    setRowsChecked([]);
    if (
      searchParamsObjArray.some((i) => {
        return i?.friendlyName?.includes('Term');
      })
    ) {
      let searchParamsArr = searchParamsObjArray;
      searchParamsArr.forEach((i) => {
        if (i.value === '') {
          i.value = true;
        }
      });
      setSearchParamsObjArray(searchParamsArr);
    }
    const metadataSearchObject = createSearchObject(paramsArray ? paramsArray : searchParamsObjArray, sort, doctypeSelection, stateSelection, countySelection);
    if (sorting !== null && sorting !== undefined) {
      metadataSearchObject.sort.push(sorting);
    }
    setIsLoading(true);
    if (keywordSearch && keywordSearch.length !== 0 && fullTextFlag === false) {
      // metadataSearchObject.searchCriteria.push({
      //   condition: 'CONTAINS',
      //   field1: {
      //     value: [keywordSearch],
      //   },
      // });
      let x = metadataSearchObject.searchCriteria[0];
      x.field2 = {
        value: keywordSearch,
      };
    } else if (fullTextFlag === true && keywordSearch && keywordSearch?.length !== 0) {
      metadataSearchObject.searchCriteria.push({
        condition: 'CONTAINS',
        field1: {
          name: 'object_specific.object_ocr',
          value: [keywordSearch],
        },
      });
    }

    if (folderkeywordSearch && folderkeywordSearch.length !== 0) {
      metadataSearchObject.searchCriteria.push({
        condition: 'CONTAINS',
        field1: {
          name: systemFolderData.length > 0 && 'object_specific.' + systemFolderData[1].title.replace(' ', ''),
          value: [folderkeywordSearch],
        },
      });
    }

    //setIsLoading(true);
    // setRecords([]);
    //return search(metadataSearchObject, skip, take, userJwt)
    return search(metadataSearchObject, skip, take, userInfo).then(async (result) => {
      if (result.isAxiosError === true) {
        setRecords([]);
        setIsLoading(false);
        setIsSearchMetadataLoading(false);
        if (result.response.status === 404) {
          setSearchErrorMessage('No records were found matching your search criteria.');
        } else {
          setSearchErrorMessage("We're sorry, something went wrong, please try your search again.");
        }
      } else {
        let newData = await result.data?.metadata?.map((obj, i) => {
          return {
            ...obj,
            custom_id: 'index_' + i,
          };
        });
        setRecords({
          metadata: newData,
          total_hits: result.data.total_hits,
        });
        // sessionStorage.setItem('searchData', JSON.stringify(newData));

        // sessionStorage('searchData',JSON.stringify(result.data))
        reorderColumn(result.data, latestFilter);
        setRefreshColumn(true);
        setIsLoading(false);
        setIsSearchMetadataLoading(false);
      }
    });
  };

  const getFileType = (filePath) => {
    const returnedDocName = filePath;
    setDocName(returnedDocName);
    const docNameSplit = returnedDocName.split('.');
    const returnedDocType = returnedDocName.split('.')[docNameSplit.length - 1];
    if (returnedDocType === 'pdf') {
      setDocType(returnedDocType);
    } else if (returnedDocType === 'bmp' || returnedDocType === 'jpg' || returnedDocType === 'jpeg' || returnedDocType === 'img') {
      setDocType('image');
    } else {
      setDocType('');
    }
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const fetchSearchDetailsObjectsData = (metadataSearchObject, data) => {
    // if (keywordSearch && keywordSearch.length !== 0) {
    //   metadataSearchObject.searchCriteria.push({
    //     condition: 'CONTAINS',
    //     field1: {
    //       value: [keywordSearch],
    //     },
    //   });
    // }
    setMetadataLoader(true);
    search(metadataSearchObject, 0, data.docCount, userInfo).then((result) => {
      if (result.isAxiosError === true) {
        setIsLoading(false);
        setLoading(false);
        setMetadataLoader(false);
        if (result.response.status === 404) {
          setSearchErrorMessage('No records were found matching your search criteria.');
        } else {
          setSearchErrorMessage("We're sorry, something went wrong, please try your search again.");
        }
        setDetailsObjList([]);
        setDocumentTitle('');
        setDocDetailResponse();
        setObjectMetadata();
        setUserId();
        setAgencyCode();
        setSections();
        setDoc();
        setDocumentTitle();
        getFileType('');
      } else {
        setDetailsObjList(result.data.metadata);
        setMetadataLoader(false);
        if (result.data.metadata.length > 0) {
          selectObjectTypeToView(result.data.metadata[0]._id);
          let selectedObj = result.data.metadata[0];
          // if(objectTypeToView !== null){
          setObjectID(selectedObj._id);
          setIsLoading(true);
          setLoading(true);
          getDocDetail(selectedObj._id)
            .then((response) => {
              console.log(response, 'response');
              setDocDetailResponse(response.data);
              setObjectMetadata(response.data.metadata);
              setUserId(response.data.metadata._id);
              setAgencyCode(response.data.metadata.mda_source.agency_code);
              setSections(response.data.metadata.object_specific.sections);
              setDoc(response.data.documentData.file);
              setDocumentTitle(response.data.metadata.object_type.object_type_name);
              getFileType(response.data.documentData.fileName);
              objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
                // const schema = schemaResponse.data[0].objecttypeMetadata.find((res) => res.metadataStartdate === response.data.metadata.object_type.edition_date);
                const schema = schemaResponse.data[0].objecttypeMetadata[0];
                const required = JSON.parse(schema?.metadataRequired);
                Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
                const optional = JSON.parse(schema.metadataOptional);
                Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

                const combined = Object.assign({}, required, optional);
                const flat = Object.flatten(response.data.metadata.object_specific);

                Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
                populateScimsCustomerData(_.find(result.data.metadata, { _id: objectTypeToView }));
                setMetadataDisplayList(combined);
                setIsLoading(false);
                setLoading(false);
              });
            })
            .catch((err) => {
              setLoading(false);
            });
          // }
        }
        setIsLoading(false);
      }
    });
  };
  const deleteRecordEntry = (id) => {
    let oldIndex;
    detailsObjList.map((itm, index) => {
      if (itm._id === id) {
        oldIndex = index;
        let updatedData = _.remove(detailsObjList, function (n) {
          return n._id !== id;
        });
        if (updatedData?.length === 0) {
          setClickedSearchRow(null);
          setObjectMetadata();
          selectObjectTypeToView(null);
          setDetailsObjList([]);
          setSearchErrorMessage('No records were found matching your search criteria.');
        } else {
          const selectedObj = updatedData[oldIndex] ?? updatedData[0];
          setDetailsObjList(updatedData);
          selectObjectTypeToView(selectedObj._id);
          getDocDetail(selectedObj._id).then((response) => {
            setDocDetailResponse(response.data);
            setObjectMetadata(response.data.metadata);
            setUserId(response.data.metadata._id);
            setAgencyCode(response.data.metadata.mda_source.agency_code);
            setSections(response.data.metadata.object_specific.sections);
            setDoc(response.data.documentData.file);
            setDocumentTitle(response.data.metadata.object_type.object_type_name);
            getFileType(response.data.documentData.fileName);
            objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
              // const schema = schemaResponse.data[0].objecttypeMetadata.find((res) => res.metadataStartdate === response.data.metadata.object_type.edition_date);
              const schema = schemaResponse.data[0].objecttypeMetadata[0];
              const required = JSON.parse(schema?.metadataRequired);
              Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
              const optional = JSON.parse(schema.metadataOptional);
              Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

              const combined = Object.assign({}, required, optional);
              const flat = Object.flatten(response.data.metadata.object_specific);

              Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
              populateScimsCustomerData(_.find(updatedData, { _id: objectTypeToView }));
              setMetadataDisplayList(combined);
              setIsLoading(false);
              setLoading(false);
            });
          });
        }
      }
    });
  };
  const fetchDocDetails = (selectedObj, oldObj) => {
    setIsLoading(true);
    getDocDetail(selectedObj)
      .then((response) => {
        let findEntry = detailsObjList.map((itm) => {
          if (itm._id === oldObj) {
            return response.data.metadata;
          } else return itm;
        });

        if (findEntry.some((itm) => itm?._id === selectedObj)) {
          setDataUpdated(true);
        }

        setDetailsObjList(findEntry);
        selectObjectTypeToView(response.data.metadata._id);
        setDocDetailResponse(response.data);
        setObjectMetadata(response.data.metadata);
        setUserId(response.data.metadata._id);
        setAgencyCode(response.data.metadata.mda_source.agency_code);
        setSections(response.data.metadata.object_specific.sections);
        setDoc(response.data.documentData.file);
        setDocumentTitle(response.data.metadata.object_type.object_type_name);
        getFileType(response.data.documentData.fileName);
        objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
          // const schema = schemaResponse.data[0].objecttypeMetadata.find((res) => res.metadataStartdate === response.data.metadata.object_type.edition_date);
          const schema = schemaResponse.data[0].objecttypeMetadata[0];
          const required = JSON.parse(schema?.metadataRequired);
          Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
          const optional = JSON.parse(schema.metadataOptional);
          Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

          const combined = Object.assign({}, required, optional);
          const flat = Object.flatten(response.data.metadata.object_specific);

          Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
          // populateScimsCustomerData(_.find(result.data.metadata, { _id: objectTypeToView }));
          setMetadataDisplayList(combined);
          setIsLoading(false);
          setLoading(false);
        });

        setDropdownFilterCell((prev) => ({ ...prev, sortKey: 'AddedOn' }));
        fetchDropDownFilterCell({ ...dropdownFilterCell, sortKey: 'AddedOn' });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // const checkIsEmpty = (e) => {
  //   let searchData = JSON.parse(sessionStorage.getItem('searchData'));

  //   searchData.forEach((el, i) => {
  //     if (i === e.dataIndex) {
  //       if (!el.section || !el.objectType) {
  //         return true;
  //       }
  //     }
  //   });
  // };

  const fetchDropDownFilterCell = (cell) => {
    if (cell !== null) {
      const getSysFolderData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));
      let filteredData = {};
      let filterDateTime = {};

      if (cell?.sortKey.toLowerCase().replace(/ /g, '') === 'addedon') {
        filteredData = {
          fieldName: 'object_type.edition_date',
          dataType: 'DATE',
          order: 'DESC',
        };
      } else {
        getSysFolderData
          .map((res, i) => {
            if (dropdownFilterCell?.sortKey === res.title) {
              const field = res.field.substring(4);

              filteredData = { fieldName: field, dataType: 'KEYWORD', order: 'ASC' };
              filterDateTime = {
                fieldName: 'object_type.edition_date',
                dataType: 'DATE',
                order: 'DESC',
              };
            }
            return null;
          })
          .filter((option) => option !== null);
      }

      // sessionStorage.setItem('FullTextQuery', JSON.stringify({}));
      // setSearchErrorMessage('');
      let searchData = records.metadata;

      searchData.forEach((el, i) => {
        if (el.custom_id === cell.dataItem.custom_id) {
          // if (!el.section) {
          //   // setisRequiredError(true);
          //   setRecords((prevData) => {
          //     return {
          //       metadata: prevData.metadata.map((item, i) => {
          //         if (item.custom_id === e.dataItem.custom_id) {
          //           return { ...item };
          //         }
          //         return item;
          //       }),
          //       total_hits: prevData.total_hits,
          //     };
          //   });
          //   return;
          // } else {
          // setisRequiredError(false);
          setCellClicked(cell);
          setObjectID(cell.dataItem._id);
          setLoading(true);
          setSelectedRecord(cell.dataItem);
          sessionStorage.setItem('selectedSearchRecord', JSON.stringify(el));
          // TODO New View Details code
          let paramsArray = null;
          let searchFilters = JSON.parse(JSON.stringify(tempParaArr.length === 0 ? [] : searchParamsObjArray));
          for (const filter in cell.dataItem.key) {
            if (cell.dataItem.key.hasOwnProperty(filter)) {
              let findDuplicate = searchFilters.find((i) => i.key === filter);
              if (findDuplicate === undefined)
                searchFilters.push({
                  key: `${filter}`,
                  value: cell.dataItem.key[filter],
                });
            }
          }
          for (const filter2 in el) {
            if (filter2 === 'section') {
              let findDuplicate = searchFilters.find((i) => i.key === 'object_specific.Section');
              if (findDuplicate === undefined)
                searchFilters.push({
                  key: `object_specific.Section`,
                  value: el[filter2],
                });
            }
            if (filter2 === 'objectType') {
              let findDuplicate = searchFilters.find((i) => i.key === 'object_type.title');
              console.log(findDuplicate, 'findDuplicate');
              if (findDuplicate === undefined)
                searchFilters.push({
                  key: `object_type.title`,
                  value: el[filter2],
                });
            }
          }
          if (fullTextFlag === true) {
            searchFilters.push({
              key: `object_specific.object_ocr`,
              value: keywordSearch,
            });
          } else {
            if (keywordSearch && keywordSearch.length !== 0) {
              searchFilters.push({
                value: keywordSearch,
              });
            }
          }
          let fullTextQuery = searchFilters.find((i) => i.key === 'object_specific.object_ocr');
          if (fullTextQuery) {
            sessionStorage.setItem('FullTextQuery', JSON.stringify(fullTextQuery));
          }
          console.log(searchFilters, 'searchFilters----searchParams', searchParamsObjArray, 'temp---', tempParaArr);

          const metadataSearchObject = CreateSearchObjectSearch(paramsArray ? paramsArray : searchFilters, sort, doctypeSelection, stateSelection, countySelection);
          setIsLoading(true);

          if (cell.sortKey.toLowerCase().replace(/ /g, '') === 'addedon') {
            metadataSearchObject.sort.push(filteredData);
          } else {
            metadataSearchObject.sort.push(filteredData, filterDateTime);
          }

          fetchSearchDetailsObjectsData(metadataSearchObject, cell.dataItem);
        }
      });

      setDropdownFilterChanged(false);
    }
  };

  const onRowClick = (e) => {
    sessionStorage.setItem('FullTextQuery', JSON.stringify({}));
    setSearchErrorMessage('');
    let searchData = records.metadata;
    // let searchData = JSON.parse(sessionStorage.getItem('searchData'));

    searchData.forEach((el, i) => {
      if (el.custom_id === e.dataItem.custom_id) {
        // if (!el.section) {
        //   // setisRequiredError(true);
        //   setRecords((prevData) => {
        //     return {
        //       metadata: prevData.metadata.map((item, i) => {

        //         if (item.custom_id === e.dataItem.custom_id) {
        //           return { ...item };
        //         }
        //         return item;
        //       }),
        //       total_hits: prevData.total_hits,
        //     };
        //   });

        //   return;
        // } else {
        // setisRequiredError(false);
        setCellClicked(e.dataItem);
        setClickedSearchRow(e.dataItem.custom_id);
        setObjectID(e.dataItem._id);
        setLoading(true);
        setSelectedRecord(e.dataItem);

        setDropdownFilterCell(e);
        // setDropdownFilterCell((prev) => ({ ...prev, sortKey: 'AddedOn' }));

        let filteredData = {
          fieldName: 'object_type.edition_date',
          dataType: 'DATE',
          order: 'DESC',
        };

        sessionStorage.setItem('selectedSearchRecord', JSON.stringify(el));
        // TODO New View Details code
        let paramsArray = null;
        let searchFilters = JSON.parse(JSON.stringify(tempParaArr.length === 0 ? [] : searchParamsObjArray));
        for (const filter in e.dataItem.key) {
          if (e.dataItem.key.hasOwnProperty(filter)) {
            let findDuplicate = searchFilters.find((i) => i.key === filter);
            if (findDuplicate === undefined)
              searchFilters.push({
                key: `${filter}`,
                value: e.dataItem.key[filter],
              });
          }
        }
        for (const filter2 in el) {
          if (filter2 === 'section') {
            let findDuplicate = searchFilters.find((i) => i.key === 'object_specific.Section');
            if (findDuplicate === undefined)
              searchFilters.push({
                key: `object_specific.Section`,
                value: el[filter2],
              });
          }
          if (filter2 === 'objectType') {
            let findDuplicate = searchFilters.find((i) => i.key === 'object_type.title');
            console.log(findDuplicate, 'findDuplicate');
            if (findDuplicate === undefined)
              searchFilters.push({
                key: `object_type.title`,
                value: el[filter2],
              });
          }
        }
        if (fullTextFlag === true && (!folderkeywordSearch || folderkeywordSearch?.length === 0)) {
          searchFilters.push({
            key: `object_specific.object_ocr`,
            value: keywordSearch,
          });
        } else {
          if (keywordSearch && keywordSearch.length !== 0) {
            searchFilters.push({
              key: 'keywordSearch',
              value: keywordSearch,
            });
          }
        }

        let fullTextQuery = searchFilters.find((i) => i.key === 'object_specific.object_ocr');
        if (fullTextQuery) {
          sessionStorage.setItem('FullTextQuery', JSON.stringify(fullTextQuery));
        }
        console.log(searchFilters, 'searchFilters----searchParams', searchParamsObjArray, 'temp---', tempParaArr);
        const metadataSearchObject = CreateSearchObjectSearch(paramsArray ? paramsArray : searchFilters, sort, doctypeSelection, stateSelection, countySelection);
        metadataSearchObject.sort.push(filteredData);
        setIsLoading(true);

        fetchSearchDetailsObjectsData(metadataSearchObject, e.dataItem);
        EventEmitter.off('refreshSearchDetailsData');
        EventEmitter.on('refreshSearchDetailsData', async () => {
          fetchSearchDetailsObjectsData(metadataSearchObject, e.dataItem);
        });

        setDetailOpen(true);
        // }
      }
    });
  };
  const handleCheckboxChange = async (e, data, obj) => {
    const checkboxes = document.getElementsByName('rowsCheckbox');
    let newData = await data.map((obj, i) => {
      return {
        ...obj,
        custom_id: 'index_' + i,
      };
    });
    let rows = [];
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        let data1 = newData.filter((itm) => itm.custom_id === checkboxes[i].id);
        rows.push(...data1);
      }
    }
    setRowsChecked(rows);

    const allCheckboxesChecked = rows.length === checkboxes.length;
    document.getElementById('selectAllCheckbox').checked = allCheckboxesChecked;
  };

  const handleAllCheckboxChange = (e, data) => {
    const checkboxes = document.getElementsByName('rowsCheckbox');
    let rows = [];
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = e.target.checked;
      checkboxes[i].ariaChecked = String(e.target.checked);
      checkboxes[i].value = String(e.target.checked);
      if (e.target.checked) {
        rows.push(checkboxes[i].id);
      } else {
        rows = rows.filter((existingId) => existingId !== checkboxes[i].id);
      }
    }

    if (e.target.checked === false) {
      setRowsChecked([]);
    } else {
      setRowsChecked(data);
    }
  };

  const clickCell = (props, data) => {
    return (
      <td>
        <div className="selectCell">
          <input
            style={{ marginRight: '0px', paddingRight: '0px' }}
            type="checkbox"
            className="fsa-checkbox"
            name="rowsCheckbox"
            id={props.dataItem.custom_id}
            onChange={(e) => handleCheckboxChange(e, data, props.dataItem)}
          />
          <label htmlFor={props.dataItem.custom_id}>
            <span className="fsa-sr-only">Label</span>
          </label>
          {/* <span 
          style={{marginLeft: "0px"}} 
          title="Click to open detailed view" 
          className="view"
          onClick={() => onRowClick(props)}
        >
          View
        </span> */}
        </div>
      </td>
    );
  };

  // const reorderColumn = (data, latestFilter = undefined) => {
  //   if (data) {
  //     let reorderColumns = [];

  //     reorderColumns = DrmsFields.map((column, index) => {
  //       if (column.field === "SELECTED_FIELD") {
  //         return  <Column
  //                   sortable={false}
  //                   reorderable={false}
  //                   headerCell={checkheaderStyle}
  //                   cell={clickCell}
  //                   headerSelectionValue={DATA_ITEM_KEY}
  //                   title={column.title}
  //                   key={index}
  //                   width={105}
  //                 />;
  //       } else if (column.field === "doc_modification_history.modification_date") {
  //         return  <Column
  //                   headerCell={headerStyle}
  //                   field={column.field}
  //                   title={column.title}
  //                   key={index}
  //                   cell={element => (
  //                     <td style={{textAlign: "center"}}>
  //                       {
  //                         element.dataItem.doc_modification_history[0].modification_date.split("T").join(" ")
  //                       }
  //                     </td>
  //                   )}
  //                   width={"200"}
  //                 />;
  //       } else if (column.field === "doc_modification_history.user_name") {
  //         return  <Column
  //                   headerCell={headerStyle}
  //                   field={column.field}
  //                   title={column.title}
  //                   key={index}
  //                   cell={element => (
  //                     <td style={{textAlign: "center"}}>
  //                       {
  //                         element.dataItem.doc_modification_history[0].user_name
  //                       }
  //                     </td>
  //                   )}
  //                   width={"200"}
  //                 />;
  //       } else {
  //         return  <Column
  //                   // headerCell={headerStyle}
  //                   columnMenu={ColumnMenu}
  //                   filter={latestFilter ? latestFilter.find(item => item.key === column.field) ? latestFilter.find(item => item.key === column.field).value : '' : ''}
  //                   field={column.field} title={column.title} key={index} cell={column.cell}
  //                   width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
  //                 />;
  //       }

  //     })

  //     searchFormObjArray.forEach(element => {
  //       reorderColumns.forEach(function (column, i) {
  //         if (column.props.field === element.friendlyName) {
  //           reorderColumns.splice(i, 1);
  //           reorderColumns.unshift(column);
  //         }
  //       });
  //     });
  //     setColumnsToShow(reorderColumns);
  //   } else {
  //     console.log("no data");
  //   }
  // }

  //ON FORM SUBMIT
  const fetchSystemFolderData = async () => {
    await searchPageColumnsAPI().then(async (response) => {
      let columnNewArray = [];
      sessionStorage.setItem('SystemColumns', JSON.stringify(response.data));
      await response.data.map((column) => {
        columnNewArray.push({
          metaId: '_id',
          field: column.name,
          title: column.name,
          dataType: 'text',
          showMenuIcon: 'true',
          sortType: '',
          data: column,
        });
        setSystemFolderData(columnNewArray);
      });
      let columnNewArray1 = [
        {
          metaId: 0,
          field: 'SELECTED_FIELD',
          title: 'Select',
        },
      ];
      await response.data.map((column, idx) => {
        columnNewArray1.push({
          metaId: '_id',
          field: 'key.object_specific.' + column.name.replace(' ', ''),
          title: column.name,
          dataType: 'text',
          showMenuIcon: 'true',
          sortType: '',
          data: column,
        });
        if (response.data.length === idx + 1) {
          columnNewArray1.push({
            metaId: idx + 1,
            field: 'VIEW_DETAILS',
            title: '',
          });
        }
        sessionStorage.setItem('systemFolderOptions', JSON.stringify(columnNewArray1));
      });
    });
  };
  useEffect(() => {
    searchHolds({ holdstatus: 'Active' }).then((res) => {
      setHoldResponse(res?.data);
    });
  }, []);

  useEffect(() => {
    reorderColumn(records, null);
  }, [refreshColumn]);

  const sectionDropListChange = async (e, dataItem, field) => {
    // let searchData = JSON.parse(sessionStorage.getItem('searchData'));
    let searchData = records.metadata;

    await searchData.forEach((x, i) => {
      if ('index_' + i === dataItem.dataItem.custom_id) {
        if (e.target.value === 'Select Section' || e.target.value === 'Select ObjectType') {
          delete x[field];
        } else x[field] = e.target.value;
      }
    });
    setRecords((prevData) => {
      return {
        metadata: prevData.metadata.map((item, i) => {
          if (i === dataItem.dataItem.custom_id) {
            return { ...item, [field]: e.target.value };
          }
          return item;
        }),
        ...prevData,
      };
    });
    // console.log(JSON.parse(sessionStorage.getItem('selectedSearchRecord')));
    // sessionStorage.setItem('searchData', JSON.stringify(searchData));
  };

  const onChangeDocType = (e) => {
    if (e == null) {
      setFolderkeywordSearch('');
    } else setFolderkeywordSearch(e?.label);
    // advSearchStateChange();
  };

  const onSelectSort = (column) => {
    let obj = [];
    let sysData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));
    sysData.forEach((itm) => {
      if (itm.field === column.field) {
        obj = itm;
      }
    });
    return (
      <div className="searchTableHeaderStyle" onClick={() => onSortColmun(column)}>
        <div className="searchTableHeaderTitle">
          <div>{column.title}</div>
          {obj.showMenuIcon && <div>{obj.sortType === '' ? '' : obj.sortType === 'ASC' ? '⬆' : '⬇'}</div>}
        </div>
      </div>
    );
  };
  const onSortColmun = (column) => {
    let sysData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));
    sysData.forEach((obj) => {
      if (obj.field === column.field) {
        if (obj.sortType === '') {
          obj.sortType = 'ASC';
          sessionStorage.setItem('systemFolderOptions', JSON.stringify(sysData));
        } else if (obj.sortType === 'ASC') {
          obj.sortType = 'DESC';
          sessionStorage.setItem('systemFolderOptions', JSON.stringify(sysData));
        } else {
          obj.sortType = '';
          onRemoveSort();
          return;
        }
        onSortData(obj);
      } else {
        obj.sortType = '';
      }
      sessionStorage.setItem('systemFolderOptions', JSON.stringify(sysData));
    });
  };

  useEffect(() => {
    if (sorting !== undefined) {
      searchMetadata(skip, take, sort, null, null, userInfo);
    }
  }, [sorting]);

  const onSortData = async (selectedColumn) => {
    setLoading(true);

    const newField = selectedColumn.field.substring(4);

    let payload = {
      fieldName: newField,
      order: selectedColumn.sortType,
    };

    setSorting(payload);
  };

  const onRemoveSort = async () => {
    setLoading(true);
    setSorting(null);
  };

  const reorderColumn = (data, latestFilter = undefined) => {
    if (data) {
      let reorderColumns = [];
      // meta Data
      let sysData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));

      data?.metadata?.map((meta) => {
        let objArr = Object.keys(meta.key);
        objArr.map((keyStr) => {
          reorderColumns = sysData.map((column, index) => {
            if (column.field === 'SELECTED_FIELD') {
              return (
                <Column
                  sortable={false}
                  reorderable={false}
                  headerCell={(e) => checkheaderStyle(e, data?.metadata)}
                  cell={(e) => clickCell(e, data?.metadata)}
                  headerSelectionValue={DATA_ITEM_KEY}
                  title={column.title}
                  key={index}
                  width={105}
                />
              );
            } else if (column.field === 'doc_modification_history.modification_date') {
              return (
                <Column
                  headerCell={headerStyle}
                  field={column.field}
                  title={column.title}
                  key={index}
                  cell={(element) => <td style={{ textAlign: 'center' }}>{element.dataItem.doc_modification_history[0].modification_date.split('T').join(' ')}</td>}
                  width={'200'}
                />
              );
            } else if (column.field === 'doc_modification_history.user_name') {
              return (
                <Column
                  headerCell={headerStyle}
                  field={column.field}
                  title={column.title}
                  key={index}
                  cell={(element) => <td style={{ textAlign: 'center' }}>{element.dataItem.doc_modification_history[0].user_name}</td>}
                  width={'200'}
                />
              );
              // } else if (userPermissions('View_Object') === true && column.field === 'VIEW_DETAILS') {
            } else if (column.field === 'VIEW_DETAILS') {
              return (
                <Column
                  // headerCell={headerStyle}
                  field=""
                  cell={(dataItem) => {
                    return (
                      <td>
                        <div className="textCenter">
                          <img src={searchImg} style={{ cursor: 'pointer' }} onClick={() => onRowClick(dataItem)} alt={'searchImg'} ref={searchIconRef}></img>
                        </div>
                      </td>
                    );
                  }}
                  title=""
                  width={100}
                  filterable={false}
                />
              );
            } else if (column.field === 'key.object_specific.Section') {
              return (
                <Column
                  headerCell={column.data?.lowCard === false ? () => onSelectSort(column) : undefined}
                  field={'Section'}
                  cell={(dataItem) => {
                    return (
                      <td>
                        {column.data?.lowCard === true ? (
                          <DropDownList
                            className={`w-100`}
                            // style={{
                            //   borderColor:
                            //     Object.keys(dataItem.dataItem).length > 0 && !dataItem.dataItem?.section === true && dataItem.dataItem.isRequired === true
                            //       ? 'red'
                            //       : 'rgba(0, 0, 0, 0.08)',
                            // }}
                            data={dataItem.dataItem.Sections}
                            defaultItem={'Select Section'}
                            onChange={(e) => sectionDropListChange(e, dataItem, 'section')}
                            popupSettings={{ className: 'search-dropdownlist-class' }}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    );
                  }}
                  title=""
                  width={200}
                  filterable={false}
                />
              );
            } else if (column.field === 'key.object_specific.ObjectType') {
              return (
                <Column
                  headerCell={column.data?.lowCard === false ? () => onSelectSort(column) : undefined}
                  field={'ObjectType'}
                  cell={(dataItem) => {
                    return (
                      <td>
                        {column.data?.lowCard === true ? (
                          <DropDownList
                            className={`w-100`}
                            data={dataItem.dataItem.ObjectTypes}
                            defaultItem={'Select ObjectType'}
                            onChange={(e) => sectionDropListChange(e, dataItem, 'objectType')}
                            popupSettings={{ className: 'search-dropdownlist-class' }}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    );
                  }}
                  title=""
                  width={200}
                  filterable={false}
                />
              );
            } else if (column.field === `key.${keyStr}`) {
              return (
                <Column
                  headerCell={column.data?.lowCard === false ? () => onSelectSort(column) : undefined}
                  field={meta.key[keyStr]}
                  title={column.title}
                  key={index}
                  width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                  cell={(props) => {
                    return (
                      <td>
                        <label>
                          <span>{props.dataItem.key[column.field.split('key.')[1]]}</span>
                        </label>
                      </td>
                    );
                  }}
                />
              );
            } else {
              return (
                <Column
                  headerCell={column.data?.lowCard === false ? () => onSelectSort(column) : undefined}
                  // headerCell={headerStyle}
                  // columnMenu={ColumnMenu}
                  // filter={latestFilter ? (latestFilter.find((item) => item.key === column.field) ? latestFilter.find((item) => item.key === column.field).value : '') : ''}
                  field={meta.key[keyStr]}
                  title={column.title}
                  key={index}
                  cell={(props) => {
                    // console.log(props, 'props from celll else ');
                    return (
                      <td>
                        <label>
                          <span>{props.dataItem.key[column.field.split('key.')[1]]}</span>
                        </label>
                      </td>
                    );
                  }}
                  width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                />
              );
            }
          });
        });
      });
      // searchFormObjArray.forEach((element) => {
      //   reorderColumns.forEach(function (column, i) {
      //     if (column.props.field === element.friendlyName) {
      //       reorderColumns.splice(i, 1);
      //       reorderColumns.unshift(column);
      //     }
      //   });
      // });
      setColumnsToShow(reorderColumns);
    } else {
      // console.log('no data');
    }
    setRefreshColumn(false);
  };
  const advSearchStateChange = (e) => {
    setRowsChecked([]);
    if (e) e.preventDefault();
    setdrmsSearch(true);
    setFilter([], searchMetadata(0, take, sort, null, null, userInfo));

    setSkip(0);
    // closePopup();
  };

  const onSearchDetails = (e) => {
    // e.preventDefault();
    setdrmsSearch(true);
    setFilter([], searchMetadata(0, take, sort, null, null, userInfo));
    setSkip(0);
    closePopup();
  };

  const onSearchData = (e) => {
    // e.preventDefault();
    setdrmsSearch(true);
    setFilter([], searchMetadata(0, take, sort, null, null, userInfo));

    setSkip(0);
    closePopup();
  };
  const getSessionParams = () => {
    return btoa(
      'searchFormParams=' +
        JSON.stringify(searchFormObjArray) +
        '&searchParams=' +
        JSON.stringify(searchParamsObjArray) +
        '&sort=' +
        JSON.stringify(sort) +
        '&skip=' +
        skip +
        '&take=' +
        take
    );
  };

  const clearAll = (e) => {
    // e.preventDefault();
    setSearchFormObjArrayTemp([]);
    setSearchParamsObjArrayTemp([]);
    setSearchFormObjArray([]);
    setSearchParamsObjArray([]);
    setDoctypeSelection(null);
    setMetadataSelection(null);
    setMetadataEmpty(true);
    setColumnSearchEmpty(true);
    setDoctypeEmpty(true);
    setKeywordEmpty(true);
    setKeywordSearch('');
    setStateSelection(null);
    setCountySelection(null);
    tempParaArr = [];
    const metadataSearchObject = createSearchObject([], sort, doctypeSelection, stateSelection, countySelection);
    setIsLoading(true);
    if (keywordSearch && keywordSearch?.length !== 0 && fullTextFlag === false) {
      metadataSearchObject.searchCriteria.push({
        condition: 'CONTAINS',
        field1: {
          value: [keywordSearch],
        },
      });
    } else if (fullTextFlag === true && keywordSearch && keywordSearch?.length !== 0) {
      metadataSearchObject.searchCriteria.push({
        condition: 'CONTAINS',
        field1: {
          name: 'object_specific.object_ocr',
          value: [keywordSearch],
        },
      });
    }

    if (folderkeywordSearch && folderkeywordSearch.length !== 0) {
      metadataSearchObject.searchCriteria.push({
        condition: 'CONTAINS',
        field1: {
          name: systemFolderData.length > 0 && 'object_specific.' + systemFolderData[1].title.replace(' ', ''),
          value: [folderkeywordSearch],
        },
      });
    }
    closePopup();

    setIsLoading(true);
    // setRecords([]);
    //return search(metadataSearchObject, skip, take, userJwt)
    return search(metadataSearchObject, skip, take, userInfo).then(async (result) => {
      if (result.isAxiosError === true) {
        setRecords([]);
        setIsLoading(false);
        if (result.response.status === 404) {
          setSearchErrorMessage('No records were found matching your search criteria.');
        } else {
          setSearchErrorMessage("We're sorry, something went wrong, please try your search again.");
        }
      } else {
        let newData = await result.data.metadata.map((obj, i) => {
          return {
            ...obj,
            custom_id: 'index_' + i,
          };
        });
        setRecords({
          metadata: newData,
          total_hits: result.data.total_hits,
        });
        // sessionStorage.setItem('searchData', JSON.stringify(newData));

        // sessionStorage('searchData',JSON.stringify(result.data))
        reorderColumn(result.data, null);
        setRefreshColumn(true);
        setIsLoading(false);
      }
    });
  };

  //ON USER Text INPUT
  // Checks to see if there is already a value by metadata name
  // in the search params object
  // If there is, replaces it. If there isn't, adds it.
  let cloneParams = [];
  let cloneForm = [];
  const handleChange = (name, event) => {
    // event.preventDefault();
    let updater = (prevSearchParamsObjArray) => {
      return prevSearchParamsObjArray.find((element) => element.key === name) === undefined
        ? prevSearchParamsObjArray.concat([
            {
              key: name,
              friendlyName: event.friendlyName,
              value: event.target.value,
            },
          ])
        : prevSearchParamsObjArray.map((obj) =>
            obj.key === name
              ? obj.key === 'object_specific.Group' ||
                obj.key === 'object_specific.Section' ||
                obj.key === 'object_specific.ObjectType' ||
                obj.key === 'object_specific.employmentstatus' ||
                obj.key === 'object_specific.statusreason'
                ? {
                    key: name,
                    friendlyName: obj.friendlyName,
                    value: event ? event.label : '',
                  }
                : {
                    key: name,
                    friendlyName: obj.friendlyName,
                    value: event.target.value,
                  }
              : obj
          );
    };
    setSearchParamsObjArray((prev) => updater(prev));
  };

  const prevStateHandler = () => {
    cloneParams = _.cloneDeep(searchParamsObjArray);
    cloneForm = _.cloneDeep(searchFormObjArray);
    setSearchParamsObjArrayTemp(cloneParams);
    setSearchFormObjArrayTemp(cloneForm);
  };
  const closeHandler = () => {
    setSearchFormObjArray(searchFormObjArrayTemp);
    setSearchParamsObjArray(searchParamsObjArrayTemp);
  };

  useEffect(() => {
    if (popupOpen === true) {
      prevStateHandler();
      setMetaModalFLag(false);
    }
    if (popupOpen === false && metaModalFlag === false) {
      closeHandler();
    }
  }, [popupOpen, metaModalFlag]);

  const handleDateChange = (name, event, setValue) => {
    const date = Moment(event.target.value).format('YYYY-MM-DD');
    setSearchParamsObjArray(searchParamsObjArray.filter((param) => param.key !== 'DispositionDt').filter((param) => param.key !== 'IndexDt'));
    if (!checkIfValidDate(name, date)) {
      setValue('');
      return;
    }
    setValue(event.value);
    setSearchParamsObjArray((prevSearchParamsObjArray) => {
      let foundParam = prevSearchParamsObjArray.find((obj) => obj.key === name);
      if (foundParam) {
        return prevSearchParamsObjArray.map((obj) =>
          obj.key === name || obj.key === name
            ? {
                key: name,
                friendlyName: obj.friendlyName,
                value: date,
              }
            : obj
        );
      } else {
        return prevSearchParamsObjArray.concat([
          {
            key: name,
            value: date,
          },
        ]);
      }
    });
  };

  const checkIfValidDate = (name, value) => {
    const prefix = name.startsWith('start_') ? 'end_' : 'start_';
    const fieldName = name.substring(name.indexOf('_') + 1);
    const foundDate = searchParamsObjArray.find((obj) => obj.key === prefix.concat(fieldName));

    if (foundDate && prefix === 'start_' && new Date(foundDate.value).getTime() > new Date(value).getTime()) {
      alert('Start Date must not be after End Date');
      setSearchParamsObjArray(searchParamsObjArray.filter((obj) => obj.key === foundDate.key));
      return false;
    } else if (foundDate && prefix === 'end_' && new Date(foundDate.value).getTime() < new Date(value).getTime()) {
      alert('End Date must not be before Start Date');
      setSearchParamsObjArray(searchParamsObjArray.filter((obj) => obj.key === foundDate.key));
      return false;
    } else {
      return true;
    }
  };

  const handleKeywordChange = (e) => {
    const { value } = e.target;
    setFolderkeywordSearch(undefined);
    if (value === '') {
      setKeywordSearch('');
      setFullTextFlag(false);
    } else {
      setKeywordSearch(value);
      setKeywordEmpty(false);
    }
  };
  const handleFolderKeywordChange = (e) => {
    const { value } = e.target;
    setKeywordSearch(undefined);
    if (value === '') {
      setFolderkeywordSearch('');
    } else {
      setFolderkeywordSearch(value);
    }
  };

  const retrieveValue = (name) => {
    return searchParamsObjArray.find((element) => element.key === name)?.value;
  };

  const checkheaderStyle = (column, data) => {
    return (
      <a style={{ backgroundColor: '#25a4db' }} className="k-link ">
        <input id="selectAllCheckbox" name="selectAllCheckbox" type="checkbox" className="fsa-checkbox" onChange={(e) => handleAllCheckboxChange(e, data)}></input>
        <label htmlFor="selectAllCheckbox">
          <span className="fsa-sr-only"> Select All Rows Checkbox </span>
        </label>
        <span style={{ fontWeight: 'bold', color: 'white', fontSize: '16px' }}> {column.title} </span>
      </a>
    );
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <CustomColumnMenu columns={DrmsFields} onColumnsSubmit={handleColumnChange} onColumnCancel={onColumnCancel} column={props.column} />
      </div>
    );
  };

  const headerStyle = (column) => {
    return (
      <a style={{ textAlign: 'center', backgroundColor: '#25a4db' }} className="k-link">
        <span style={{ fontWeight: 'bold', color: 'white', fontSize: '16px', paddingRight: '1em' }} onClick={column.onClick}>
          {column.title}
        </span>
        <div className={'filter-hide'}>
          <input style={{ width: '80%' }} onChange={handleColumnChange(column.field)}></input>
        </div>
      </a>
    );
  };

  const onColumnCancel = (columnField, value, setFilterVal) => (e) => {
    setFilterVal('');
    let columnFilter = [...filter];
    columnFilter = columnFilter.filter((item) => item.key !== columnField);
    let array = [...searchParamsObjArray, ...columnFilter];
    setFilter(columnFilter, searchMetadata(0, take, sort, array, columnFilter, userInfo));

    setSkip(0);
  };

  const handleColumnChange = (columnField, value) => (e) => {
    let emptyValue = false;
    //newFilter variables capture duplicates
    let newFilterIndex = -1;
    let newFilter = {};
    let filterParamsArray = [...searchParamsObjArray, ...filter];
    for (var i = 0; i < filterParamsArray.length; i++) {
      let filter = filterParamsArray[i];
      if (value == '') {
        emptyValue = true;
      }
      if (filter.key == columnField) {
        if (filter.value !== value) {
          filter.value = value;
          newFilter = filter;
          newFilterIndex = i;
        }
      }
    }
    if (newFilterIndex !== -1) {
      //If user enters an empty value, and parameter
      if (emptyValue) {
        filterParamsArray.splice(newFilterIndex, 1);
      } else {
        filterParamsArray[newFilterIndex] = newFilter;
      }
    } else {
      if (!emptyValue) {
        if (filterParamsArray.find((item) => item.key === columnField)) {
          filterParamsArray.map((item) => (item.key === columnField ? { key: columnField, value: value } : item));
        } else {
          filterParamsArray.push({ key: columnField, value: value });
        }
      } else {
        filterParamsArray = [];
      }
    }
    setColumnSearchEmpty(false);
    let columnFilter = [...filter];
    if (columnFilter.find((element) => element.key === columnField)) {
      columnFilter.map((item) => (item.key === columnField ? { key: columnField, value: value } : item));
    } else {
      columnFilter.push({ key: columnField, value: value });
    }
    setFilter(columnFilter, searchMetadata(0, take, sort, filterParamsArray, columnFilter, userInfo));
    setSkip(0);
  };

  const dropDownStyle = {
    multiValue: (styles) => {
      const color = '#205493';
      return {
        ...styles,
        backgroundColor: color,
        color: 'white',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
    }),
  };

  const getDataType = (systemName) => {
    return metafieldResponse.find((field) => field.display_name === systemName).type;
  };

  const renderSpecsDiv = (userParams) => {
    const systemName = userParams.friendlyName;
    const dataType = getDataType(systemName);
    if (dataType === 'Date') {
      return (
        <DateSearchInput
          className="date-field"
          userParams={userParams}
          retrieveValue={retrieveValue}
          removeParam={removeParam}
          handleDateChange={handleDateChange}
          value={searchParamsObjArray.find((i) => i.key === 'object_specific.statusstartdate')?.value}
        ></DateSearchInput>
      );
    } else if (dataType === 'boolean') {
      return (
        <BoolInput
          userParams={userParams}
          removeParam={removeParam}
          handleChange={handleChange}
          checked={searchParamsObjArray.map((i) => (i.friendlyName.includes('Term') ? i.value : ''))}
        ></BoolInput>
      );
    } else if (systemName === 'Group') {
      return (
        <div className="specsDivs1">
          <div className="d-flex align-items-center justify-content-between">
            <label style={{ marginBottom: 0 }}>{systemName}</label>
            <div style={{ textAlign: 'right' }} className={'inputRemoveIcon'} onClick={() => removeParam('object_specific.Group')}>
              x
            </div>
          </div>
          <AsyncSelect
            isClearable
            defaultOptions
            placeholder="Select Group"
            value={searchParamsObjArray.map((i) => i.key === 'object_specific.Group' && i.value !== '' && { label: i.value, value: i.value })}
            loadOptions={(inputValue, callback) => {
              if (inputValue === '') {
                let options = [];
                groupList.map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              } else {
                let options = [];
                groupFilterData(inputValue).map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              }
            }}
            onChange={(e) => {
              handleChange('object_specific.Group', e);
            }}
          />
        </div>
      );
    } else if (systemName === 'Section') {
      return (
        <div className="specsDivs1">
          <div className="d-flex align-items-center justify-content-between">
            <label style={{ marginBottom: 0 }}>{systemName}</label>
            <div style={{ textAlign: 'right' }} className={'inputRemoveIcon'} onClick={() => removeParam('object_specific.Section')}>
              x
            </div>
          </div>
          <AsyncSelect
            isClearable
            defaultOptions
            placeholder="Select Section"
            value={searchParamsObjArray.map((i) => i.key === 'object_specific.Section' && i.value !== '' && { label: i.value, value: i.value })}
            loadOptions={(inputValue, callback) => {
              if (inputValue === '') {
                let options = [];
                sectionList.map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              } else {
                let options = [];
                sectionFilterData(inputValue).map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              }
            }}
            onChange={(e) => {
              handleChange('object_specific.Section', e);
            }}
          />
        </div>
      );
    } else if (systemName === 'ObjectType') {
      return (
        <div className="specsDivs1">
          <div className="d-flex align-items-center justify-content-between">
            <label style={{ marginBottom: 0 }}>{systemName}</label>
            <div style={{ textAlign: 'right' }} className={'inputRemoveIcon'} onClick={() => removeParam('object_specific.ObjectType')}>
              x
            </div>
          </div>
          <AsyncSelect
            isClearable
            defaultOptions
            placeholder="Select ObjectType"
            value={searchParamsObjArray.map((i) => i.key === 'object_specific.ObjectType' && i.value !== '' && { label: i.value, value: i.value })}
            loadOptions={(inputValue, callback) => {
              if (inputValue === '') {
                let options = [];
                objectTypeList.map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              } else {
                let options = [];
                objectTypeFilterData(inputValue).map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              }
            }}
            onChange={(e) => {
              handleChange('object_specific.ObjectType', e);
            }}
          />
        </div>
      );
    } else if (systemName === 'Status Reason') {
      return (
        <div className="specsDivs1">
          <div className="d-flex align-items-center justify-content-between">
            <label style={{ marginBottom: 0 }}>{systemName}</label>
            <div style={{ textAlign: 'right' }} className={'inputRemoveIcon'} onClick={() => removeParam('object_specific.statusreason')}>
              x
            </div>
          </div>
          <AsyncSelect
            isClearable
            defaultOptions
            placeholder="Select Reason"
            value={searchParamsObjArray.map((i) => i.key === 'object_specific.statusreason' && i.value !== '' && { label: i.value, value: i.value })}
            loadOptions={(inputValue, callback) => {
              if (inputValue === '') {
                let options = [];
                statusReasonList.map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              } else {
                let options = [];
                statusReasonFilter(inputValue).map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              }
            }}
            onChange={(e) => {
              handleChange('object_specific.statusreason', e);
            }}
          />
        </div>
      );
    } else if (systemName === 'Employment Status') {
      return (
        <div className="specsDivs1">
          <div className="d-flex align-items-center justify-content-between">
            <label style={{ marginBottom: 0 }}>{systemName}</label>
            <div style={{ textAlign: 'right' }} className={'inputRemoveIcon'} onClick={() => removeParam('object_specific.employmentstatus')}>
              x
            </div>
          </div>
          <AsyncSelect
            isClearable
            defaultOptions
            placeholder="Select Status"
            value={searchParamsObjArray.map((i) => i.key === 'object_specific.employmentstatus' && i.value !== '' && { label: i.value, value: i.value })}
            loadOptions={(inputValue, callback) => {
              if (inputValue === '') {
                let options = [];
                employmentStatusList.map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              } else {
                let options = [];
                employmentStatusFilter(inputValue).map((res) => {
                  options.push({ label: res.display_name, value: res.id });
                  setTimeout(() => {
                    callback(options);
                  }, 1000);
                });
                callback(options);
              }
            }}
            onChange={(e) => {
              handleChange('object_specific.employmentstatus', e);
            }}
          />
        </div>
      );
    } else {
      return <TextSearchInput userParams={userParams} retrieveValue={retrieveValue} removeParam={removeParam} handleChange={handleChange}></TextSearchInput>;
    }
  };

  const handleMetaModal = () => {
    //ReactDOM.findDOMNode(this.refs['elementRef']).getBoundingClientRect();
    if (!popupOpen) {
      setPopupOpen(true);
    } else {
      setPopupOpen(false);
    }
  };
  const closePopup = () => {
    setSearchParamsObjArrayTemp([]);
    setPopupOpen(false);
  };

  const clearProducerSearch = () => {
    setIsProducerSearch(false);
    searchMetadata(0, take, sort, removeParam('Producer Identified'), null, userInfo);
  };

  const indexFilterData = (filter) => {
    const data = indexFieldResponse.slice();
    return filterBy(data, filter);
  };

  const getOptionLabel = (option) => option.display_name;
  const getOptionValue = (option) => option.name;

  // Metadata search popup
  const closeMetadatModal = () => {
    setPopupOpen(false);
  };
  const handleInputTitleChange = (updatedInputTitle) => {
    // console.log('Updated inputTitle in parent component:', updatedInputTitle);
    setMetaModalFLag(true);
  };

  const updateObjSelected = (e) => {
    // setIsLoading(true);
    setLoading(true);
    selectObjectTypeToView(e);

    // let paramsArray =null;
    // const metadataSearchObject = CreateSearchObjectSearch(paramsArray ? paramsArray : searchParamsObjArray, sort, doctypeSelection, stateSelection, countySelection);

    // fetchSearchDetailsObjectsData(metadataSearchObject)
    getDocDetail(e)
      .then((response) => {
        setDocDetailResponse(response.data);
        setObjectMetadata(response.data.metadata);
        setUserId(response.data.metadata._id);
        setAgencyCode(response.data.metadata.mda_source.agency_code);
        setSections(response.data.metadata.object_specific.sections);
        setDoc(response.data.documentData.file);
        setDocumentTitle(response.data.metadata.object_type.object_type_name);
        getFileType(response.data.documentData.fileName);
        objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
          const schema = schemaResponse.data[0].objecttypeMetadata[0];
          const required = JSON.parse(schema.metadataRequired);
          Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
          const optional = JSON.parse(schema.metadataOptional);
          Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

          const combined = Object.assign({}, required, optional);
          const flat = Object.flatten(response.data.metadata.object_specific);

          Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
          const selectedObjTYpe = detailsObjList.find((element) => element._id === objectTypeToView);

          populateScimsCustomerData(selectedObjTYpe);
          setMetadataDisplayList(combined);
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const onDeleteObject = (data) => {
    setDeleteData(data);
    setDeleteModalShow(true);
  };

  const DeleteDataFunc = async (data) => {
    var uniqueIdsArray = [];
    for (let i = 0; i < data.length; i++) {
      const idArray = data[i].aggregations.asMap.employee_data.hits.hits;
      for (let j = 0; j < idArray.length; j++) {
        uniqueIdsArray.push(idArray[j].id);
      }
    }
    // setIsLoadingSpinner(true);
    uniqueIdsArray = _.uniq(_.map(uniqueIdsArray)); // commented because this prevents sending more than one id while deleting
    if (uniqueIdsArray.length > 0) {
      try {
        const result = await deleteObjectSearch(uniqueIdsArray);
        if (result.data) {
          setDeleteData([]);
          setDeleteModalShow(false);
          setIsLoadingSpinner(false);
          setIsSuccessGrowlHidden(false);
          advSearchStateChange();
          setMessage('Record deleted successfully');
          setTimeout(() => {
            setIsSuccessGrowlHidden(true);
          }, 3000);
        } else if (result.error) {
          setIsLoadingSpinner(false);
          setIsErrorGrowlHidden(false);
          setMessage(result.error.message ? result.error.message : result.error ? result.error : 'Something went wrong');
          setTimeout(() => {
            setIsErrorGrowlHidden(true);
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const metaClearOnSearch = () => {
    setKeywordEmpty(true);
    setKeywordSearch('');
    setFolderkeywordSearch('');
    setFullTextFlag(false);
  };

  return (
    (isLoading && !drmsSearch && <LoadingSplash></LoadingSplash>) || (
      <main className="content" tabIndex="-1">
        <div
          className="content"
          style={
            detailOpen || objFileCodePopupOpen || isBulkPopupOpen
              ? {
                  overflowY: 'hidden',
                  maxHeight: '75vh',
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                  paddingTop: '0px',
                }
              : {}
          }
        >
          {(isSearchMetadataLoading || isLoadingSpinner) && <GlobalLoader />}

          <div className="pageContent">
            <h1 className="mt-0">Search</h1>
            <div className="searchCon">
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="flex">
                <form style={{ margin: '10px 0', width: '100%', justifyContent: 'space-between' }} className="searchBar" onSubmit={advSearchStateChange}>
                  <div className="advanced flexrow">
                    <div className="advanced search-form" style={{ width: '100%', justifyContent: 'space-between', display: 'flex', marginRight: 0 }}>
                      <div className="keyWordSearchWrapper">
                        <div className="form-field keyword">
                          {userPermissions['Keyword_Search'] === true && (
                            <>
                              <div>
                                <b>Keyword Search</b>
                              </div>
                              <input
                                //value={retrieveValue(systemName)}
                                placeholder=""
                                type="text"
                                style={{ minWidth: '600px' }}
                                value={keywordSearch === undefined || keywordSearch === '' ? '' : keywordSearch}
                                //name={friendlyName}
                                onChange={handleKeywordChange}
                                //onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                              />
                              <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
                            </>
                          )}

                          {/* <div style={{ marginTop: '10px' }} className="flexrow underKeywordContainer ">
                            {userPermissions['Metadata_Search'] === true && (
                              <div className="modal-link-con" ref={elementRef}>
                                <p className="modal-link" style={{ cursor: 'pointer' }} onClick={handleMetaModal}>
                                  Metadata Search
                                  <span>
                                    {popupOpen ? (
                                      <FontAwesomeIcon onClick={closePopup} className="float-right chevron" icon={faChevronUp} />
                                    ) : (
                                      <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                    )}
                                  </span>
                                </p>
                              </div>
                            )}
                            <div className="collapsed-params">
                              <p>Search Parameters: {searchParamsObjArray.length === 0 && <b style={{ fontSize: '16px' }}>None</b>} </p>
                              {searchParamsObjArray.map((userParams) =>
                                userParams.friendlyName ? (
                                  <div className="param-pair">
                                    <p className="collapsedKey">
                                      <b>{userParams.friendlyName}</b>:
                                    </p>{' '}
                                    <span>{userParams.value} </span>
                                  </div>
                                ) : (
                                  <></>
                                )
                              )}
                            </div>
                            {userPermissions['Full_Text_Search'] === true && (
                              <div className="fullTextContainer">
                                <input type="checkbox" className="checkboxStyle"></input>
                                <p className="ml-2">Full Text</p>
                              </div>
                            )}
                          </div> */}
                        </div>

                        {/* <div className="form-field">
                          <div>
                            <b>Doctype</b>
                          </div>
                          <DropDownList
                            data={docFilteredData}
                            filterable={true}
                            textField="objectTypeName"
                            dataItemKey="objectTypeUuid"
                            value={doctypeSelection}
                            defaultItem={{ objectTypeName: 'Any' }}
                            onFilterChange={docFilterChange}
                            onChange={doctypeDropListChange}
                            onOpen={onDoctypeFocus}
                          />
                        </div> */}
                        <button
                          onClick={() => (setSearchParamsObjArray([]), setSearchParamsObjArrayTemp([]), setSearchFormObjArray([]), setSearchFormObjArrayTemp([]))}
                          disabled={keywordSearch?.length > 2 ? false : true}
                          className="fsa-btn ml-3"
                        >
                          <span>Search</span>
                        </button>
                      </div>

                      {userPermissions['Folder_Search'] === true && (
                        <div>
                          <div>
                            <b>{systemFolderData.length > 0 && systemFolderData[1].title} Search</b>
                          </div>
                          {/*TODO:- do not remove this code */}
                          {/* <input
                            //value={retrieveValue(systemName)}
                            placeholder=""
                            type="text"
                            style={{ minWidth: '500px', marginRight: '1rem' }}
                            value={folderkeywordSearch === undefined || folderkeywordSearch === '' ? '' : folderkeywordSearch}
                            //name={friendlyName}
                            onChange={handleFolderKeywordChange}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter' && folderkeywordSearch?.length > 2) {
                                advSearchStateChange();
                              }
                            }}
                            //onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                          /> */}
                          <div className="d-flex w-100">
                            <AsyncSelect
                              isClearable
                              defaultOptions
                              placeholder="Select Group"
                              value={folderkeywordSearch ? { label: folderkeywordSearch, value: folderkeywordSearch } : ''}
                              loadOptions={(inputValue, callback) => {
                                if (inputValue === '') {
                                  let options = [];
                                  groupList.map((res) => {
                                    options.push({ label: res.display_name, value: res.id });
                                    setTimeout(() => {
                                      callback(options);
                                    }, 1000);
                                  });
                                  callback(options);
                                } else {
                                  let options = [];
                                  groupFilterData(inputValue).map((res) => {
                                    options.push({ label: res.display_name, value: res.id });
                                    setTimeout(() => {
                                      callback(options);
                                    }, 1000);
                                  });
                                  callback(options);
                                }
                              }}
                              onChange={(e) => {
                                onChangeDocType(e);
                              }}
                            />
                            <button
                              onClick={() => (setSearchParamsObjArray([]), setSearchParamsObjArrayTemp([]), setSearchFormObjArray([]), setSearchFormObjArrayTemp([]))}
                              disabled={folderkeywordSearch?.length > 2 ? false : true}
                              className="fsa-btn mt-0 ml-3"
                            >
                              <span>Search</span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Metadata search section */}
                    <div style={{ marginTop: '10px' }} className="flexrow underKeywordContainer ">
                      {userPermissions['Metadata_Search'] === true && (
                        <div className="modal-link-con" ref={elementRef}>
                          <p className="modal-link" style={{ cursor: 'pointer' }} onClick={handleMetaModal}>
                            Metadata Search
                            <span>
                              {popupOpen ? (
                                <FontAwesomeIcon onClick={closePopup} className="float-right chevron" icon={faChevronUp} />
                              ) : (
                                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                              )}
                            </span>
                          </p>
                        </div>
                      )}
                      <div className="collapsed-params">
                        <p>Search Parameters: {searchParamsObjArray.length === 0 && <b style={{ fontSize: '16px' }}>None</b>} </p>
                        {searchParamsObjArray.map((userParams) =>
                          userParams.friendlyName ? (
                            <div className="param-pair">
                              <p className="collapsedKey">
                                <b>{userParams.friendlyName}</b>:
                              </p>{' '}
                              <span>{typeof userParams.value === 'boolean' ? (userParams.value ? 'Yes' : 'No') : userParams.value}</span>
                            </div>
                          ) : (
                            <span>{userParams.value}</span>
                          )
                        )}
                      </div>
                      {userPermissions['Full_Text_Search'] === true && (
                        <div className="fullTextContainer">
                          <input type="checkbox" checked={fullTextFlag} className="checkboxStyle" onChange={fullTextHandler}></input>
                          <p className="ml-2">Full Text</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flexrow">
                    {/* {popupOpen &&
                    <Window
                      minimizeButton={() => null}
                      maximizeButton={() => null}
                      restoreButton={() => null}
                      onClose={closePopup}
                      style={{minHeight: "400px", paddingRight: "10px", zIndex: 10}}
                      className="k-window-focused k-state-focused"
                      draggable={false}
                      initialHeight={"40%"}
                      initialWidth={1200}
                      initialLeft={modalLocation.left}
                      initialTop={modalLocation.top + 40}
                    >
                      <div className="flexrow meta-form">
                        <h2 style={{color:'#1C83B4'}}>Metadata Search</h2>
                        <div className="meta-form-spacer">
                          <div className="meta-form-inner">
                            <div className="flexrow index">
                              <div className={metadataSelection !== null ? 'form-field' : 'form-field defaultItem'}>
                                <div><b>Metadata:</b></div>
                                <DropDownList
                                  data={metaFilteredData}
                                  id="metadataSelectionDropDown"
                                  value={metadataSelection}
                                  filterable={true}
                                  textField="display_name"
                                  dataItemKey="display_name"
                                  onFilterChange={metaFilterChange}
                                  defaultItem={defaultItem}
                                  //loading={this.state.loading}
                                  onChange={(e) => metaDropListChange(e)}
                                  onClose={(e) => e.syntheticEvent.code === "Enter" ? metaDropListChange(e) : null}
                                //onBlur={addParam}
                                />
                              </div>
                              <hr/>
                            </div>
                            <div className="flexrow metarow">
                              {searchFormObjArray.map(userParams => (
                                userParams.friendlyName !== "Producer Identified") ?
                                renderSpecsDiv(userParams) : null
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <WindowActionsBar layout={layout}>
                        <div className="meta-modal-btn-con">
                          <button onClick={advSearchStateChange} className="fsa-btn">
                            <span>Search</span>
                          </button>
                          <button
                            className="fsa-btn clear-btn"
                            disabled={docTypeEmpty && metadataEmpty && columnSearchEmpty && keywordEmpty && !countySelection && !stateSelection && searchParamsObjArray.length === 0}
                            onClick={clearAll}
                          >
                            Clear All Metadata
                          </button>
                        </div>
                      </WindowActionsBar>
                    </Window>} */}
                  </div>
                </form>
              </div>
            </div>

            {/* metadata modal */}
            <MetaDataModal
              metadataModalOpen={popupOpen}
              closeMetadatModal={closeMetadatModal}
              metadataSelection={metadataSelection}
              setMetadataSelection={setMetadataSelection}
              handleChange={handleChange}
              // inputTitle={inputTitle}
              onInputTitleChange={handleInputTitleChange}
              fieldsData={metaFilteredData}
              metaFilterChange={metaFilterChange}
              defaultItem={defaultItem}
              onChangeAsyncData={onChangeAsyncData}
              onCloseList={(e) => (e.syntheticEvent.code === 'Enter' ? metaDropListChange(e) : null)}
              advSearchStateChange={onSearchDetails}
              clearData={clearAll}
              searchFormObjArray={searchFormObjArray}
              renderSpecsDiv={renderSpecsDiv}
              fullTextFlag={fullTextFlag}
              closeHandler={closeHandler}
              prevStateHandler={prevStateHandler}
              metaClearOnSearch={metaClearOnSearch}
            />

            <DRMSGrid
              isMetadataLoading={isMetadataLoading}
              onRowClick={onRowClick}
              errorMessage={errorMessage}
              drmsSearch={drmsSearch}
              searchMetadata={searchMetadata}
              isLoading={isLoading}
              loading={loading}
              objectID={objectID}
              skip={skip}
              take={take}
              sort={sort}
              setSort={setSort}
              setSkip={setSkip}
              setTake={setTake}
              records={records}
              setRecords={setRecords}
              columnsToShow={columnsToShow}
              doctypeColumns={metafieldResponse}
              cellClicked={cellClicked}
              setFilter={setFilter}
              SELECTED_FIELD={SELECTED_FIELD}
              onSelectionChange={onSelectionChange}
              objectMetadata={objectMetadata}
              setObjectMetadata={setObjectMetadata}
              doc={doc}
              detailOpen={detailOpen}
              setDetailOpen={setDetailOpen}
              scimsCustomers={scimsCustomers}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              numPages={numPages}
              docName={docName}
              docType={docType}
              metadataDisplayList={metadataDisplayList}
              setMetadataDisplayList={setMetadataDisplayList}
              filter={filter}
              searchParamsObjArray={searchParamsObjArray}
              rowsChecked={rowsChecked}
              objFileCodePopupOpen={objFileCodePopupOpen}
              setObjFileCodePopupOpen={setObjFileCodePopupOpen}
              userInfo={userInfo}
              userId={userId}
              agencyCode={agencyCode}
              docDetailResponse={docDetailResponse}
              documentTitle={documentTitle}
              sections={sections}
              detailsObjList={detailsObjList}
              selectObjectTypeToView={(e) => updateObjSelected(e)}
              objectTypeToView={objectTypeToView}
              selectedRecord={selectedRecord}
              onDeleteObject={onDeleteObject}
              userPermissions={userPermissions}
              setBulkPopupOpen={(e) => {
                setBulkPopupOpen(e);
              }}
              holdResponse={holdResponse}
              refreshData={refreshDataObject}
              clearAll={clearAll}
              fetchDocDetails={(e, v) => {
                fetchDocDetails(e, v);
              }}
              deleteRecordEntry={deleteRecordEntry}
              dataUpdated={dataUpdated}
              setDataUpdated={setDataUpdated}
              fullTextFlag={fullTextFlag}
              keywordSearch={keywordSearch}
              setDropdownFilterChanged={setDropdownFilterChanged}
              dropdownFilterCell={dropdownFilterCell}
              setDropdownFilterCell={setDropdownFilterCell}
              fetchDropDownFilterCell={(cell) => {
                fetchDropDownFilterCell(cell);
              }}
              dropdownFilterChanged={dropdownFilterChanged}
              clickedSearchRow={clickedSearchRow}
            />
            <div className="alertBar-right">
              <WarningGrowl isWarningGrowlHidden={isWarningGrowlHidden} setIsWarningGrowlHidden={setIsWarningGrowlHidden}></WarningGrowl>
            </div>
            {deleteModalShow && (
              <DeleteModal
                show={deleteModalShow}
                DeleteDataFunc={DeleteDataFunc}
                popupTitle={'Record'}
                onHide={() => setDeleteModalShow(false)}
                setDeleteModalShow={setDeleteModalShow}
                selectedDeleteRowData={deleteData}
                setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
                setMessage={setMessage}
              ></DeleteModal>
            )}
            <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
            <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
          </div>
        </div>
      </main>
    )
  );
}
