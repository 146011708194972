import React, { useEffect, useState } from 'react';
import styles from './Profile.module.scss';
import '../../GlobalStyle.scss';
import ProfileTable from './ProfileTable';
import UsersTablesData from './Profile.json';
import SuccessProcessGrowl from '../../components/SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../../components/SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../newcomponents/DeletePopup/DeleteModal';

var _ = require('lodash');

export default function UsersTable() {
  const [data, setData] = useState([JSON.parse(sessionStorage.getItem('user_information'))]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [UsersTableDetails, setUsersTableData] = useState(UsersTablesData);

  const onRemoveSort = async () => {};

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const editFunc = (data) => {};

  const DeleteFunc = (DeleteData) => {};

  const onSortData = async (selectedColumn) => {};

  const DeleteDataFunc = () => {};

  return (
    <div className={styles.usersTablePage}>
      {isLoading && <GlobalLoader />}

      {/* <Breadcrumb data={breadcrumbData} /> */}
      <div className={styles.usersTablePageContent}>
        <h1 className={styles.pageTitle}>Profile</h1>

        <div className={[styles.usersTableTable, 'glbUsersTableTable'].join(' ')}>
          <ProfileTable
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={UsersTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            popupTitle={'User'}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}
