import React, { useEffect, useState, useRef, useContext } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DatePicker from "react-datepicker";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { v4 as uuidv4 } from 'uuid';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { faChevronUp, faChevronDown, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './DetailsViewV2.scss';
import EditIcon from '../../../fsa-style/img/svgs/EditPencilBlue.svg';
import DownloadFile from '../../DownloadFile/DownloadFile';
import CustomerInfoList from '../../CustomerInfo/CustomerInfoList';
import UpdateObjectFileCodeModal from '../UpdateObjectFileCodeModal/UpdateObjectFileCodeModal';
import Collapsible from 'react-collapsible';
import { EditMetadata, getFileCodeById, updateDispositionDate } from '../../../services/searchService';
import { filterBy } from '@progress/kendo-data-query';
import FullscreenIcon from '../../../fsa-style/img/svgs/fullScreenIcon.svg';
// import OptionArrow from '../../../fsa-style/img/svgs/OptionArrow.svg';
import SelectedObjectTypeIcon from '../../../fsa-style/img/svgs/SelectedObjectTypeIcon.svg';
import DeleteIcon from '../../../fsa-style/img/trashIcon.svg';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { deleteObject } from '../../../services/searchService';
import { handleCustomErrorMsg } from '../../../utils/helper/handleReuiredErrorFunc';
//import { GlobalLoader } from "@material-ui/core";

//import { ListView } from "@progress/kendo-react-listview";
//import { Popup } from '@progress/kendo-react-popup'

// import ImageView from "../ImageView/ImageView";
// import DownloadFile from "../DownloadFile/DownloadFile";
import _ from 'lodash';
import SearchDetail from '../../SearchDetail/SearchDetail';
import ImageView from '../../ImageView/ImageView';
import PDFView from '../../PDFView/PDFView';
import CustomerInfo from '../../CustomerInfo/CustomerInfo';
import { ListView } from '@progress/kendo-react-listview';
import { useWindowSize } from '../../../services/helper';
import { getDoctype } from '../../../services/suspenseQueueService';
import SuccessProcessGrowl from '../../SuspenseQueue/SuccessProcessGrowl';
import moment from 'moment';
import FormDatepicker from '../../FormModules/FormDatepicker';
import AliceSenseTab from '../../../pages/Search/AliceSenseTab/AliceSenseTab';
import UserContext from '../../UserContext/UserContext';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import { CircularProgress } from '@material-ui/core';
import UpdateObjectHoldModal from '../UpdateObjectHoldModal/UpdateObjectHoldModal';
import ErrorGrowl from '../../SuspenseQueue/ErrorGrowl';
import { EventEmitter } from '../../../services/events';
import { groupData, sectionData, employmentStatusData, statusReasonData } from '../../../utils/helper/helperData';
import AsyncSelect from 'react-select/async';

export default function DetailsView({
  isMetadataLoading,
  cellClicked,
  objectID,
  popupOpen,
  closePopup,
  doc,
  objectMetadata,
  setObjectMetadata,
  onDocumentLoadSuccess,
  numPages,
  loading,
  docName,
  docType,
  scimsCustomers,
  metadataDisplayList,
  setMetadataDisplayList,
  setRecords,
  records,
  userId,
  agencyCode,
  docDetailResponse,
  documentTitle,
  sections,
  detailsObjList,
  selectObjectTypeToView,
  objectTypeToView,
  selectedRecord,
  errorMessage,
  holdResponse,
  refreshData,
  fetchDocDetails,
  deleteRecordEntry,
  dataUpdated,
  setDataUpdated,
  fullTextFlag,
  keywordSearch,
  setDropdownFilterChanged,
  fetchDropDownFilterCell,
  setDropdownFilterCell,
  dropdownFilterCell,
  dropdownFilterChanged,
}) {
  const userInformation = JSON.parse(sessionStorage.getItem('user_information'))?.rolesName;
  const rolesToCheck = ['Admin', 'Record Manager', 'Application Administrator', 'Full text search user', ' Delete object user'];
  const isDeleteAccessible = userInformation?.some((itm) => rolesToCheck.some((role) => itm?.toLowerCase().includes(role?.toLowerCase())));

  const [zoom, setZoom] = useState(1);
  const [selected, setSelected] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [sectionOpen, setSectionOpen] = useState(false);
  const [producersMinimized, setProducersMinimized] = useState(false);
  const [entityMinimized, setEntityMinimized] = useState(false);
  const [guardianMinimized, setGuardianMinimized] = useState(false);
  const [memberInfoMinimized, setMemberInfoMinimized] = useState(false);
  const [participantMinimized, setParticipantMinimized] = useState(false);
  const [loadingFileCodes, setLoadingFileCodes] = useState(true);
  const [objFileCodePopupOpen, setObjFileCodePopupOpen] = useState(false);
  const [width, height] = useWindowSize();
  const [EditRefresh, setEditRefresh] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const [editedFields, setEditedFields] = useState();
  const [editDocumentTitle, setEditDocumentTitle] = useState();
  const [doctype, setDoctype] = useState([]);
  const [doctypeFilteredData, setDoctypeFilteredData] = useState(doctype.slice());
  const [sectionSelection, setSectionSelection] = useState(null);
  const [employeeStatusSelection, setEmployeeStatusSelection] = useState(null);
  const [statusReasonSelection, setStatusReasonSelection] = useState(null);
  const [groupSelection, setGroupSelection] = useState(null);
  const [documentTitleSelection, setDocumentTitleSelection] = useState(null);
  const [expand, setExpand] = useState('DEFAULT');
  const [modalTitle, setModalTitle] = useState(selectedRecord);
  const [isHoldModalVisible, setIsHoldModalVisible] = useState(false);
  const [isMetadataModified, setIsMetadataModified] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isRequiredError, setisRequiredError] = useState(false);
  const [divWidth, setdivWidth] = useState();

  const observedDiv = useRef(null);
  const resizingDelayTimer = useRef(null);

  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };
  const { userPermissions } = useContext(UserContext);

  //api
  const doctypeFilterChange = (event) => {
    setDoctypeFilteredData(doctypeFilterData(event.filter));
  };

  const [sectionList, setSectionList] = useState(_.cloneDeep(sectionData));
  const [groupList, setGroupList] = useState(_.cloneDeep(groupData));
  const [employmentStatusList, setEmploymentStatusList] = useState(_.cloneDeep(employmentStatusData));
  const [statusReasonList, setStatusReasonList] = useState(_.cloneDeep(statusReasonData));

  const [sectionFilteredData, setSectionFilteredData] = useState(sectionList.slice());
  const [groupFilteredData, setGroupFilteredData] = useState(groupList.slice());
  const sectionFilterChange = (event) => {
    setSectionFilteredData(sectionFilterData(event.filter));
  };

  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden1, setIsSuccessGrowlHidden1] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [holdInfo, setHoldInfo] = useState(null);
  const [fileCodeName, setFileCodeName] = useState(null);
  const [folderValue, setFolderValue] = useState('');
  const [folderEditable, setFolderEditable] = useState(false);
  const [sortedDropdownVal, setSortedDropdownVal] = useState({ label: 'Added On', value: 'AddedOn' });

  const dropdownData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));

  const sectionFilterData = (filter) => {
    const data = sectionList.slice();
    return filterBy(data, filter);
  };

  const doctypeFilterData = (filter) => {
    const data = doctype.slice();
    return filterBy(data, filter);
  };

  const defaultItemfordoctype = {
    display_name: documentTitle,
  };
  const defaultItemforSections = {
    display_name: sections,
  };
  const [loaderUpdateMetaData, setLoaderUpdateMetaData] = useState(false);
  const DocTitleDropListChange = (e) => {
    setDocumentTitleSelection(e.target.value);
    let keyTitle = 'title';
    let keyObjectType = 'object_type_name';
    let value = e.target.value.display_name;
    setEditDocumentTitle((values) => ({
      ...values,
      [keyTitle]: value,
      [keyObjectType]: value,
    }));
  };
  const sectionDropListChange = (e) => {
    setSectionSelection(e.target.value);
    let key = 'sections';
    let value = e.target.value.display_name;
    setEditedFields((values) => ({ ...values, [key]: value }));
  };

  const groupDropdownChange = (e) => {
    setGroupSelection(e.target.value);
    let key = 'Group';
    let value = e.target.value.display_name;
    setEditedFields((values) => ({ ...values, [key]: value }));
  };

  const holdArrFilter = async () => {
    let filteredHold =
      holdResponse?.length !== 0 &&
      objectMetadata?.holds &&
      holdResponse?.find((holdItm) => holdItm?.holdNumber === objectMetadata?.holds[objectMetadata?.holds.length - 1]?.hold_number);
    setHoldInfo(filteredHold);
    let fileCodeid = objectMetadata?.record_management && objectMetadata?.record_management?.file_code;
    if (fileCodeid) {
      const response = await getFileCodeById(fileCodeid, {});
      if (response) setFileCodeName(response.recordtitle);
    } else setFileCodeName('');
  };
  useEffect(() => {
    holdArrFilter();
    setStartDate([]);
    setPageNumber(1);
    if (objectMetadata && objectMetadata.record_management) {
      if (objectMetadata?.record_management?.projected_cut_off_date) setStartDate(moment(objectMetadata.record_management.projected_cut_off_date).format('YYYY-MM-DD'));
    }
  }, [objectMetadata, objFileCodePopupOpen]);

  useEffect(() => {
    const getdoc = async () => {
      try {
        const response = await getDoctype();
        if (Array.isArray(response.data)) {
          const mappedData = response.data.map((item, index) => ({
            id: index + 1,
            display_name: item.objectTypeName,
          }));
          setDoctype(mappedData);
          setDoctypeFilteredData(mappedData);
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      } catch (error) {
        console.error('Error in getDoctype:', error.message);
      }
    };

    getdoc();
    setModalTitle(selectedRecord);
    setDocumentTitleSelection(_.find(doctype, { display_name: docDetailResponse?.metadata.object_type?.title }));
    setEditDocumentTitle((values) => ({
      ...values,
      object_type_name: docDetailResponse?.metadata.object_type?.object_type_name,
      title: docDetailResponse?.metadata.object_type?.title,
    }));
    setSectionSelection(_.find(sectionList, { display_name: docDetailResponse?.metadata.object_specific?.Section }));
    setGroupSelection(_.find(groupList, { display_name: docDetailResponse?.metadata.object_specific?.Group }));
    setEmployeeStatusSelection(_.find(employmentStatusList, { display_name: docDetailResponse?.metadata.object_specific?.employmentstatus }));
    setStatusReasonSelection(_.find(statusReasonList, { display_name: docDetailResponse?.metadata.object_specific?.statusreason }));
    setFolderValue(docDetailResponse?.metadata.object_specific?.Folder);
    setEditedFields((values) => ({
      ...values,
      sections: docDetailResponse?.metadata.object_specific?.Section,
      title: docDetailResponse?.metadata.object_type?.title,
      Group: docDetailResponse?.metadata?.Group,
      Folder: docDetailResponse?.metadata?.Folder,
    }));

    // sectionList.fin
  }, [docDetailResponse, modalTitle]);

  useEffect(() => {
    if (!observedDiv.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        if (observedDiv.current && observedDiv.current.offsetWidth != null && observedDiv.current.offsetWidth !== divWidth) {
          setdivWidth(observedDiv.current.offsetWidth / 16);
        }
      }, 100);
    });

    resizeObserver.observe(observedDiv.current);
    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [observedDiv.current]);

  // Disposition Information Functionality Start
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const UpdateDisposition = async () => {
    // console.log(startDate, 'updatedispositiondate');
    setLoaderUpdateMetaData(true);
    let payload = [];
    payload.push(userId);
    try {
      const headers = {
        agencycode: agencyCode,
        applicationid: 0,
      };
      let newDate = startDate;
      if (startDate !== '') newDate = startDate + 'T00:00:00';
      else newDate = null;
      const response = await updateDispositionDate(newDate, payload, headers);

      if (response) {
        // console.log('Response:', response);
        setLoaderUpdateMetaData(false);
        selectObjectTypeToView(objectTypeToView);
        setMessage('Disposition data populated successfully');
        setIsSuccessGrowlHidden1(false);
        setTimeout(() => {
          setIsSuccessGrowlHidden1(true);
        }, 3000);
      }
    } catch (error) {
      // Handle error
      setLoaderUpdateMetaData(false);
      setMessage(error.response?.data?.message ? error.response.data.message : 'Something went wrong');
      console.error('Error in onSaveButtonClick:', error);
      setIsErrorGrowlHidden(false);
      setTimeout(() => {
        setIsErrorGrowlHidden(true);
      }, 3000);
    }
  };
  // Disposition Information Functionality End

  // To add isEditSelected Start
  useEffect(() => {
    setTimeout(() => {
      metadataDisplayList &&
        Object.keys(metadataDisplayList).forEach((key) => {
          return (metadataDisplayList[key].isEditSelected = false);
        });
      objectMetadata &&
        Object.keys(objectMetadata).forEach((key) => {
          if (key === 'object_type') {
            return (objectMetadata[key].isEditSelected = false);
          }
        });
    }, 2000);
  }, [metadataDisplayList, expand]);
  // To add isEditSelected End

  // const [fcHistory, setFcHistoryr] = useState(objectMetadata.record_management.file_code_change_history);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const toggleUpdateObjFileCodePopup = (popupState) => {
    setLoadingFileCodes(true);
    setObjFileCodePopupOpen(popupState);
    if (popupState === false) selectObjectTypeToView(objectTypeToView);
  };
  const closeHoldModal = () => {
    setIsHoldModalVisible(false);
    selectObjectTypeToView(objectTypeToView);
  };

  const openHoldModal = () => {
    setIsHoldModalVisible(true);
  };

  const handleZoom = (zoomDirection) => {
    zoomDirection === 'in' ? setZoom((previousZoom) => previousZoom + 0.5) : setZoom((previousZoom) => previousZoom - 0.5);
  };

  const changePage = (direction) => {
    direction === 'left' ? setPageNumber((prevPageNumber) => prevPageNumber - 1) : setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const closePopupActions = () => {
    closePopup();
    setExpand('DEFAULT');
    setSelected(0);
    setZoom(1.0);
    setSortedDropdownVal({ label: 'Added On', value: 'Added On' });
    if (isMetadataModified === true) {
      refreshData();
      setIsMetadataModified(false);
    }

    setDropdownFilterCell(null);
  };

  const closeSection = () => {
    if (!sectionOpen) {
      setSectionOpen(true);
    } else {
      setSectionOpen(false);
    }
  };

  // Edit Text Code Section Start
  const EditDisplayList = (ele, idx, checkEdit) => {
    if (checkEdit === 'metadataDisplayList') {
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].isEditSelected = false); // if metadataDisplayList is selected then objectMetadata is input false
        }
      });
      Object.keys(ele).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (idx === index) {
          return (ele[key].isEditSelected = !ele[key].isEditSelected);
        } else {
          return (ele[key].isEditSelected = false);
        }
      });
    }
    if (checkEdit === 'objectMetadata') {
      Object.keys(ele).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        return (ele[key].isEditSelected = false); // if objectMetadata is selected then metadataDisplayList is input false
      });
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].isEditSelected = !objectMetadata[key].isEditSelected);
        }
      });
    }
  };
  //for sending only edited changes in payload
  const onChangeInput = (e, idx, checkEdit, currentField, metadataDisplayList) => {
    if (checkEdit === 'metadataDisplayList') {
      let value = e.target.value;
      currentField === 'Term' && (value = value === 'true' ? true : false);
      if (currentField === 'Name Last' || currentField === 'Name First' || currentField === 'Employee Number') {
        value = e.target.value.trim() === '' ? '' : e.target.value;
        setisRequiredError(true);
      }
      Object.keys(metadataDisplayList).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (idx === index && currentField) {
          // saving changed value for dropdown fields
          if (currentField === 'Employment Status' || currentField === 'Status Reason') {
            const dropdownValue = e.target.value.display_name;
            currentField === 'Employment Status' ? setEmployeeStatusSelection(e.target.value) : setStatusReasonSelection(e.target.value);

            setEditedFields((prevEditedFields) => ({
              ...prevEditedFields,
              [key]: (metadataDisplayList[key].value = dropdownValue),
            }));
          } else {
            setEditedFields((prevEditedFields) => ({
              ...prevEditedFields,
              [key]: (metadataDisplayList[key].value = value),
            }));
          }
        }
      });
    }
    if (checkEdit === 'objectMetadata') {
      let value = e.target.value;
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].title = value);
        }
      });
    }
  };

  const onSaveDisplayList = (e, idx, checkEdit, metadataDisplayList) => {
    if (checkEdit === 'metadataDisplayList') {
      if (e.key === 'Enter') {
        let value = e.target.value;
        Object.keys(metadataDisplayList).forEach((key, index) => {
          setEditRefresh(!EditRefresh);
          if (idx === index) {
            return (metadataDisplayList[key].isEditSelected = false), (metadataDisplayList[key].value = value);
          }
        });
      }
    }
    if (metadataDisplayList['first_name'].value === '' || metadataDisplayList['last_name'].value === '' || metadataDisplayList['employee_number'].value === '') {
      setisRequiredError(true);
    }

    if (checkEdit === 'objectMetadata') {
      if (e.key === 'Enter') {
        let value = e.target.value;
        Object.keys(objectMetadata).forEach((key, index) => {
          setEditRefresh(!EditRefresh);
          if (key === 'object_type') {
            return (objectMetadata[key].isEditSelected = false), (objectMetadata[key].title = value);
          }
        });
      }
    }
  };
  // Edit Text Code Section End

  Object.flatten = function (data) {
    var result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, '');
    return result;
  };

  //TODO Move this out to component
  const LogItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div className="p-2 border-bottom align-middle vote-detail-row center">
        <div className="col-12 vote-detail-col">
          <h2 className="text-uppercase vote-detail">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  display: 'inline-block',
                  // width: '36%',
                  textAlign: 'left',
                }}
              >
                {item.change_log.split('_').join(' ')}
              </span>
              <span
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  // width: '30%',
                  textAlign: 'right',
                }}
              >
                {item.modification_date.split('T').join(' ')}
              </span>
            </div>
          </h2>
        </div>
      </div>
    );
  };

  {
    /* {objectMetadata.record_management.file_code_change_history} */
  }
  {
    /* application_date: "2021-10-19T15:44:06"
    old_file_code: "01010"
    old_file_code_version_number: 11
    user_id: "28200403099030045911"
    user_name: "Everett Koechner" */
  }

  const FcLogItemRender = (props, data) => {
    let item = props.dataItem;
    return (
      <>
        {item?.old_file_code && (
          <div className="p-2 border-bottom align-middle vote-detail-row center">
            <div className="col-12 vote-detail-col">
              <h2 className="text-uppercase vote-detail">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-inline-block text-left w-50">File Code Update: {item?.old_file_code}</span>

                  <span className="d-inline-block align-top text-right w-30">{item.application_date.split('T').join(' ')}</span>
                  {/* <span
                style={{
                  verticalAlign: 'top',
                  display: 'inline-block',
                  width: '33%',
                  textAlign: 'right',
                }}
              >
                {item.user_name}
              </span> */}
                </div>
              </h2>
            </div>
          </div>
        )}
      </>
    );
  };

  const isCustomerInfo = (key) => {
    return (
      key.startsWith('producer[') ||
      key.startsWith('producer.') ||
      key.startsWith('entity.') ||
      key.startsWith('entity[') ||
      key.startsWith('participant.') ||
      key.startsWith('participant[') ||
      key.startsWith('member_info.') ||
      key.startsWith('member_info[') ||
      key.startsWith('guardian.') ||
      key.startsWith('guardian[') ||
      key.startsWith('title') ||
      key.startsWith('object_type_name') ||
      key.startsWith('edition_date')
    );
  };

  //start for edit metadata
  const onSaveButtonClick = async () => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    if (folderValue === '') return;
    if (groupSelection === '' || groupSelection === null || groupSelection === undefined) return;

    setisRequiredError(false);
    setLoaderUpdateMetaData(false);

    Object.keys(metadataDisplayList).forEach((key) => {
      return (metadataDisplayList[key].isEditSelected = false);
    });

    try {
      const headers = {
        agencycode: agencyCode,
        applicationid: 0,
      };

      setObjectMetadata((prevEditedFields) => ({
        // ...prevEditedFields,
        ...(prevEditedFields['object_specific'] = {
          ...prevEditedFields.object_specific,
          ...editedFields,
        }),
        ...(prevEditedFields['object_type'] = {
          ...prevEditedFields.object_type,
          ...editDocumentTitle,
        }),
      }));
      if (objectMetadata?.object_specific?.first_name === '' || objectMetadata?.object_specific?.last_name === '' || objectMetadata?.object_specific?.employee_number === '') {
        setisRequiredError(true);

        return;
      }
      setLoaderUpdateMetaData(true);
      objectMetadata['object_specific']['Section'] = objectMetadata['object_specific']['sections'];
      objectMetadata['object_specific']['ObjectType'] = objectMetadata['object_type']['title'];
      objectMetadata['object_specific']['Folder'] = folderValue;

      delete objectMetadata[systemFolders[0].name];
      // delete objectMetadata.Folder;
      delete objectMetadata.ObjectType;
      delete objectMetadata.Section;
      delete objectMetadata.contextID;
      delete objectMetadata.document_ocr_id;
      delete objectMetadata.employmentstatus;
      delete objectMetadata.statusreason;
      delete objectMetadata.fsa_access_level;
      delete objectMetadata.isEditSelected;
      delete objectMetadata.object_ocr;
      delete objectMetadata.object_type_name;
      delete objectMetadata.smc_department;
      // delete objectMetadata.isEditSelected;
      // delete objectMetadata.isEditSelected;

      delete objectMetadata.contract_number;
      delete objectMetadata.employee_number;
      delete objectMetadata.first_name;
      delete objectMetadata.last_name;
      delete objectMetadata.middle_name;
      delete objectMetadata.sections;
      delete objectMetadata.termination_date;
      delete objectMetadata.statusstartdate;
      delete objectMetadata.term;
      delete objectMetadata.title;
      if (objectMetadata?.object_specific?.statusstartdate) {
        objectMetadata.object_specific.statusstartdate = moment(objectMetadata.object_specific.statusstartdate).format('YYYY-MM-DDTHH:mm:ss');
      }
      // delete objectMetadata.object_specific.term_date;
      // delete objectMetadata.object_specific.emp_status;
      const response = await EditMetadata(userId, objectMetadata, headers);

      if (response) {
        setIsSuccessGrowlHidden(false);
        fetchDocDetails(response?._id, userId);
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);

        setObjectMetadata((prevState) => ({
          ...prevState,
          ...response,
        }));
        setIsMetadataModified(true);
        // EventEmitter.emit('refreshSearchDetailsData');
        // EventEmitter.off('refreshSearchDetailsData');

        setDataUpdated(false);
      } else {
        setTimeout(() => {
          setLoaderUpdateMetaData(false);
        }, [4000]);

        console.log('error in EditMetadata()');
      }
    } catch (error) {
      // Handle error
      setLoaderUpdateMetaData(false);

      console.error('Error in onSaveButtonClick:', error);
    }
  };
  useEffect(() => {
    //  whenever dataUpdated changes
    if (dataUpdated === true) {
      setTimeout(() => {
        setLoaderUpdateMetaData(false);
        setSortedDropdownVal({ label: 'Added On', value: 'Added On' });
        setDropdownFilterCell((prev) => ({ ...prev, sortKey: 'Added On' }));
      }, [1000]);
    }
  }, [dataUpdated]);
  //End for edit Metadata

  const metadataView = (metadataDisplayList) => {
    metadataDisplayList = Object.fromEntries(
      Object.entries(metadataDisplayList).sort((x, y) => {
        if (x[1].display_name < y[1].display_name) return -1;
        if (x[1].display_name > y[1].display_name) return 1;
        return 0;
      })
    );
    if (metadataDisplayList)
      return Object.keys(metadataDisplayList).map((key, idx) => {
        if (key === 'sections') return null;

        if (key === 'object_ocr') return null;

        if (key === 'full_text_search') return null;

        if (key === 'document_ocr_id') return null;

        if (!isCustomerInfo(key)) {
          return (
            //Here...
            <>
              <div className="returnedData" key={idx + '_metadata'}>
                <div className="keyfont">{metadataDisplayList[key]?.display_name}</div>
                <div
                  className="valuefont"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {/* if editing is true then the below code will execute */}
                  {metadataDisplayList[key].isEditSelected === true ? (
                    <>
                      {/* when metadataDisplayList has a display_name as Status Start Date this date picker will render */}
                      {metadataDisplayList[key].display_name === 'Status Start Date' && (
                        <>
                          <FormDatepicker
                            value={moment(metadataDisplayList[key].value).isValid() ? metadataDisplayList[key].value : null}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                          />
                        </>
                      )}
                      {/* when metadataDisplayList has a display_name as Term then below dropdown for radio button will render */}
                      {metadataDisplayList[key].display_name === 'Term' && (
                        <>
                          <select
                            value={
                              String(metadataDisplayList[key]?.value)?.toLowerCase() === 'true' || String(metadataDisplayList[key]?.value)?.toLowerCase() === 'yes' ? true : false
                            }
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                          >
                            <option value={true}>Yes</option>

                            <option value={false}>No</option>
                          </select>
                        </>
                      )}
                      {/* when metadataDisplayList has a display_name as Employment status this dropdown will render */}
                      {metadataDisplayList[key].display_name === 'Employment Status' && (
                        <>
                          <div className="fsa-field dropdownCustomStyle">
                            <DropDownList
                              className="dropDown"
                              data={employmentStatusList}
                              textField="display_name"
                              dataItemKey="id"
                              value={employeeStatusSelection}
                              onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                              style={{ minWidth: '100%' }}
                              title={metadataDisplayList[key].display_name}
                              itemRender={itemRender}
                            />
                          </div>
                        </>
                      )}
                      {/* when metadataDisplayList has a display_name as Status Reason this dropdown will render */}
                      {metadataDisplayList[key].display_name === 'Status Reason' && (
                        <>
                          <div className="fsa-field dropdownCustomStyle">
                            <DropDownList
                              className="dropDown"
                              data={statusReasonList}
                              textField="display_name"
                              dataItemKey="id"
                              value={statusReasonSelection}
                              onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                              style={{ minWidth: '100%' }}
                              title={metadataDisplayList[key].display_name}
                              itemRender={itemRender}
                            />
                          </div>
                        </>
                      )}

                      {metadataDisplayList[key].display_name !== 'Status Start Date' &&
                        metadataDisplayList[key].display_name !== 'Term' &&
                        metadataDisplayList[key].display_name !== 'Employment Status' &&
                        metadataDisplayList[key].display_name !== 'Status Reason' && (
                          <>
                            <input
                              value={metadataDisplayList[key].value !== '.' && metadataDisplayList[key].value}
                              onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                              onKeyPress={(e) => onSaveDisplayList(e, idx, 'metadataDisplayList', metadataDisplayList)}
                              style={{ width: 'calc(100% - 15%)' }}
                            />
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {metadataDisplayList[key].display_name === 'Status Start Date' && metadataDisplayList[key].value && (
                        <>{moment(metadataDisplayList[key].value).format('MM/DD/YYYY')}</>
                      )}

                      {metadataDisplayList[key].display_name === 'Term' &&
                        (metadataDisplayList[key].value === 'Yes' ||
                        metadataDisplayList[key].value === 'yes' ||
                        metadataDisplayList[key].value === 'No' ||
                        metadataDisplayList[key].value === 'no' ||
                        metadataDisplayList[key].value === 'true' ||
                        metadataDisplayList[key].value === 'True' ||
                        metadataDisplayList[key].value === 'False' ||
                        metadataDisplayList[key].value === true ||
                        metadataDisplayList[key].value === false ||
                        metadataDisplayList[key].value === 'false' ? (
                          <>
                            {metadataDisplayList[key].value === 'Yes' && 'Yes'}
                            {metadataDisplayList[key].value === 'No' && 'No'}
                            {metadataDisplayList[key].value === 'yes' && 'Yes'}
                            {metadataDisplayList[key].value === 'true' && 'Yes'}
                            {metadataDisplayList[key].value === 'True' && 'Yes'}
                            {metadataDisplayList[key].value === 'false' && 'No'}
                            {metadataDisplayList[key].value === true && 'Yes'}
                            {metadataDisplayList[key].value === false && 'No'}
                            {metadataDisplayList[key].value === 'False' && 'No'}
                          </>
                        ) : (
                          <>{metadataDisplayList[key].value && metadataDisplayList[key].value}</>
                        ))}
                      {metadataDisplayList[key].display_name !== 'Status Start Date' && metadataDisplayList[key].display_name !== 'Term' && metadataDisplayList[key].value}
                    </>
                  )}
                  <img
                    style={{
                      marginLeft: '2px',
                      padding: '2px 0px 4px 10px',
                      height: '2.5rem',
                      cursor: 'pointer',
                    }}
                    alt="pencilIcon"
                    src={EditIcon}
                    onClick={() => EditDisplayList(metadataDisplayList, idx, 'metadataDisplayList')}
                  />
                </div>
              </div>
              {/* required fields validation */}
              <div className="d-flex justify-content-end">
                {metadataDisplayList[key].display_name === 'Name First' &&
                  metadataDisplayList['first_name']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
                {metadataDisplayList[key].display_name === 'Name Last' &&
                  metadataDisplayList['last_name']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
                {metadataDisplayList[key].display_name === 'Employee Number' &&
                  metadataDisplayList['employee_number']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
              </div>
            </>
          );
        }
      });
  };
  const returnTitle = () => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));

    let title = '';
    let arrow = '';

    if (systemFolders.length > 0) {
      systemFolders.map((itm, i) => {
        if (JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.key?.['object_specific.' + itm.name.replace(/\s/g, '')] !== undefined) {
          if (title.length > 0) {
            arrow = ' > ';
          } else arrow = '';
          title = title + arrow + JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.key?.['object_specific.' + itm.name.replace(/\s/g, '')];
        }
      });
      if (JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.['section'] !== undefined)
        title = title + ' > ' + JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.['section'];

      if (JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.['objectType'] !== undefined)
        title = title + ' > ' + JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.['objectType'];

      if (JSON.parse(sessionStorage.getItem('FullTextQuery'))?.['value'] !== undefined) title = title + ' > ' + JSON.parse(sessionStorage.getItem('FullTextQuery'))?.['value'];
    }

    return (
      <span className="modalHeaderTitleStyle" title={title}>
        {title}
      </span>
    );
  };

  const DeleteFunc = (dataItem) => {
    setDeleteModalShow(true);
    setDeleteData(dataItem);
  };

  const DeleteDataFunc = (dataItem) => {
    setLoadingFileCodes(true);
    const headers = {
      agencycode: agencyCode,
      applicationid: 5,
    };

    const sendData = {
      id: dataItem,
    };
    deleteObject(sendData, headers).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await deleteRecordEntry(sendData.id);
        setIsMetadataModified(true);
        setLoadingFileCodes(true);
        setIsSuccessGrowlHidden1(false);
        setMessage('Record deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden1(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error ? response.error?.data?.message : 'Something went wrong');
        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoadingFileCodes(true);
      }
    });
  };

  const loadSortDropdownList = (inputValue, callback) => {
    let options = dropdownData
      .map((res, i) => {
        if (i === 0) {
          return { label: 'Added On', value: 'Added On' };
        } else if (res.data && res.data.lowCard === true) {
          if (res.title === 'Group') {
            return { label: res.title, value: res.title, isDisabled: true };
          }
          return { label: res.title, value: res.title, isDisabled: false };
        }
        return null;
      })
      .filter((option) => option !== null);

    setTimeout(() => {
      callback(options);
    }, 1000);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
      whiteSpace: 'nowrap',
      width: '80rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    singleValue: (provided, state) => ({
      ...provided,

      maxWidth: 'calc(100% - 85px)',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      // padding:0,
      // margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '45%',
      maxWidth: '50%',
      margin: '0rem 1rem 0rem 1rem',
    }),
  };

  const onChangeDropdownVal = (event) => {
    const { value } = event;
    const updatedValue = value === '' ? null : value;

    setSortedDropdownVal({ label: updatedValue, value: updatedValue });

    setDropdownFilterCell((prev) => ({ ...prev, sortKey: value }));

    setDropdownFilterChanged(true);
  };
  useEffect(() => {
    if (dropdownFilterChanged === true) {
      fetchDropDownFilterCell(dropdownFilterCell);
    }
  }, [dropdownFilterChanged]);

  const itemRender = (li, itemProps) => {
    const itemChildren = <span title={itemProps.dataItem.display_name}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    popupOpen && (
      <div id="detailsViewModalElement">
        <Window
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            maxHeight: '100%',
            // zIndex: deleteModalShow ? 10001 : '',
          }}
          minimizeButton={() => null}
          maximizeButton={() => null}
          stage={expand}
          title={returnTitle()}
          closeButton={() => (
            <>
              <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
                {/* <b>X</b> */}
                <img src={FullscreenIcon} alt="FullScreen icon" />
              </button>
              <button aria-label="Close Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => closePopupActions()}>
                {/* <b>X</b> */}
                <img src={crossIcon} alt="cross icon" />
              </button>
            </>
          )}
          restoreButton={() => null}
          modal={true}
          draggable={false}
          left={width * 0.025}
          top={height * 0.05}
          onClose={closePopupActions}
          height={height * 0.9}
          width={width * 0.95}
        >
          {/* {loading ? (
            <div className="center">
              <GlobalLoader />
            </div>
          ) : objectMetadata ? ( */}

          {isMetadataLoading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : (
            // objectMetadata ? (
            <>
              <div>
                <div className="detailViewCon">
                  {detailsObjList?.length !== 0 && (
                    <div className="objectTypeContainer">
                      <header className="sortByDropdownHeaderContainer">
                        <label className="sortByDropdownTitle"> Sort By</label>

                        <AsyncSelect
                          styles={customStyles}
                          defaultOptions
                          // isDisabled={props.isNew ? false : true}
                          loadOptions={loadSortDropdownList}
                          getOptionDisabled={(option) => option.isDisabled}
                          value={sortedDropdownVal}
                          onChange={onChangeDropdownVal}
                        />
                      </header>
                      <header className="objectTypeHeaderContainer">
                        <p className="objectTypeHeading" style={{ width: divWidth ? `${divWidth}em` : '72%', minWidth: '60%' }}>
                          ObjectType
                        </p>
                        <p className="objectTypeHeading " style={{ width: 140 }}>
                          Added On
                        </p>
                      </header>
                      <section className="objectTypeBody">
                        {detailsObjList?.map((values, index) => (
                          <>
                            <div className={'tableRowSearchDetails'} key={index}>
                              <div
                                key={values.index}
                                className={[objectTypeToView === values._id && 'clickedData', 'tableRowObjectType', 'col'].join(' ')}
                                onClick={() => selectObjectTypeToView(values._id)}
                              >
                                <p className={[objectTypeToView === values._id ? 'clickedObjectTypeName mb-0 pb-0 ' : 'objectTypeName mb-0 pb-0']}>{values.object_type.title}</p>
                                <div className="objectTypeContentSeperator">
                                  <div className={[objectTypeToView === values._id && 'clickedObjectTypeDiv', 'tableRowObjectTypeDiv'].join(' ')}></div>
                                  <img src={SelectedObjectTypeIcon} className={objectTypeToView === values._id ? 'optionClikedImage' : 'optionsImage'} alt={'options arrow'}></img>
                                </div>
                                <div className="d-flex flex-column pb-2">
                                  {dropdownData
                                    .filter((val) => val.data && val.data.lowCard === true && val.title?.toLowerCase() !== 'objecttype' && val.data.isVisible === true)
                                    .map((itm, i) => {
                                      const newTitle = itm.title;
                                      let filteredArray = dropdownData.filter(
                                        (val) => val.data && val.data.lowCard === true && val.title?.toLowerCase() !== 'objecttype' && val.data.isVisible === true
                                      );

                                      let hoveredTitles = '';
                                      let viewClickedId = null;
                                      filteredArray.map((j, index) => {
                                        viewClickedId = values._id;
                                        if (index >= 2)
                                          hoveredTitles = hoveredTitles + `${j.title} : ${values.object_specific[j.title] ?? ''} ${filteredArray.length - 1 === index ? '' : ', '}`;
                                      });

                                      return (
                                        <>
                                          <div className="d-flex">
                                            {i <= 1 && (
                                              <>
                                                <span className="pl-3 pr-0 mr-0">
                                                  <span style={{ fontWeight: '600', color: 'black' }}>{itm.title}:</span>{' '}
                                                  <span style={{ color: 'black' }}>
                                                    {/* faf7f7 */}
                                                    {values.object_specific[newTitle]}
                                                  </span>
                                                  {filteredArray.length >= 2 && i === 1 && filteredArray.length - 2 !== 0 && (
                                                    <span
                                                      title={`${hoveredTitles}`}
                                                      className={viewClickedId === objectTypeToView ? 'clickedObjectTypeOption' : 'objectTypeOptions'}
                                                    >
                                                      +{filteredArray.length - 2}
                                                    </span>
                                                  )}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                              {/*  */}
                              <div className={[objectTypeToView === values._id && 'clickedData', 'tableRowDate'].join(' ')}>
                                <p className="p-0 mt-3 mb-0 ml-3">{moment(values.object_type.edition_date).format('MM/DD/YYYY HH:mm:ss')}</p>
                              </div>
                              {isDeleteAccessible === true && (
                                <div className="d-flex justify-content-center align-itms-center">
                                  <img
                                    src={DeleteIcon}
                                    style={{ cursor: 'pointer', margin: '1rem 0rem 1rem 0.6rem' }}
                                    onClick={() => {
                                      DeleteFunc(values._id);
                                    }}
                                    alt={'deleteIcon'}
                                    className={'rowDeleteIcon'}
                                  ></img>
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                      </section>
                    </div>
                  )}

                  {loading ? (
                    <div className="center">
                      <CircularProgress />
                    </div>
                  ) : objectMetadata ? (
                    <>
                      <div className="fileViewer">
                        {docType === 'pdf' && (
                          <>
                            {' '}
                            <header className="previewHeaderContainer">
                              <p className="objectTypeHeading">Preview</p>
                            </header>
                            <PDFView
                              doc={doc}
                              docName={docName}
                              docType={docType}
                              onDocumentLoadSuccess={onDocumentLoadSuccess}
                              pageNumber={pageNumber}
                              changePage={changePage}
                              numPages={numPages}
                              zoom={zoom}
                              handleZoom={handleZoom}
                            ></PDFView>
                          </>
                        )}
                        {docType === 'image' && <ImageView doc={doc} docName={docName}></ImageView>}
                      </div>

                      <TabStrip className="detailViewer" selected={selected} onSelect={handleSelect}>
                        {userPermissions['ObjectMetadata_View_Object'] === true && (
                          <TabStripTab title="Object Metadata">
                            {/* TODO1  */}
                            <div
                              className="metadata"
                              style={{
                                pointerEvents: loaderUpdateMetaData === true ? 'none' : 'auto',
                              }}
                            >
                              <div className="returnedData" key={uuidv4()}>
                                {/* start Group dropdown */}
                                <div className="keyfont">Group</div>
                                {/* <div className="fsa-grid__1/2"> */}
                                <div className="fsa-field dropdownCustomStyle">
                                  <DropDownList
                                    className="dropDown"
                                    data={groupFilteredData}
                                    textField="display_name"
                                    dataItemKey="id"
                                    value={groupSelection}
                                    onChange={groupDropdownChange}
                                    style={{ minWidth: '100%' }}
                                    title={groupSelection?.display_name}
                                    itemRender={itemRender}
                                  />
                                </div>
                                {/* </div> */}
                                {/* end Group dropdown */}
                              </div>
                              {(groupSelection === '' || groupSelection === null || groupSelection === undefined) && (
                                <div className="d-flex justify-content-end">{handleCustomErrorMsg('This field is required.')}</div>
                              )}
                              <div className="returnedData" key={uuidv4()}>
                                {/* start Object Type dropdown */}
                                <div className="keyfont">Object Type</div>
                                {/* <div className="fsa-grid__1/2"> */}
                                <div className="fsa-field dropdownCustomStyle">
                                  <DropDownList
                                    className="dropDown"
                                    data={doctypeFilteredData}
                                    textField="display_name"
                                    dataItemKey="id"
                                    value={documentTitleSelection}
                                    onChange={DocTitleDropListChange}
                                    style={{ minWidth: '100%' }}
                                    title={documentTitleSelection?.display_name}
                                    itemRender={itemRender}
                                  />
                                </div>
                                {/* </div> */}
                                {/* end Object Type dropdown */}
                              </div>

                              <div className="returnedData" key={uuidv4()}>
                                {/* start Sections dropdown */}
                                <div className="keyfont">Section</div>
                                {/* <div className="fsa-grid__1/2"> */}
                                <div className="fsa-field dropdownCustomStyle">
                                  <DropDownList
                                    className="dropDown"
                                    data={sectionFilteredData}
                                    textField="display_name"
                                    dataItemKey="id"
                                    value={sectionSelection}
                                    onChange={sectionDropListChange}
                                    style={{ minWidth: '100%' }}
                                    title={sectionSelection?.display_name}
                                    itemRender={itemRender}
                                  />
                                </div>
                                {/* </div> */}
                                {/* end Sections dropdown */}
                              </div>
                              <div className="returnedData">
                                <div className="keyfont">Folder</div>
                                {/* <div className="fsa-field valuefont mb-0"> */}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  {folderEditable ? (
                                    <input
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setEditRefresh(!EditRefresh);
                                        setFolderValue(e.target.value);
                                      }}
                                      onKeyPress={(e) => {
                                        e.key === 'Enter' && setFolderEditable(!folderEditable);
                                      }}
                                      style={{ width: 'calc(100% - 15%)' }}
                                      value={folderValue}
                                    />
                                  ) : (
                                    folderValue
                                  )}
                                  <img
                                    style={{
                                      marginLeft: '2px',
                                      padding: '2px 0px 4px 10px',
                                      height: '2.5rem',
                                      cursor: 'pointer',
                                    }}
                                    src={EditIcon}
                                    alt="pencilIcon"
                                    onClick={() => setFolderEditable(!folderEditable)}
                                  />
                                  {/* </div> */}
                                </div>
                              </div>

                              {folderValue === '' && <div className="d-flex justify-content-end">{handleCustomErrorMsg('This field is required.')}</div>}
                              {metadataView(metadataDisplayList)}
                            </div>
                            <div
                              style={{
                                textAlign: 'right',
                                marginTop: '10px',
                                marginRight: '1em',
                              }}
                              className="d-flex justify-content-end"
                            >
                              <button onClick={onSaveButtonClick} disabled={loaderUpdateMetaData} className="fsa-btn">
                                <span>Save</span>
                              </button>
                              {loaderUpdateMetaData && (
                                <div>
                                  <GlobalLoader />
                                </div>
                              )}
                            </div>
                          </TabStripTab>
                        )}
                        {userPermissions['RecordManagement_View_Object'] === true && (
                          <TabStripTab title="Records Management">
                            {/* TODO2 RENAME to AssignFileCode */}
                            {/* <SearchDetail metaData={objectMetadata} /> */}

                            <div style={{ overflowX: 'auto' }}>
                              <div className="detailSection">
                                <div className="detailSectionTitle">
                                  <h4>Filecode information</h4>
                                  <div className="flexrow">
                                    <button onClick={() => toggleUpdateObjFileCodePopup(true)} className="fsa-btn clear-btn">
                                      Update Filecode
                                    </button>
                                    <span>
                                      {sectionOpen ? (
                                        <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                                      ) : (
                                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="detailSectionContent">
                                  <div className="metaItem">
                                    <span className="metaName">File Code Name: {fileCodeName}</span>
                                  </div>
                                  <div className="metaItem">
                                    <span className="metaName">File Code id: {objectMetadata.record_management ? objectMetadata.record_management.file_code : ''}</span>
                                  </div>
                                </div>
                              </div>
                              <UpdateObjectFileCodeModal
                                setObjectMetadata={setObjectMetadata}
                                objectMetadata={objectMetadata}
                                objectID={objectID}
                                windowHeight={window.innerHeight}
                                windowWidth={window.innerWidth}
                                loadingFileCodes={loadingFileCodes}
                                setLoadingFileCodes={setLoadingFileCodes}
                                objFileCodePopupOpen={objFileCodePopupOpen}
                                setRecords={setRecords}
                                records={records}
                                toggleUpdateObjFileCodePopup={toggleUpdateObjFileCodePopup}
                                ids={[objectMetadata._id]}
                              ></UpdateObjectFileCodeModal>

                              <div className="detailSection">
                                <div className="detailSectionTitle">
                                  <h4>Hold information</h4>
                                  <div className="flexrow">
                                    <button className="fsa-btn clear-btn" onClick={openHoldModal}>
                                      Update Hold
                                    </button>
                                    <span>
                                      {sectionOpen ? (
                                        <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                                      ) : (
                                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="detailSectionContent">
                                  <div className="metaItem">
                                    <span className="metaName">Hold Name: {holdInfo !== null ? holdInfo?.holdName : ''}</span>
                                  </div>
                                  <div className="metaItem">
                                    <span className="metaName">Type of Hold: {holdInfo !== null ? holdInfo?.holdtype : ''}</span>
                                    <br></br>
                                  </div>
                                </div>
                              </div>
                              <UpdateObjectHoldModal onClose={closeHoldModal} visible={isHoldModalVisible} recordIds={[objectMetadata?._id]} />

                              {/* Disposition Information Start */}
                              <div className="detailSection">
                                <div className="detailSectionTitle">
                                  <h4>Disposition information</h4>
                                  <div className="flexrow">
                                    <button className="fsa-btn clear-btn" onClick={UpdateDisposition}>
                                      Update Disposition
                                    </button>
                                    <span>
                                      {sectionOpen ? (
                                        <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                                      ) : (
                                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="detailSectionContent">
                                  <div className="metaItem">
                                    <span className="metaName">Cutoff Date:</span>
                                    {/* <DatePicker className="disposition-datepicker" onChange={handleStartDateChange} selected={startDate} /> */}

                                    <input type="date" value={startDate} format="MM/dd/yyyy" name={'end_' + startDate} onChange={handleStartDateChange} />
                                  </div>
                                  <div className="metaItem">
                                    <span className="metaName">Disposition Date:</span>
                                    <span>
                                      {objectMetadata?.record_management?.projected_disposition_date !== undefined
                                        ? moment(objectMetadata?.record_management?.projected_disposition_date).format('MM-DD-YYYY')
                                        : '-'}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* Disposition Information End */}
                              <div
                                style={{
                                  textAlign: 'right',
                                  marginTop: '10px',
                                  marginRight: '1em',
                                }}
                                className="d-flex justify-content-end"
                              >
                                {' '}
                                <button onClick={onSaveButtonClick} disabled={loaderUpdateMetaData} className="fsa-btn">
                                  <span>Save</span>
                                </button>
                                {loaderUpdateMetaData && (
                                  <div>
                                    <GlobalLoader />
                                  </div>
                                )}
                              </div>
                            </div>
                          </TabStripTab>
                        )}
                        {userPermissions['View_Logs'] === true && (
                          <TabStripTab title="Logs">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <a
                                style={{
                                  color: '#fff',
                                  borderRadius: '5px',
                                  padding: '2rem 0 2rem',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  marginBottom: '1em',
                                  background: '#205493',
                                  textDecoration: 'none',
                                  fontSize: '1.2em',
                                  fontWeight: '500',
                                  letterSpacing: '0.24px',
                                  lineHeight: 'normal',
                                }}
                                download={'logs.txt'}
                                href={`data:text/plain,${JSON.stringify(objectMetadata.doc_modification_history)}`}
                              >
                                Download Logs
                              </a>
                            </div>

                            <h3 style={{ color: '#000' }}>Object History</h3>
                            <ListView
                              style={{ maxHeight: '45 em', overflow: 'hidden' }}
                              data={objectMetadata.doc_modification_history}
                              item={LogItemRender}
                              className="k-listview"
                            />
                            <h3 style={{ color: '#000' }}>Records Management History</h3>
                            <ListView
                              style={{ maxHeight: '45em', overflow: 'hidden' }}
                              data={objectMetadata.record_management ? objectMetadata.record_management.file_code_change_history : []}
                              item={(props) => FcLogItemRender(props, objectMetadata.record_management)}
                              className="k-listview"
                            />
                          </TabStripTab>
                        )}
                        {userPermissions['Can_View_Sense'] === true && (
                          <TabStripTab title="SENSE - β">
                            <AliceSenseTab docNumber={objectMetadata?.object_specific?.document_ocr_id} />
                          </TabStripTab>
                        )}
                      </TabStrip>
                    </>
                  ) : (
                    <div className="center">
                      <h3>{errorMessage}</h3>
                      <div className="fsa-divider"></div>
                      {/* <h2>{cellClicked}</h2> */}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    textAlign: 'right',
                    marginTop: '10px',
                    marginRight: '5em',
                  }}
                >
                  {' '}
                </div>
              </div>
            </>
          )}
          <SuccessProcessGrowl
            isSuccessGrowlHidden={isSuccessGrowlHidden}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            message={'Successfully updated file object metadata.'}
          />
          <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden1} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden1} message={message} />
          <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        </Window>

        <div>
          {deleteModalShow === true && (
            <DeleteModal
              show={deleteModalShow}
              DeleteDataFunc={DeleteDataFunc}
              onHide={() => setDeleteModalShow(false)}
              setDeleteModalShow={setDeleteModalShow}
              selectedDeleteRowData={deleteData}
              setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
              setMessage={setMessage}
              popupTitle="Object"
            ></DeleteModal>
          )}
        </div>
      </div>
    )
  );
}
