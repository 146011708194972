import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const oktaTokenVal =
    sessionStorage.getItem('UserLoginFrom') !== 'System'
      ? JSON.parse(sessionStorage.getItem('okta-token-storage')) !== null && Object.keys(JSON.parse(sessionStorage.getItem('okta-token-storage'))).length != 0
        ? true
        : false
      : JSON.parse(sessionStorage.getItem('isUserAuthenticated'));

  return <Route {...rest} render={(props) => (oktaTokenVal === true ? <Component {...props} /> : <Redirect to={'/login'} />)} />;
};

export default PrivateRoute;
