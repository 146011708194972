import './Timer.scss';

const Timer = ({ degree, searchTumbnailColor }) => {
  return (
    <div className="icon-holder">
      <div>
        <svg viewBox="0 0 256 256">
          <g id="button-top">
            <path
              fill="none"
              stroke={degree >= 50 && degree < 75 ? 'orange' : degree >= 75 || degree == 100 ? 'red' : 'green'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="16"
              d="M128 8v29"
            />
            <path
              fill="none"
              stroke={degree >= 50 && degree < 75 ? 'orange' : degree >= 75 || degree == 100 ? 'red' : 'green'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="16"
              d="M112 8h32"
            />
          </g>
          <g id="button-top-right">
            <path
              fill="none"
              stroke={degree >= 50 && degree < 75 ? 'orange' : degree >= 75 || degree == 100 ? 'red' : 'green'}
              strokeLinecap="round"
              transform="rotate(35 160 50)"
              strokeLinejoin="round"
              strokeWidth="16"
              d="M195 8v29"
            />
          </g>
          <circle
            cx="128"
            cy="143"
            r="105"
            stroke={degree >= 50 && degree < 75 ? 'orange' : degree >= 75 || degree == 100 ? 'red' : 'green'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
        </svg>
        <div
          className="innerCircle"
          style={{
            backgroundImage: `conic-gradient( ${degree >= 50 && degree < 75 ? 'orange' : degree >= 75 || degree == 100 ? 'red' : 'green'} ${degree}%,transparent 0%)`,
          }}
        ></div>
      </div>
    </div>
  );
};
export default Timer;
