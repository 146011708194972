import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import calculateSize from 'calculate-size';
import { orderBy } from '@progress/kendo-data-query';
import './TableManageDisposition.scss';
import moment from 'moment';
import searchImg from '../../fsa-style/img/searchImg.svg';
import { SingleObjectViewDetailsModal } from '../../newcomponents/SingleObjectViewDetailsModal/SingleObjectViewDetailsModal';

const dateColumn = ['projectedCutOffDate', 'projectedDispositionDate'];
const headerStyle = (column) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: '#25a4db' }} className="k-link" onClick={column.onClick}>
      <div className="tableHeaderTitle">
        {column.title}
        <span>
          {column.children[0]?.props?.className === 'k-icon k-i-sort-asc-sm' ? ' ⬆' : column.children[0]?.props?.className === 'k-icon k-i-sort-desc-sm' ? '⬇' : ''} {column.sort}
        </span>
      </div>
    </div>
  );
};

export const calculateWidth = (data, field, columnName) => {
  let maxWidth = 0;
  const size = calculateSize(columnName.replaceAll('_', ' '), {
    font: 'Arial',
    fontSize: '26px',
  });
  maxWidth = size.width;
  data.forEach((item) => {
    const contentSize = calculateSize(item[field], {
      font: 'Arial',
      fontSize: '24px',
    });
    if (contentSize.width > maxWidth) {
      maxWidth = contentSize.width;
    }
  });
  return maxWidth;
};

export default function TableManageDisposition(props) {
  const [rowsChecked, setRowsChecked] = useState([]);

  const { data = [], fields, total = data.length, page, pageSize, onPageChange, selectedRows } = props;

  const [sort, setSort] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [openSingleObjectPopup, setOpenSingleObjectPopup] = useState(false);
  const [recordId, setRecordId] = useState();

  useEffect(() => {
    // Set "checked" property to false for each data item initially
    if (page === 0) {
      const initialData = data.slice(0, pageSize).map((obj, idx) => ({
        ...obj,
        checked: false,
        id: idx,
      }));
      setTableData(initialData);
      // props.setSelectedRows([]);
    } else {
      const initialData = data.slice(pageSize * page, pageSize + pageSize * page).map((obj, idx) => ({
        ...obj,
        checked: false,
        id: idx,
      }));
      setTableData(initialData);
      // props.setSelectedRows([]);
    }
  }, [data, page, pageSize]);

  const handleCheckboxChanges = (event, dataItem) => {
    const updatedData = tableData.map((item) => (item.id === dataItem.id ? { ...item, checked: event.target.checked } : item));
    if (props.setSelectedRows) {
      props.setSelectedRows(updatedData.filter((item) => item.checked));
    }
    setTableData(updatedData);
  };

  useEffect(() => {
    const isSelectAllChecked = selectedRows?.length > 0 && selectedRows?.length === tableData?.length ? true : false;
    props.setSelectAllChecked(isSelectAllChecked);
  }, [selectedRows]);

  const headerStyleWithCheckbox = (column) => {
    return (
      <div style={{ backgroundColor: '#25a4db', display: 'flex' }} className="k-link" onClick={column.onClick}>
        <input type="checkbox" className="k-checkbox" checked={props.selectAllChecked} onChange={handleSelectAllChange} />
        <div style={{ fontWeight: 'bold', color: 'white', fontSize: '16px' }}>
          <span style={{ fontWeight: 'bold', color: 'white', fontSize: '16px', paddingLeft: '1rem' }} onClick={column.onClick}>
            {column.title}
            {column.field !== 'selected' && (
              <span>
                {column.children[0]?.props?.className === 'k-icon k-i-sort-asc-sm' ? ' ⬆' : column.children[0]?.props?.className === 'k-icon k-i-sort-desc-sm' ? '⬇' : ''}
                {column.sort}
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };
  const handleCheckboxChange = (event, dataItem) => {
    const updatedData = tableData.map((item) => (item.id === dataItem.id ? { ...item, checked: event.target.checked } : item));
    if (props.setSelectedRows) {
      props.setSelectedRows(updatedData.filter((item) => item.checked));
    }
    setTableData(updatedData);

    const allChecked = updatedData.every((item) => item.checked);
    props.setSelectAllChecked(allChecked);

    if (event.target.checked === true) {
      const row = [dataItem];
      props.setRowsChecked(row);
    } else {
      props.setRowsChecked();
    }
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    const updatedData = tableData.map((item) => ({
      ...item,
      checked: checked,
    }));
    if (props.setSelectedRows) {
      props.setSelectedRows(checked ? updatedData : []);
    }
    setTableData(updatedData);
    props.setSelectAllChecked(checked);
    if (event.target.checked === true) {
      const row = [{}];
      props.setRowsChecked(row);
    } else {
      props.setRowsChecked();
    }
  };

  const sortedData = orderBy(data, sort);
  const slicedData = sortedData.slice(pageSize * page, pageSize + pageSize * page);

  useEffect(() => {
    const updatedTableData = slicedData.map((item, idx) => ({
      ...item,
      id: idx,
      checked: tableData[idx] ? tableData[idx].checked : false,
    }));

    setTableData(updatedTableData);
  }, [data, sort, page, onPageChange]);

  useEffect(() => {
    if (selectedRows.length == 0) {
      const updatedTableData = slicedData.map((item, idx) => ({
        ...item,
        id: idx,
        checked: false,
      }));
      setTableData(updatedTableData);
    }
  }, [pageSize, page, onPageChange]);

  const onRowClick = (props) => {
    setOpenSingleObjectPopup(true);
    setRecordId(props?.guid);
  };

  return (
    <>
      <Grid
        {...props}
        total={total}
        data={orderBy(tableData, sort)}
        page={page}
        pageSize={pageSize}
        skip={page * pageSize}
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 15, 20, 25],
          previousNext: true,
        }}
        onPageChange={onPageChange}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridNoRecords>No results</GridNoRecords>
        <Column
          headerCell={headerStyleWithCheckbox}
          field="selected"
          cell={({ dataItem }) => (
            <td>
              <input type="checkbox" className="k-checkbox" checked={dataItem.checked} onChange={(e) => handleCheckboxChanges(e, dataItem)} />
            </td>
          )}
          title="Select" // Add the title here
          width={90} // Adjust the width if needed
          filterable={false}
        />

        {data &&
          fields.map((column, index) => {
            if (!dateColumn.includes(column.field)) {
              return (
                <Column
                  headerCell={headerStyle}
                  field={column.field}
                  title={column.title}
                  key={index}
                  cell={column.cell}
                  width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                />
              );
            } else {
              return (
                <Column
                  headerCell={headerStyle}
                  field={column.field}
                  title={column.title}
                  key={index}
                  cell={(dataItem) => <td className="pb-0 pt-0">{moment(dataItem.dataItem[dataItem.field]).format('MM-DD-YYYY')}</td>}
                  width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                />
              );
            }
          })}
        <Column
          headerCell={headerStyle}
          field=""
          cell={({ dataItem }) => (
            <td>
              <div className="actionColumn">
                <img
                  src={searchImg}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onRowClick(dataItem);
                  }}
                  alt={'searchImg'}
                ></img>
              </div>
            </td>
          )}
          title=""
          width={100}
          filterable={false}
        />
      </Grid>

      {openSingleObjectPopup === true && (
        <SingleObjectViewDetailsModal
          setOpenObjectPopup={setOpenSingleObjectPopup}
          openObjectPopup={openSingleObjectPopup}
          recordId={recordId}
          setRecordId={setRecordId}
          getData={props.getDispositions}
        />
      )}
    </>
  );
}
