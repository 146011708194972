import React, { useContext, useState, useRef, useEffect } from 'react';
import UserContext from '../UserContext/UserContext';
import { IdleTimeOutModal } from '../modal/IdleModal';
import IdleTimer from 'react-idle-timer';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
// import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './head.scss';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { isUserAuthenticated } from '../../services/actions/action';
import { getSystemConfigDetails } from '../../services/inBasketService';
import High_msg from '../../fsa-style/img/svgs/high_message.png';
import { useLocation, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { userLogout } from '../Login/LoginService';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import User_Guide from './../../fsa-style/img/svgs/User_guide3.svg';
// import UserGuide from './../../fsa-style/documents/SMC User Training Guide.pdf';
const Head = ({ logout }) => {
  const { userInfo } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  // const [page, setPage] = useState(1);
  const [userName, setUserName] = useState();
  const [userAgencyCode, setUserAgencyCode] = useState();
  const [userAddress, setUserAddress] = useState();
  const [path, setPath] = useState(window.location.pathname);
  const { messages, buowDetails } = useSelector((state) => state);
  const dispatch = useDispatch();
  // const [msgForUndo, setMsgForUndo] = useState([]);
  const { oktaAuth, authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useHistory();
  // const userInfoDetails = useAuthUser();
  const [systemConfig, setSystemConfig] = useState((window.localStorage.getItem('system_config') && JSON.parse(window.localStorage.getItem('system_config'))) || {});

  const loginUserName = sessionStorage.getItem('userName');
  const contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
  const location = useLocation();

  useEffect(() => {
    setPath(window.location.pathname);
    getSystemConfigDetails(JSON.parse(sessionStorage.getItem('user_information'))?.contextId);
  }, [location, path, buowDetails, sessionStorage.getItem('userToken')]);
  useEffect(() => {
    if (Object.keys(systemConfig).length === 0) {
      updateSysConfig();
    }
  }, []);

  const updateSysConfig = async () => {
    let response = await getSystemConfigDetails(contextId);
    setSystemConfig(response?.data);
  };

  useEffect(() => {
    if (userInfo.usdaagencycode) {
      let agency_name = '';
      switch (userInfo.usdaagencycode[0]) {
        case 'FA':
        case 'CE':
          agency_name = 'FSA';
          break;
        case '16':
          agency_name = 'NRCS';
          break;
        case '08':
          agency_name = 'RMA';
          break;
        case '12':
        case '99':
          agency_name = 'FBC';
          break;
        default:
          agency_name = 'N/A';
      }
      setUserName(userInfo.name);
      setUserAgencyCode(agency_name);
      setUserAddress(userInfo.address);
    }
    setPath(window.location.pathname);
  }, [userName, userAgencyCode, userAddress, userInfo, path]);

  useEffect(() => {
    if (authState !== null) {
      sessionStorage.setItem('isOktaUserAuthenticated', authState.isAuthenticated);

      if (sessionStorage.getItem('UserLoginFrom') === 'Okta') sessionStorage.setItem('isUserAuthenticated', authState.isAuthenticated);

      dispatch(isUserAuthenticated(authState.isAuthenticated));
    }
  }, [authState, dispatch]);

  // useEffect(() => {
  //   async function data() {
  //     await getMessages().then((response1) => {
  //       if (response1?.data?.length > 0) {
  //         response1.data.forEach((element) => {
  //           element.isDeleteStarted = false;
  //         });
  //         setMsgForUndo(response1.data);
  //       }
  //     });
  //     dispatch(getMessagesCountNew());
  //   }
  //   data();
  // }, [page]);

  // {
  //   /* Start->Commented changes for login page */
  // }
  const handleOnIdle = () => {
    JSON.parse(sessionStorage.getItem('isOktaUserAuthenticated')) === false && loggingOut();
    // setShowModal(true);
    // sessionTimeoutRef.current = setTimeout(handleEAuthRedirect, 30000);
  };
  const handleClose = () => {
    setShowModal(false);
    clearTimeout(sessionTimeoutRef.current);
  };
  // {
  //   /* End->Commented changes for login page */
  // }

  const handleEAuthRedirect = () => {
    loggingOut(); //Commented changes for login page
    // window.location.href = process.env.REACT_APP_EAUTH_LOGOUT_URL;
  };

  // const onDeleteMessage = (obj) => {
  //   let newData = msgForUndo;
  //   let index = newData.indexOf(obj);
  //   obj.isDeleteStarted = true;

  //   let timer = window.setTimeout(() => {
  //     newData.splice(index, 1);
  //     let url = 'messageId=' + obj.messageId;
  //     dismissMessage(url).then((response1) => {
  //       setMsgForUndo([...newData]);
  //       dispatch(getMessagesCountNew());
  //     });
  //   }, 3000);
  //   obj.timer = timer;
  //   setMsgForUndo([...newData]);
  // };

  // const undoDeleteMessage = (obj) => {
  //   window.clearTimeout(obj.timer);
  //   let newData = messages;
  //   obj.isDeleteStarted = false;
  //   obj.timer = null;
  //   setMsgForUndo([...newData]);
  //   dispatch(getMessagesCountNew());
  // };

  const popover = (
    <Popover id="popover-contained" style={{ maxWidth: 'initial', width: '450px', height: '350px', fontSize: '15px', overflowY: 'scroll' }}>
      <Popover.Header style={{ fontSize: '15px', textAlign: 'center' }}>Messages</Popover.Header>
      <Popover.Body>
        {messages &&
          messages.map((obj, idx) => {
            return (
              <div className="popBody" key={`${idx}_head_msg`}>
                <strong>{obj.sender}</strong>
                {` has added `}
                <br />
                {obj.priority === 'H' && <img src={High_msg} alt="High_msg" style={{ height: 30 }} />}
                {` ${obj?.messageBody}`}

                <p className="popBody__date">
                  {moment(obj.messageDttm).format('ddd, DD MMM HH:MM a')}
                  {/* {obj.dismissed === false && obj.isDeleteStarted === false && (
                    <i className="fa fa-trash popBody__Btn" onClick={() => onDeleteMessage(obj)} style={{ cursor: 'pointer' }}></i>
                  )}
                  {!!obj.isDeleteStarted && obj.isDeleteStarted === true && (
                    <button className="popBody__undoBtn" onClick={() => undoDeleteMessage(obj)}>
                      Undo
                    </button>
                  )} */}
                </p>
              </div>
            );
          })}
      </Popover.Body>
    </Popover>
  );
  // messgae popUP end

  const loggingOut = async () => {
    if (sessionStorage.getItem('UserLoginFrom') === 'System') {
      setIsLoading(true);
      // User logout call
      let data = {
        token: sessionStorage.getItem('userToken'),
      };
      await userLogout(data).then(async (response) => {
        sessionStorage.clear();
        localStorage.clear();

        setTimeout(() => {
          setIsLoading(false);
          navigate.push('/login');
        }, 3000);
      });
    } else if (sessionStorage.getItem('UserLoginFrom') === 'Okta') {
      await oktaAuth.signOut();
      sessionStorage.clear();
      localStorage.clear();
    }
    window.location.reload();
  };

  const onSelectUserName = () => {
    navigate.push('/profile');
  };

  return (
    <>
      {isLoading && <GlobalLoader />}

      <IdleTimer ref={idleTimerRef} element={document} onIdle={handleOnIdle} debounce={250} timeout={parseInt(process.env.REACT_APP_TIME_OUT)} />
      <header>
        <div className="fsa-tophat fsa-tophat--fullscreen">
          <div className="fsa-tophat__bd" style={{ marginLeft: '2em', marginRight: '2em' }}>
            <div className="fsa-tophat__primary">
              <span className="fsa-tophat__agency">
                <a href="/" style={{ textDecoration: 'none' }}>
                  <div className="fsa-tophat__link">
                    {systemConfig !== null && (
                      <>
                        {systemConfig.logo && <img role="presentation" className="fsa-tophat__agency-logo" src={`data:image/jpeg;base64,${systemConfig.logo}`} alt="" />}
                        <abbr className="fsa-tophat__agency-abbr" title="United States Department of Agriculture">
                          USDA
                        </abbr>
                        <span className="fsa-tophat__agency-fullname" style={{ fontSize: '14px', textDecoration: 'underline', paddingRight: 5 }}>
                          {systemConfig.title}
                          {/* An official website of the United States Department of Agriculture */}
                        </span>{' '}
                        {process.env.REACT_APP_ENVIRONMENT === 'UAT' && (
                          <span style={{ color: 'black', borderLeft: '1px solid #DCDCDC', paddingLeft: 6, fontWeight: 800, fontSize: 18 }}>SMC UAT</span>
                        )}
                        {process.env.REACT_APP_ENVIRONMENT === 'DRIMS Demo' && (
                          <span style={{ color: 'black', borderLeft: '1px solid #DCDCDC', paddingLeft: 6, fontWeight: 800, fontSize: 18 }}>DRIMS Demo</span>
                        )}
                      </>
                    )}
                  </div>
                </a>
                {/* <a className="fsa-tophat__link" href="//usda.gov" title="Link to USDA homepage" rel="noreferrer"></a> */}
              </span>
            </div>
            <div className="fsa-tophat__secondary row d-flex align-items-center">
              {/* <span className="fsa-tophat__subagency">
								<abbr className="fsa-header-app__app-abbr">FPAC</abbr>
                &nbsp;
                <span>&nbsp;&nbsp;Farm Production and Conservation</span>
							</span> */}

              {/* start */}
              <OverlayTrigger trigger={'click'} rootClose placement="bottom" overlay={popover}>
                <button className="message-btn">
                  <div className="headTextOuterDiv row mt-2 mr-5" style={{ cursor: 'pointer' }}>
                    <span className="headText" style={{ margin: '0px 20px' }}>
                      {'Messages'}
                    </span>
                    <div className="row">
                      <i className="fa fa-bell headIcon"></i>
                      <div className="messageCount">
                        <span>{messages?.length}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </OverlayTrigger>
              {/* end */}

              <a
                href={process.env.REACT_APP_BACKEND_URL + '/aws_s3_api/s3/' + process.env.REACT_APP_S3_USER_GUIDE_BUCKET_NAME + '/SMC User Training Guide.pdf'}
                download="SMC User Training Guide.pdf"
              >
                <img src={User_Guide} alt="user_guide_icon" className="user_guide_icon cursor" title="User Guide" />
              </a>
              {systemConfig !== null && <span> User Guide</span>}
            </div>
          </div>
        </div>
        <div className="fsa-header-app fsa-header-app--fullscreen" style={{ backgroundColor: '#25a4db' }}>
          <div className="fsa-header-app__bd p-2 pl-5" style={{ marginLeft: '2em', marginRight: '2em', alignItems: 'center' }}>
            {/* <div className="fsa-header-app__primary">
              <a className="fsa-header-app__home-link" href="boilerplate.html">
								<span className="fsa-header-app__app-name">
                <img role="presentation" className="img-logo" style={{height:"80%"}} src="img/Brillient-Logo.png" alt=""/>
									{/* <abbr className="fsa-header-app__app-abbr">DRMS</abbr>
									<span className="fsa-header-app__app-full">Digital Records Management System</span> 
								</span>
              </a>
            </div> */}

            {/* Commented changes for login page */}
            {path === '/inbasketpage' && <span className="ml-5">My In-Basket</span>}
            {path === '/' && <span className="ml-5">My In-Basket</span>}
            {path === '/filecode' && <span className="ml-5">Manage Record Schedule</span>}
            {path === '/scan' && <span className="ml-5">Scan On Demand</span>}
            {path === '/dashboard' && <span className="ml-5">Dashboard</span>}
            {path === '/admin' && <span className="ml-5">Administrator Panel</span>}
            {path === '/search' && <span className="ml-5">Search</span>}
            {path === '/fileplan' && <span className="ml-5">Create Retention Instruction</span>}
            {path === '/buow' && <span className="ml-5">BUOW Validation Page</span>}
            {path === '/routingsteps' && <span className="ml-5">Routing Steps</span>}
            {path === '/orchestration' && <span className="ml-5">Routing Orchestration</span>}
            {path === '/ruledefinition' && <span className="ml-5">Rule Definition</span>}
            {path === '/systemconfiguration' && <span className="ml-5">System Configuration</span>}
            {path === '/userstable' && <span className="ml-5">Users</span>}
            {path === '/rolestable' && <span className="ml-5">Roles</span>}
            {path === '/permissionstable' && <span className="ml-5">Permissions</span>}
            {path === '/system-folder' && <span className="ml-5">System Folder</span>}
            {path === '/user-preference' && <span className="ml-5">User Preference</span>}
            {path === '/profile' && <span className="ml-5">User Profile</span>}

            {path === '/holds' && <span className="ml-5">Manage Holds</span>}
            {path === '/transfer' && <span className="ml-5">Manage Transfers</span>}
            {path === '/manage' && <span className="ml-5">Manage Disposition</span>}
            {path.includes('/buow/') && buowDetails && <span className="ml-5">{buowDetails}</span>}
            {path === '/userguide' && <span className="ml-5">User Guide</span>}
            {/* buow_page_title */}

            <div className="fsa-header-app__secondary">
              <div>
                <p
                  style={{
                    alignItems: 'right',
                    paddingBottom: '0em',
                    marginBottom: '0em',
                  }}
                >
                  {/* | {userInfo.name} */}
                  {/* {userInfoDetails !== null && userInfoDetails.name} | BRILLIENT USA */}
                  <span style={{ textTransform: 'capitalize', cursor: 'pointer' }} onClick={onSelectUserName}>
                    {loginUserName && loginUserName}
                  </span>{' '}
                  | {contextId}
                  <span
                    // href="https://www.eauth.usda.gov/Logout/logoff.asp"
                    onClick={loggingOut}
                    style={{ cursor: 'pointer' }}
                  >
                    {' |  Log out '}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <nav>
          <div className="fsa-nav-global fsa-nav-global--fullscreen">
            <div className="fsa-nav-global__bd" style={{marginLeft: "2em", marginRight: "2.75em"}}>
              <ul className="fsa-nav-global__list" aria-label="Primary Navigation" id="primary-navigation">

                <li className="fsa-nav-global__list-item">
                  <NavLink exact to="/" className="fsa-nav-global__link" activeClassName="selectedNav">
                    <span className="fsa-nav-global__text">Dashboard</span>
                  </NavLink>
                </li>

                <li className="fsa-nav-global__list-item">
                  <NavLink to="/suspenseQueue" className="fsa-nav-global__link" activeClassName="selectedNav"
                           activeStyle={{color: '#236430'}}>
                    <span className="fsa-nav-global__text">Suspense Queue</span>
                  </NavLink>
                </li>

                <li className="fsa-nav-global__list-item">
                  <NavLink to="/Search" className="fsa-nav-global__link" activeClassName="selectedNav"
                           activeStyle={{color: '#236430'}}>
                    <span className="fsa-nav-global__text">Search</span>
                  </NavLink>
                </li>

                <li className="fsa-nav-global__list-item">
                  <div>
                    <p className="fsa-nav-global__link main-nav-item">
                      <span className="fsa-nav-global__text">Records Management</span>
                    </p>
                    <div className="subnav slide-bottom">
                      <NavLink to="/fileplan" className="fsa-nav-global__link" activeClassName="selectedNav"
                               activeStyle={{color: '#236430'}}>
                        <span className="fsa-nav-global__text">Create Retention Instruction</span>
                      </NavLink>
                      <NavLink to="/filecode" className="fsa-nav-global__link" activeClassName="selectedNav"
                               activeStyle={{color: '#236430'}}>
                        <span className="fsa-nav-global__text">Create Record Schedule</span>
                      </NavLink>
                      <NavLink to="/holds" className="fsa-nav-global__link" activeClassName="selectedNav"
                               activeStyle={{color: '#236430'}}>
                        <span className="fsa-nav-global__text">Create Hold</span>
                      </NavLink>
                      <NavLink to="/transfer" className="fsa-nav-global__link" activeClassName="selectedNav"
                               activeStyle={{color: '#236430'}}>
                        <span className="fsa-nav-global__text">Create Transfer</span>
                      </NavLink>
                    </div>
                  </div>
                </li>

                <li className="fsa-nav-global__list-item">
                  <a className="fsa-nav-global__link" href="link.html">
                    <span className="fsa-nav-global__text">Doctype+</span>
                  </a>
                </li>

              </ul>
              <a style={{textDecoration: "none"}} target="_blank" href="https://www.usda.gov">
                <FontAwesomeIcon icon={faQuestionCircle}/> Help
              </a>
            </div>
          </div>
        </nav> */}
        {JSON.parse(sessionStorage.getItem('isOktaUserAuthenticated')) === false && showModal && (
          <IdleTimeOutModal showModal={showModal} handleClose={handleClose} handleLogout={handleEAuthRedirect} />
        )}
      </header>
    </>
  );
};

export default Head;
