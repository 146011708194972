import { MESSAGES, IS_AUTHENTICATED, SET_ALICE_HISTORY,SET_BUOW_DETAILS } from "../constants";

const initialState = { messages: [], isAuthenticated: false, aliceHistory: [], buowDetails:[] };

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGES:
      return { ...state, messages: (state.messages = action.data) };
    case IS_AUTHENTICATED:
      return { ...state, isAuthenticated: (state.isAuthenticated = action.data) } 
    case SET_ALICE_HISTORY:
      return { ...state, aliceHistory: action.data }
      case SET_BUOW_DETAILS:
      return { ...state, buowDetails: action.data } 
    default:
      return state;
  }
};

export default rootReducer;
