import React, { useState, useEffect } from 'react';
import '../../../components/FileCode/FileCode.scss';
import RolesTableData from './RolesTableData.json';
import TableRolesTable from './TableRolesTable';
import { getPermissions, createRolesTable, updateOneRolesTable, deleteRolesTable, searchRolesTable, getSearchContextId, getDefaultPermissions } from './RolesTableServices';
import styles from './RolesTable.module.scss';
import '../../../GlobalStyle.scss';

import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import AsyncSelect from 'react-select/async';
import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import Footer from '../../../newcomponents/Footer/Footer';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError, resetErrorMessage } from '../../../utils/helper/handleReuiredErrorFunc';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Roles', link: '' },
];

export default function RolesTable() {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [createEditModalShow, setCreateEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDeleteRowData, setSelectedDeleteRowData] = useState();
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [rolesTableDetails, setRolesTableData] = useState(RolesTableData);

  const [formData, setFormData] = useState({
    contextId: '',
    roleName: '',
    roleDescription: '',
    permissions: [],
  });

  async function fetchRolesTableData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(false);
    fetchRolesTableData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);

    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      roleName: '',
      roleDescription: '',
      permissions: [],
    });
    setIsNewRecord(true);
    setCreateEditModalShow(true);
  };

  const EditRowDataFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      roleName: data.roleName,
      roleDescription: data.roleDescription,
      id: data.id,
      permissions: data.permissions,
    });

    setIsNewRecord(false);
    setCreateEditModalShow(true);
  };

  const DeleteRowDataFunc = (DeleteRowData) => {
    setDeleteModalShow(true);
    setSelectedDeleteRowData(DeleteRowData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRolesTable(payload).then((response) => {
      setData(response.data);

      setPage(0);
      setLoading(false);
    });
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };
    deleteRolesTable(sendData).then(async (response) => {
      if (response) {
        setDeleteModalShow(false);
        await fetchRolesTableData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Role deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoading(false);
      }
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRolesTableData = JSON.parse(JSON.stringify(rolesTableDetails));
    newRolesTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setRolesTableData(newRolesTableData);
    await fetchNewRolesTableData();
  };

  const fetchNewRolesTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.rolesTablePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.rolesTableContent}>
        <h1 className={styles.pageTitle}>Roles</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent searchText={searchText} handleSearch={handleSearch} onSearch={onSearch} clearSearch={clearSearch}></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Role" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>

        <div className={[styles.rolesTable, 'glbRolesTable'].join(' ')}>
          <TableRolesTable
            data={data}
            EditRowDataFunc={EditRowDataFunc}
            DeleteRowDataFunc={DeleteRowDataFunc}
            setDeleteModalShow={setDeleteModalShow}
            fields={rolesTableDetails}
            page={page} // Pass the page state from the parent component
            pageSize={pageSize} // Pass the pageSize state from the parent component
            onPageChange={onPageChange}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <CreateEditRolesTableModal
          show={createEditModalShow}
          isNewRecord={isNewRecord}
          formData={formData}
          setFormData={setFormData}
          fetchRolesTableData={fetchRolesTableData}
          setCreateEditModalShow={setCreateEditModalShow}
          onHide={() => setCreateEditModalShow(false)}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={selectedDeleteRowData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Role'}
          ></DeleteModal>
        )}

        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

const CreateEditRolesTableModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const loadPermissionList = async (inputValue, callback) => {
    if (inputValue === '') {
      // callback([]);
      await getDefaultPermissions(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.name, value: res.name });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      await getPermissions(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.name, value: res.name };
        });

        callback(options);
      });
    }
  };

  const onChangePermissions = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      permissions: selectedOptions.map((permission) => permission.value),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };

  const AddSubmitFunct = async (data) => {
    try {
      if (!props.formData.roleName || data.roleName.length > 150 || data.roleDescription.length > 500) {
        setisRequiredError(true);
        return;
      }
      let payload = _.cloneDeep(data);
      payload.permissions = props.formData.permissions?.map((item) => ({
        name: item,
      }));
      payload.contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

      props.isLoading(true);

      await createRolesTable(payload)
        .then((response) => {
          if (response.data) {
            props.fetchRolesTableData();
            props.setCreateEditModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Role created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const EditSubmitFunct = async (data) => {
    if (!props.formData.contextId || !props.formData.roleName || data.roleName.length > 150 || data.roleDescription.length > 500) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);
    payload.permissions = props.formData.permissions?.map((item) => ({
      name: item,
    }));

    props.isLoading(true);
    await updateOneRolesTable(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Role updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchRolesTableData();
        props.setCreateEditModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={[styles.rolesTableModal, 'glbRolesTableModalStyle'].join(' ')}
        onHide={props.onHide}
      >
        <div>
          <Modal.Header>
            <div className={styles.modalHeader}>
              <div>
                <h2 className={styles.modalHeaderTitle}>{props.isNewRecord ? 'Create Role' : 'Edit Role'}</h2>
              </div>
              <div
                className={styles.modalHeaderIcon}
                onClick={() => {
                  props.setCreateEditModalShow(false);
                }}
              >
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className={styles.rolesTableModalBody}>
            <div className={[styles.modalBodySection, 'row mr-0 ml-0'].join(' ')}>
              {props.isNewRecord ? null : (
                <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={!props.isNewRecord}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                </div>
              )}

              <div className={[styles.inputFieldSection, props.isNewRecord ? 'col-12' : 'col-6'].join(' ')}>
                <label>Role Name *</label>
                <input name="roleName" value={props.formData.roleName} type="text" onChange={handleInputChange} />

                {props.formData.roleName.length > 150 && handleMaxLimitError(150)}
                {(props.formData.roleName === null || props.formData.roleName === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
              <div className={[styles.inputFieldSection, 'col-12'].join(' ')}>
                <label>Role Description</label>
                <textarea name="roleDescription" value={props.formData?.roleDescription !== null ? props.formData.roleDescription : ''} onChange={handleInputChange} type="text" />
                {props.formData.roleDescription.length > 500 && handleMaxLimitError(500)}
              </div>

              <div className={[styles.inputFieldSection, 'col-12'].join(' ')}>
                <label>Permissions </label>

                <AsyncSelect
                  isMulti
                  styles={customStyles}
                  isClearable
                  // isDisabled={props.isNew ? false : true}
                  defaultOptions
                  loadOptions={loadPermissionList}
                  // value={props?.formData?.roles[0]?.roleName !== '' ? { label: props?.formData?.roles[0]?.roleName, value: props?.formData?.roles[0]?.roleName } : null}
                  value={props?.formData?.permissions?.map((name) => ({
                    label: name,
                    value: name,
                  }))}
                  onChange={onChangePermissions}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.rolesTableFooter}>
            <div style={{ display: 'flex', gap: '3rem' }} className={'col-12 justify-content-center'}>
              {props.isNewRecord ? (
                <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => AddSubmitFunct(props.formData)} />
              ) : (
                <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => EditSubmitFunct(props.formData)} />
              )}
              <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => props.setCreateEditModalShow(false)} />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
