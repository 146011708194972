import React, { useEffect, useState, useRef, Fragment } from "react";
import { Window } from '@progress/kendo-react-dialogs';
import { useWindowSize } from "../../../../services/helper";
import "./OCRModal.scss"

export default function OCRModal({
    activityURL,
    popupOpen,
    closePopup,
    refreshQueue
}) {
    const url = (activityURL !== "" ? activityURL.replace('10.0.4.133','localhost:5555') : "");
    const iFrameOrigin = "https://localhost:5555";
    const [width, height] = useWindowSize();
    
    useEffect(() => {
        window.addEventListener("message", function (event) {
            let frameToRemove = document.getElementById("OCRFrame");
            if (frameToRemove && event.data === 'close-iframe' && event.origin === iFrameOrigin) {
                console.log('Request to close');
                closePopupActions();
                refreshQueue();
            }
        });
      });
      
    const [zoom, setZoom] = useState(1.0);

    const closePopupActions = () => {
        closePopup();
        setZoom(1.0);
    };

  return (
    popupOpen && (
    <Window 
        style={{ position:"fixed",overFlowY:"hidden"}} 
        className="ocr-window glbModalHeader"
        title={false} 
        modal={true}
        minimizeButton={() => null}
        maximizeButton={() => null}
        closeButton={()=> <button aria-label="Close Detail View Window" style={{backgroundColor:"Transparent", border:"none"}} onClick={()=>closePopupActions()}><b>X</b></button>}
        closeButton={() => null}
        left={width *.43}
        draggable={false} 
        initialTop={0}
        onClose={closePopupActions}
    >
        <iframe 
            id="OCRFrame" 
            style={{width:"100%", height:"100%"}} 
            name="iframe" 
            src={url} 
        />
    </Window>
    )
  );
}
