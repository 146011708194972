import React from 'react';
import styles from './CustomButton.module.scss';
import PropTypes from 'prop-types';

/**
 * CustomButton component for custom button rendering.
 *
 * This component renders a custom-styled button with the specified title and styles, allowing developers to create customized buttons with ease.
 *
 * @component
 */

const CustomButton = ({ className, title, onClick,parentWarpper, ...rest }) => {
  return (
    <div className={[parentWarpper]}>
      <button className={[className, styles.defultBtnStyle].join(' ')} onClick={onClick} {...rest}>
        {title}
      </button>
    </div>
  );
};

export default CustomButton;

CustomButton.propTypes = {
  /**
   * You can give a custom class name to style the button.
   */
  className: PropTypes.string,

  /**
   * The title or text to display on the button.
   * This prop is required, so a title must always be provided.
   */
  title: PropTypes.string.isRequired,

  /**
   * A function to be called when the button is clicked.
   */
  onClick: PropTypes.func,
};
