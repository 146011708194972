import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import ValidatedInput from '../FormModules/ValidatedInput';
import FormTextArea from './FormModules/FormTextArea';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import UserContext from '../UserContext/UserContext';
import { useContext, useState } from 'react';
import { EventEmitter } from '../../services/events';

import axios from 'axios';
import FileCodeStatusRadioGroup from './FormModules/FileCodeStatusRadioGroup';
import VitalRadioGroup from './FormModules/VitalRadioGroup';
import ProgramStatusRadioGroup from './FormModules/ProgramStatusRadioGroup';
import PrimaryOrganizationDropDownList from './FormModules/PrimaryOrganizationDropDownList';
import SchedulingStatusDropDownList from './FormModules/SchedulingStatusDropDownList';
import ScheduleNameDropDownList from './FormModules/ScheduleNameDropDownList';
import SecondaryOrganizationDropDownList from './FormModules/SecondaryOrganizationDropDownList';
import RecordTypeDropDownList from './FormModules/RecordTypeDropDownList';
import { requiredValidator } from '../FormModules/validators';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';

import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';

export default function FileCodeManagementForm(props) {
  // const { EventEmitter } = require('../../services/events');

  const { fileCodeObject, onClose } = props;
  const { userInfo, userPermissions } = useContext(UserContext);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);

  const [loading, setLoading] = useState(false);

  const headers = {
    userName: userInfo.name || '',
    usdaeauthid: userInfo.usdaeauthid || '',
  };

  const onSubmit = async (fileCode) => {
    fileCode.filecodeId =fileCode.filecodeId.trim();
    if (!fileCodeObject) {
      if (fileCode.filecodeId.trimStart() === '' && fileCode.recordtitle.trim() === '') {
        fileCode.filecodeId = '';
        fileCode.recordtitle = '';

        requiredValidator(fileCode.filecodeId);
        requiredValidator(fileCode.recordtitle);

        return;
      }

      if (fileCode.filecodeId.trim() === '') {
        fileCode.filecodeId = '';
        requiredValidator(fileCode.filecodeId);

        return;
      }

      if (fileCode.recordtitle.trim() === '') {
        fileCode.recordtitle = '';
        requiredValidator(fileCode.recordtitle);
        return;
      }
    } else {
      if (fileCode.recordtitle.trim() === '') {
        fileCode.recordtitle = '';
        requiredValidator(fileCode.recordtitle);
        return;
      }
    }

    setLoading(true);
    const data = omitBy(
      {
        ...fileCode,
      },
      isNil
    );

    // update existing file code
    if (fileCodeObject && fileCodeObject.filecodeId) {
      const url = `/recordsmanagementapi/records-management/filecodes/${fileCodeObject.filecodeId}`;
      axios
        .put(url, data, {
          headers: headers,
        })
        .then(() => {
          // addNotification({ style: 'success', msg: 'File Code updated' });
          setMessage('File Code updated successfully');
          setIsSuccessGrowlHidden(false);

          EventEmitter.emit('refreshFileCodeGrid');

          setTimeout(() => {
            onClose();
            setIsSuccessGrowlHidden(true);
            setLoading(false);
          }, 5000);
        })
        .catch((err) => {
          console.error(err);
          setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
          setIsErrorGrowlHidden(false);
          setTimeout(() => {
            setIsErrorGrowlHidden(true);
            setLoading(false);
          }, 5000);
        });
    }
    // create new file code
    else {
      data.schedulestatus = 'Scheduled';
      const url = '/recordsmanagementapi/records-management/filecodes';
      axios
        .post(url, data, {
          headers: headers,
        })
        .then(() => {
          // addNotification({ style: 'success', msg: 'New file code created' });;
          setMessage('New file code created successfully');
          setIsSuccessGrowlHidden(false);

          EventEmitter.emit('refreshFileCodeGrid');

          setTimeout(() => {
            onClose();
            setIsSuccessGrowlHidden(true);
            setLoading(false);
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
          // addNotification({ style: 'error', msg: err.message });
          setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
          setIsErrorGrowlHidden(false);
          setTimeout(() => {
            // onClose();
            setIsErrorGrowlHidden(true);
            setLoading(false);
          }, 3000);
        });
    }
  };

  const initialValues = fileCodeObject || {
    primaryorg: process.env.REACT_APP_CLIENT_NAME,
    recordtype: 'Temporary',
    programstatus: 'Active',
    vital: false,
    filecodestatus: 'Active',
    filecodeName: 'filecodeName',
  };

  return (
    <>
      {loading && <GlobalLoader />}
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={(fieldRenderProps) => {
          return (
            <div>
              <div className="row">
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Primary Organization</label>
                  <Field name={'primaryorg'} className="fsa-input fsa-field__item" component={PrimaryOrganizationDropDownList} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Secondary Organization</label>
                  <Field name={'secondaryorg'} className="fsa-input fsa-field__item" component={SecondaryOrganizationDropDownList} />
                </FieldWrapper>
                {/* <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label" for='filecodeName'>Schedule ID*</label>
                <Field
                  id = {'filecodeName'}
                  name={'filecodeName'}
                  className="fsa-input fsa-field__item"
                  component={ValidatedInput}
                  validator={requiredValidator}
                />
              </FieldWrapper> */}
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-6'}>
                  <label className="fsa-field__label" for="filecodeId">
                    File Code*
                  </label>
                  <Field
                    id={'filecodeId'}
                    disabled={fileCodeObject?.filecodeId ? true : false}
                    name={'filecodeId'}
                    className="fsa-input fsa-field__item"
                    component={ValidatedInput}
                    validator={requiredValidator}
                  />
                </FieldWrapper>
                <FieldWrapper className={'col-6'}>
                  <label className="fsa-field__label" for="recordtitle">
                    Record Title*
                  </label>
                  <Field id={'recordtitle'} name={'recordtitle'} className="fsa-input fsa-field__item" component={ValidatedInput} validator={requiredValidator} />
                </FieldWrapper>
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-6'}>
                  <label className="fsa-field__label" for="primarydescription">
                    Primary Records Description
                  </label>
                  <Field id={'primarydescription'} name={'primarydescription'} className="fsa-input fsa-field__item" component={FormTextArea} />
                </FieldWrapper>
                <FieldWrapper className={'col-6'}>
                  <label className="fsa-field__label" for="secondarydescription">
                    Secondary Records Description
                  </label>
                  <Field id={'secondarydescription'} name={'secondarydescription'} className="fsa-input fsa-field__item" component={FormTextArea} />
                </FieldWrapper>
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label" for="keywords">
                    Keywords
                  </label>
                  <Field id={'keywords'} name={'keywords'} className="fsa-input fsa-field__item" component={FormTextArea} />
                </FieldWrapper>
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Retention Instructions*</label>
                  <Field name={'schedule'} className="fsa-input fsa-field__item" component={ScheduleNameDropDownList} validator={requiredValidator} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Record Type</label>
                  <Field name={'recordtype'} className="fsa-input fsa-field__item" component={RecordTypeDropDownList} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label" for="instructions">
                    Physical Record Instructions
                  </label>
                  <Field id={'instructions'} name={'instructions'} className="fsa-input fsa-field__item" component={FormTextArea} />
                </FieldWrapper>
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-2'}>
                  <label className="fsa-field__label">Vital</label>
                  <Field name={'vital'} className="fsa-input fsa-field__item" component={VitalRadioGroup} />
                </FieldWrapper>
                <FieldWrapper className={'col-5'}>
                  <label className="fsa-field__label" for="systemoforigin">
                    System of Origin
                  </label>
                  <Field id={'systemoforigin'} name={'systemoforigin'} className="fsa-input fsa-field__item" component={Input} />
                </FieldWrapper>
                <FieldWrapper className={'col-5'}>
                  <label className="fsa-field__label" for="actreference">
                    Act Reference
                  </label>
                  <Field id={'actreference'} name={'actreference'} className="fsa-input fsa-field__item" component={Input} />
                </FieldWrapper>
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-3'}>
                  <label className="fsa-field__label" for="dispositionAuthority">
                    Disposition Authority
                  </label>
                  <Field id="dispositionAuthority" name={'dispositionAuthority'} className="fsa-input fsa-field__item" component={ValidatedInput} />
                </FieldWrapper>
                <FieldWrapper className={'col-3'}>
                  <label className="fsa-field__label">Program Status</label>
                  <Field name={'programstatus'} className="fsa-input fsa-field__item" component={ProgramStatusRadioGroup} />
                </FieldWrapper>
                <FieldWrapper className={'col-3'}>
                  <label className="fsa-field__label">File Code Status</label>
                  <Field name={'filecodestatus'} className="fsa-input fsa-field__item" component={FileCodeStatusRadioGroup} />
                </FieldWrapper>
                {/* <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Scheduling Status</label>
                <Field
                  name={'schedulestatus'}
                  className="fsa-input fsa-field__item"
                  component={SchedulingStatusDropDownList}
                />
              </FieldWrapper> */}
              </div>

              <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label" for="filecodeComments">
                    Record Series Comments
                  </label>
                  <Field id={'filecodeComments'} name={'filecodeComments'} className="fsa-input fsa-field__item" component={FormTextArea} />
                </FieldWrapper>
              </div>

              <div className="row mt-5">
                <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                  {!fileCodeObject?.filecodeId && userPermissions['Create_Record_Series'] === true && (
                    <button className="fsa-btn" onClick={fieldRenderProps.onSubmit} disabled={!fieldRenderProps.allowSubmit}>
                      Save
                    </button>
                  )}

                  {fileCodeObject?.filecodeId && userPermissions['Update_Record_Series'] === true && (
                    <button className="fsa-btn" onClick={fieldRenderProps.onSubmit} disabled={!fieldRenderProps.allowSubmit}>
                      Save
                    </button>
                  )}
                  <button onClick={onClose} className="fsa-btn fsa-btn--secondary">
                    Cancel
                  </button>
                </div>
              </div>
              <SuccessProcessGrowl setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} isSuccessGrowlHidden={isSuccessGrowlHidden} message={message} />
              <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
            </div>
          );
        }}
      />
    </>
  );
}
