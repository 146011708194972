import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import './IndexSheetModal.scss';
import styles from './IndexSheetModal.module.scss';
import { useWindowSize } from '../../../../services/helper';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { getDoctype } from '../../../../services/suspenseQueueService';
import crossIcon from '../../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { handleIsRequiredError } from '../../../../utils/helper/handleReuiredErrorFunc';
import { searchPageColumnsAPI } from '../../../../pages/Search/SearchServices';
import moment from 'moment';
import FormDatePicker from '../../../FormModules/FormDatepicker';
import { Field, FieldWrapper } from '@progress/kendo-react-form';
import _ from 'lodash';
import { getDayforceEmpInfoByEmpNumber } from '../../../../services/scanOnDemandService';
import { invalidChars } from '../../../../utils/helper/globalFunc';
import { GlobalLoader } from '../../../../newcomponents/GlobalLoader/GlobalLoader';
import { groupData, sectionData, employmentStatusData, statusReasonData } from '../../../../utils/helper/helperData';

const IndexSheetModalV2 = ({ selectedRows, popupOpen, closePopup, officeId, onSubmit }) => {
  const [width, height] = useWindowSize();
  const [groupList, setGroupList] = useState(
    _.cloneDeep(
      groupData.sort((a, b) => {
        // If the display_name of a is before b, return -1
        if (a.display_name < b.display_name) {
          return -1;
        }
        // If the display_name of a is after b, return 1
        if (a.display_name > b.display_name) {
          return 1;
        }
        // If the display_names are the same, return 0
        return 0;
      })
    )
  );
  const [sectionList, setSectionList] = useState(
    _.cloneDeep(
      sectionData.sort((a, b) => {
        // If the display_name of a is before b, return -1
        if (a.display_name < b.display_name) {
          return -1;
        }
        // If the display_name of a is after b, return 1
        if (a.display_name > b.display_name) {
          return 1;
        }
        // If the display_names are the same, return 0
        return 0;
      })
    )
  );
  const [employmentStatusList, setEmploymentStausList] = useState(
    _.cloneDeep(
      employmentStatusData.sort((a, b) => {
        // If the display_name of a is before b, return -1
        if (a.display_name < b.display_name) {
          return -1;
        }
        // If the display_name of a is after b, return 1
        if (a.display_name > b.display_name) {
          return 1;
        }
        // If the display_names are the same, return 0
        return 0;
      })
    )
  );
  const [statusReasonList, setStatusReasonList] = useState(
    _.cloneDeep(
      statusReasonData.sort((a, b) => {
        // If the display_name of a is before b, return -1
        if (a.display_name < b.display_name) {
          return -1;
        }
        // If the display_name of a is after b, return 1
        if (a.display_name > b.display_name) {
          return 1;
        }
        // If the display_names are the same, return 0
        return 0;
      })
    )
  );
  const [doctype, setDoctype] = useState([]);
  const [sectionFilteredData, setSectionFilteredData] = useState(sectionList.slice());

  const [lookup, setLookup] = useState('');
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [islookupError, setIslookupError] = useState(false);

  const [isRequiredError, setisRequiredError] = useState(false);
  const [sectionSelection, setSectionSelection] = useState(null);
  const [docTypeSelection, setDocTypeSelection] = useState(null);

  const [indexSheetData, setIndexSheetData] = useState({});
  const [pageColumnData, setPageColumnData] = useState([]);
  const [isSubmitActive, IsSubmitActive] = useState(false);

  const defaultItem = {
    display_name: 'Select Sections',
  };

  const defaultItemfordoctype = {
    display_name: 'Select Doctype',
  };
  const sectionFilterChange = (event) => {
    setSectionFilteredData(sectionFilterData(event.filter));
  };

  const sectionFilterData = (inputValue) => {
    return sectionList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };
  const employmentStatusFilter = (inputValue) => {
    return employmentStatusList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };
  const statusReasonFilter = (inputValue) => {
    return statusReasonList.filter((i) => i.value?.includes(inputValue?.toLowerCase()));
  };
  const groupFilterData = (inputValue) => {
    return groupList.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };
  const docTypeFilterData = (inputValue) => {
    return doctype.filter((i) => i.display_name?.toLowerCase().includes(inputValue?.toLowerCase()));
  };

  const sectionDropListChange = (e) => {
    // setSectionSelection(e.target.value);
    let key = 'sections';
    let value = e?.label;
    // setIndexSheetData((values) => ({ ...values, [key]: value }));
    if (e == null)
      return setIndexSheetData((prevData) => ({
        ...prevData,
        [key]: '',
      }));
    // const { value } = e;
    const updatedValue = value === '' ? null : value;
    setIndexSheetData((prevData) => ({
      ...prevData,
      [key]: updatedValue,
    }));
  };

  const onChangeVerNum = (e, verName) => {
    let key = verName;
    let value = e?.label;
    Object.assign(indexSheetData, { [key]: value });
    setisRequiredError(false);
  };

  const onChangeDocType = (e, colName) => {
    // setDocTypeSelection(e.target.value);
    // let key = "doctype";
    // let value = e.target.value.display_name;
    // setIndexSheetData((values) => ({ ...values, [key]: value }));
    // setSectionSelection(e.target.value);
    let key = colName;
    let value = e?.label;

    // setIndexSheetData((values) => ({ ...values, [key]: value }));
    let tempObj = indexSheetData.system_folders;
    Object.assign(tempObj, { [key]: value });

    setIndexSheetData((values) => ({ ...values, ['system_folders']: tempObj }));
  };

  const onChangeDropdownInput = (e, colName) => {
    if (e.label) {
      const { label } = e;
      setIndexSheetData((values) => ({
        ...values,
        [colName]: label,
      }));
    } else {
      setIndexSheetData((values) => ({
        ...values,
        [colName]: '',
      }));
    }
  };
  const [defaultKey, setDefaultKey] = useState(false);

  const filterData = (obj1) => {
    let keys = Object.keys(obj1);
    keys.length !== 0 &&
      keys.map((key) => {
        pageColumnData.length !== 0 &&
          pageColumnData.map((col) => {
            if (col?.name === key && lookup === '') {
              return (indexSheetData.system_folders[key] = obj1[key]);
            } else return (indexSheetData.system_folders[col.name] = selectedRows[0]['IndexData'][col.name]);
          });
      });
    // indexSheetData.system_folders['ObjectType'] = selectedRows.length > 0 ? selectedRows[0].DocTypeName : '';
    let groupsOriginalList = JSON.parse(JSON.stringify(groupData));
    if (!groupsOriginalList.find((e) => e.display_name === indexSheetData.system_folders.Group)) {
      indexSheetData.system_folders.Group = '';
    }
    let sectionOriginalList = JSON.parse(JSON.stringify(sectionList));

    if (!sectionOriginalList.find((e) => e.display_name === indexSheetData.system_folders.Section)) {
      indexSheetData.system_folders.Section = '';
    }
    const newStatusReason = statusReasonList.find((itm) => itm?.value === indexSheetData.statusreason) === undefined ? '' : indexSheetData?.statusreason;

    const newEmploymentStatus =
      employmentStatusList.find((itm) => itm?.display_name?.toLowerCase() === indexSheetData?.employmentstatus?.toLowerCase()) === undefined
        ? ''
        : indexSheetData?.employmentstatus;

    indexSheetData.statusreason = newStatusReason;
    indexSheetData.employmentstatus = newEmploymentStatus;
  };
  useEffect(() => {
    if (selectedRows.length > 0 && !popupOpen) {
      if (selectedRows[0].IndexData) {
        setIslookupError(false);
        setMessage('');
        setLookup('');
        var metadata = selectedRows[0].IndexData;
        setIndexSheetData({
          company: metadata.company,
          division: metadata.division,
          employeename: metadata.employeename,
          employeenumber: metadata.employeenumber,
          employmentyear: metadata.employmentyear,
          filecode: metadata.filecode,
          program: metadata.program,
          first_name: metadata.firstname,
          last_name: metadata.lastname,
          middle_name: metadata.middlename,
          statusstartdate: moment(metadata.statusstartdate, 'DD-MM-YYYY').format('yyyy-MM-DD'),
          term: metadata?.term?.toLowerCase() === 'yes' || metadata?.term?.toLowerCase() === 'true' ? metadata.term : 'No',
          employmentstatus: metadata.employmentstatus,
          statusreason: metadata.statusreason,
          system_folders: {},
          VersionNumber: '1.0',
          object_ocr: metadata.object_ocr,
          document_ocr_id: metadata.document_ocr_id,
        });
      }
    }
  }, [selectedRows]);
  useEffect(() => {
    if (
      indexSheetData !== undefined &&
      indexSheetData !== null &&
      indexSheetData?.system_folders !== undefined &&
      indexSheetData?.system_folders !== null &&
      Object.keys(indexSheetData?.system_folders).length === 0
    ) {
      filterData(selectedRows.length > 0 && selectedRows[0]?.IndexData);
    }
  }, [indexSheetData]);

  useEffect(() => {
    searchPageColumnsAPI().then(async (response) => {
      setPageColumnData(response.data);
    });
  }, []);

  let officeOptions = !Array.isArray(officeId)
    ? [{ value: officeId, label: officeId }]
    : officeId.length > 0 &&
      officeId.map((item) => {
        return { value: item.officeId, label: item.officeId };
      });

  const closePopupActions = () => {
    IsSubmitActive(false);
    closePopup();
  };

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === 'statusstartdate') {
      let formattedDate = moment(value).format('MM/DD/yyyy');
      setIndexSheetData((values) => ({ ...values, [key]: formattedDate }));
    } else {
      setIndexSheetData((values) => ({ ...values, [key]: value }));
    }
  };

  const handleInputChangelookup = (e) => {
    let key = e.target.name;
    let value = e.target.value;

    if (key === 'lookup') {
      setLookup(value);
    }
  };

  const handleColumnData = (e) => {
    let keyName = e.target.name;
    let value = e.target.value;
    // Object.assign(indexSheetData?.system_folders, { [keyName]: value });
    systemKeyValidator = false;
    // console.log(indexSheetData)
    // setIndexSheetData((values) => ({
    //   ...values,
    //     system_folders: {
    //     ...(values.system_folders[keyName] = value),
    //     // ...values.system_folders,
    //   },
    // }));

    let TempObj = indexSheetData?.system_folders;
    Object.assign(TempObj, { [keyName]: value });
    setIndexSheetData((values) => ({ ...values, ['system_folders']: TempObj }));
  };
  let systemKeys = Object.keys(indexSheetData).length !== 0 && Object.keys(indexSheetData?.system_folders).length !== 0 ? Object.keys(indexSheetData?.system_folders) : [];
  let systemKeyValidator = systemKeys?.some(
    (itm, idx) => indexSheetData.system_folders[itm.name] !== 'ObjectType' && (!indexSheetData.system_folders[itm] || indexSheetData.system_folders[itm] === '')
  );

  const handleOnSubmit = (e) => {
    IsSubmitActive(true);
    e.preventDefault();
    let temp = indexSheetData;

    systemKeyValidator = systemKeys?.some((itm, idx) => itm !== 'ObjectType' && (!temp.system_folders[itm] || temp.system_folders[itm] === ''));
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    let sysColumnValidator = systemFolders?.some((itm, idx) => itm.name !== 'ObjectType' && (!temp.system_folders[itm.name] || temp.system_folders[itm.name] === ''));
    // temp.system_folders.Group = 'Emp';
    if (
      temp.employeenumber === '' ||
      temp.employeenumber === undefined ||
      temp.last_name === undefined ||
      temp.first_name === undefined ||
      temp.last_name === '' ||
      temp.first_name === '' ||
      temp.VersionNumber === '' ||
      temp.VersionNumber === undefined ||
      temp.VersionNumber !== '1.0' ||
      systemKeyValidator === true ||
      systemKeys.length === 0 ||
      sysColumnValidator === true

      // systemKeys.includes('ObjectType') === false ||
      // systemKeys.includes('Section') === false ||
      // systemKeys.includes('File Series') === false ||
      // systemKeys.includes('Folder') === false
    ) {
      setisRequiredError(true);
      return;
    } else {
      setisRequiredError(false);
    }

    if (!Object.keys(temp).includes('OfficeSiteID')) {
      temp = { ...temp, OfficeSiteID: officeOptions[0].value };
      setIndexSheetData(temp);
    }

    let payload = _.cloneDeep(temp);
    delete payload.VersionNumber;
    payload.statusstartdate =
      payload.statusstartdate !== null && payload.statusstartdate !== '' && payload.statusstartdate !== 'Invalid date'
        ? moment(payload.statusstartdate).format('YYYY-MM-DDTHH:mm:ss')
        : '';

    onSubmit(payload);
  };

  useEffect(() => {
    const getdoc = async () => {
      try {
        const response = await getDoctype();
        if (Array.isArray(response.data)) {
          const mappedData = response.data.map((item, index) => ({
            id: index + 1,
            display_name: item.objectTypeName,
          }));
          setDoctype(mappedData);
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      } catch (error) {
        console.error('Error in getDoctype:', error.message);
      }
    };
    setisRequiredError(false);
    getdoc();
  }, [popupOpen]);

  const returnRequiredfields = (colData) => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    return systemFolders.map((item) => {
      return colData?.name === item.name && <>*</>;
    });
  };
  const fetchDayforceInfo = async (e) => {
    e.preventDefault();
    setIndexSheetData((values) => ({
      ...values,
      employeename: '',
      employeenumber: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      statusstartdate: '',
      term: '',
      employmentstatus: '',
      statusreason: '',
      system_folders: {
        ...(values.system_folders['Group'] = ''),
        ...(values.system_folders['Folder'] = ''),
        ...(values.system_folders['Section'] = ''),
        ...values.system_folders,
      },
    }));
    setLoading(true);
    await getDayforceEmpInfoByEmpNumber(lookup).then((response) => {
      if (response.data) {
        setIslookupError(false);
        setMessage('Record Updated Successfully!');
        setLoading(false);
        let dayforceData = response.data.Data;
        setDefaultKey(!defaultKey);

        const emp_status = dayforceData?.['EmploymentStatuses']?.['Items']?.[0]?.['EmploymentStatus']?.['XRefCode'].toLowerCase();
        const status_reason = dayforceData?.['EmploymentStatuses']?.['Items']?.[0]?.['EmploymentStatusReason']?.['XRefCode'].toLowerCase();
        const capitalized = capitalizeFirstCharacter(emp_status);
        const status_reason_val = capitalizeFirstCharacter(status_reason);

        setIndexSheetData((values) => ({
          ...values,
          employeename: dayforceData.DisplayName,
          employeenumber: dayforceData.EmployeeNumber,
          first_name: dayforceData.FirstName,
          last_name: dayforceData.LastName,
          middle_name: dayforceData.MiddleName,
          statusstartdate: moment(dayforceData['EmploymentStatuses']?.['Items']?.[0]?.['EffectiveStart']).format('MM/DD/yyyy'),
          term:
            dayforceData['EmploymentStatuses']['Items'][0]['EmploymentStatusReason'] &&
            dayforceData['EmploymentStatuses']['Items'][0]['EmploymentStatusReason']['IsTerminationReason']
              ? 'Yes'
              : 'No',
          employmentstatus: capitalized,
          statusreason: status_reason_val,
          // statusreason: dayforceData?.['EmploymentStatuses']?.['Items']?.[0]?.['EmploymentStatusReason']?.['XRefCode'],
          system_folders: {
            ...values.system_folders,
            Group: '',
            Folder:
              (dayforceData.LastName !== '' && dayforceData.LastName !== undefined ? dayforceData.LastName + ', ' : '') +
              (dayforceData.FirstName !== '' && dayforceData.FirstName !== undefined ? dayforceData.FirstName + ', ' : '') +
              (dayforceData.MiddleName !== '' && dayforceData.MiddleName !== undefined ? dayforceData.MiddleName + ', ' : '') +
              (dayforceData.EmployeeNumber !== '' && dayforceData.EmployeeNumber),
          },
        }));
      } else if (response.error) {
        setIslookupError(true);
        setLoading(false);
        setMessage('No Record Found');
        setIndexSheetData((values) => ({
          ...values,
          employeename: '',
          employeenumber: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          statusstartdate: '',
          term: '',
          employmentstatus: '',
          statusreason: '',
          system_folders: {
            ...values.system_folders,
            ...(values.system_folders['Group'] = ''),
            ...(values.system_folders['Folder'] = ''),
            ...(values.system_folders['Section'] = ''),
          },
        }));
      }
    });
  };

  return (
    <>
      <Modal
        show={popupOpen}
        className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
        backdropClassName="orc-modal-custom-backdrop"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Index Sheet Metadata</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                closePopupActions();
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {Loading && <GlobalLoader />}
          <form onSubmit={handleOnSubmit}>
            <div style={{ margin: '0 2em ' }}>
              <div
                className="fsa-grid"
                style={{
                  marginTop: '1px',
                }}
              >
                <div className="fsa-grid__1/1">
                  <div style={{ fontWeight: 'bold', color: `rgba(33, 37, 41,0.5) ` }}>This index sheet metadata will be applied to all selected files.</div>
                  <div style={{ marginBottom: '1em', fontWeight: 'bold', color: `rgba(33, 37, 41,0.5) ` }}>
                    SMCUSAHR INDEX METADATA:<b style={{ color: '#000' }}> Version 1.0</b>
                  </div>
                  <div style={{ marginBottom: '1em' }}></div>
                </div>
              </div>
              <div
                className="fsa-grid"
                style={{
                  marginTop: '5px',
                }}
              >
                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor="lookup">
                      <b>Lookup</b>
                    </label>
                    <div className="d-flex">
                      <input
                        className="fsa-input fsa-field__item"
                        id="lookup"
                        name="lookup"
                        aria-describedby="lookup"
                        aria-required="false"
                        value={lookup}
                        type="number"
                        placeholder="Enter Employee Number"
                        onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()}
                        pattern="^(0|[1-9][0-9]*)$"
                        onChange={handleInputChangelookup}
                      />
                      <button className="fsa-btn fsa-btn--primary m-0" disabled={lookup.length < 6} onClick={fetchDayforceInfo}>
                        Retrieve
                      </button>
                    </div>
                    {message && <span className={islookupError ? 'validation-message' : 'success-message'}>{message}</span>}
                  </div>
                </div>
                <div className="fsa-grid__1/2"></div>
                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor="employeenumber">
                      <b>Employee Number *</b>
                    </label>
                    <input
                      className="fsa-input fsa-field__item"
                      id="employeenumber"
                      name="employeenumber"
                      aria-describedby="employeenumber"
                      aria-required="false"
                      value={indexSheetData.employeenumber}
                      type="text"
                      placeholder="employee number"
                      onChange={handleInputChange}
                    />
                    {(indexSheetData.employeenumber === undefined || indexSheetData.employeenumber === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </div>

                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor="last_name">
                      Last Name *
                    </label>
                    <input
                      className="fsa-input fsa-field__item"
                      id="last_name"
                      name="last_name"
                      aria-describedby="last_name"
                      aria-required="false"
                      type="text"
                      value={indexSheetData.last_name}
                      placeholder="last name"
                      onChange={handleInputChange}
                    />
                    {(indexSheetData.last_name === undefined || indexSheetData.last_name === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </div>
              </div>
              <div
                className="fsa-grid"
                style={{
                  marginTop: '5px',
                }}
              >
                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor="first_name">
                      First Name *
                    </label>
                    <input
                      className="fsa-input fsa-field__item"
                      id="first_name"
                      name="first_name"
                      aria-describedby="first_name"
                      aria-required="false"
                      type="text"
                      value={indexSheetData.first_name}
                      placeholder="first name"
                      onChange={handleInputChange}
                    />
                    {(indexSheetData.first_name === undefined || indexSheetData.first_name === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </div>

                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor="middle_name">
                      Middle Name
                    </label>
                    <input
                      className="fsa-input fsa-field__item"
                      id="middle_name"
                      name="middle_name"
                      aria-describedby="middle_name"
                      aria-required="false"
                      type="text"
                      value={indexSheetData.middle_name}
                      placeholder="middle name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {pageColumnData?.map((colData, idx) => {
                  return (
                    colData?.name !== 'ObjectType' && (
                      <div className="fsa-grid__1/2" key={`col_name_${idx}_`}>
                        <div className="fsa-field">
                          <label className="fsa-field__label" htmlFor={colData?.name}>
                            {colData?.name}
                            {returnRequiredfields(colData)}
                          </label>
                          {colData?.lowCard === false ? (
                            <>
                              {colData?.name === 'Group' ? (
                                <>
                                  <AsyncSelect
                                    isClearable
                                    defaultOptions
                                    value={
                                      Object.keys(indexSheetData).length !== 0 &&
                                      Object.keys(indexSheetData?.system_folders).length !== 0 && {
                                        label: indexSheetData?.system_folders[colData?.name] ? indexSheetData?.system_folders[colData?.name] : '',
                                      }
                                    }
                                    loadOptions={(inputValue, callback) => {
                                      if (inputValue === '') {
                                        let options = [];
                                        groupList.map((res) => {
                                          options.push({ label: res.display_name, value: res.id });
                                          setTimeout(() => {
                                            callback(options);
                                          }, 1000);
                                        });
                                        callback(options);
                                      } else {
                                        let options = [];
                                        groupFilterData(inputValue).map((res) => {
                                          options.push({ label: res.display_name, value: res.id });
                                          setTimeout(() => {
                                            callback(options);
                                          }, 1000);
                                        });
                                        callback(options);
                                      }
                                    }}
                                    onChange={(e) => {
                                      onChangeDocType(e, colData?.name);
                                    }}
                                  />
                                  {indexSheetData?.system_folders !== undefined
                                    ? Object.keys(indexSheetData?.system_folders).length === 0 && isRequiredError === true
                                      ? handleIsRequiredError()
                                      : (indexSheetData?.system_folders[colData?.name] === '' || indexSheetData?.system_folders[colData?.name] === undefined) &&
                                        isRequiredError === true &&
                                        handleIsRequiredError()
                                    : handleIsRequiredError()}
                                </>
                              ) : (
                                <>
                                  <input
                                    className="fsa-input fsa-field__item"
                                    id={colData?.name}
                                    name={colData?.name}
                                    aria-describedby={colData?.name}
                                    aria-required="false"
                                    type="text"
                                    defaultValue={indexSheetData?.system_folders !== undefined && indexSheetData?.system_folders[colData.name]}
                                    key={defaultKey}
                                    placeholder=""
                                    onKeyUp={(e) => {
                                      if (e.key === 'Backspace' || e.key === 'Delete') {
                                        handleColumnData(e);
                                      } else if (e.target.value === '') {
                                        handleColumnData(e);
                                      }
                                    }}
                                    onChange={handleColumnData}
                                  />

                                  {indexSheetData?.system_folders !== undefined
                                    ? Object.keys(indexSheetData?.system_folders).length === 0 && isRequiredError === true
                                      ? handleIsRequiredError()
                                      : (indexSheetData?.system_folders[colData?.name] === '' || indexSheetData?.system_folders[colData?.name] === undefined) &&
                                        isRequiredError === true &&
                                        handleIsRequiredError()
                                    : handleIsRequiredError()}
                                </>
                              )}
                            </>
                          ) : colData?.name === 'ObjectType' ? null : (
                            // <>
                            //   <AsyncSelect
                            //     isClearable
                            //     defaultOptions
                            //     name={colData?.name}
                            //     value={
                            //       Object.keys(indexSheetData).length !== 0 &&
                            //       Object.keys(indexSheetData?.system_folders).length !== 0 && { label: indexSheetData?.system_folders[colData?.name] }
                            //     }
                            //     loadOptions={(inputValue, callback) => {
                            //       if (inputValue === '') {
                            //         let options = [];
                            //         doctype.map((res) => {
                            //           options.push({ label: res.display_name, value: res.id });
                            //           setTimeout(() => {
                            //             callback(options);
                            //           }, 1000);
                            //         });
                            //         callback(options);
                            //       } else {
                            //         let options = [];
                            //         docTypeFilterData(inputValue).map((res) => {
                            //           options.push({ label: res.display_name, value: res.id });
                            //           setTimeout(() => {
                            //             callback(options);
                            //           }, 1000);
                            //         });
                            //         callback(options);
                            //       }
                            //     }}
                            //     onChange={(e) => {
                            //       onChangeDocType(e, colData?.name);
                            //     }}
                            //   ></AsyncSelect>
                            //   {systemKeys.includes(colData?.name) === false && isRequiredError === true && handleIsRequiredError()}
                            // </>
                            <>
                              <AsyncSelect
                                isClearable
                                defaultOptions
                                value={
                                  Object.keys(indexSheetData).length !== 0 &&
                                  Object.keys(indexSheetData?.system_folders).length !== 0 && { label: indexSheetData?.system_folders[colData?.name] }
                                }
                                loadOptions={(inputValue, callback) => {
                                  if (inputValue === '') {
                                    let options = [];
                                    sectionList.map((res) => {
                                      options.push({ label: res.display_name, value: res.id });
                                      setTimeout(() => {
                                        callback(options);
                                      }, 1000);
                                    });
                                    callback(options);
                                  } else {
                                    let options = [];
                                    sectionFilterData(inputValue).map((res) => {
                                      options.push({ label: res.display_name, value: res.id });
                                      setTimeout(() => {
                                        callback(options);
                                      }, 1000);
                                    });
                                    callback(options);
                                  }
                                }}
                                onChange={(e) => {
                                  onChangeDocType(e, colData?.name);
                                }}
                              />
                              {indexSheetData?.system_folders !== undefined
                                ? Object.keys(indexSheetData?.system_folders).length === 0 && isRequiredError === true
                                  ? handleIsRequiredError()
                                  : (indexSheetData?.system_folders[colData.name] === '' || indexSheetData?.system_folders[colData.name] === undefined) &&
                                    isRequiredError === true &&
                                    handleIsRequiredError()
                                : handleIsRequiredError()}
                            </>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
                {/* <div className="fsa-grid__1/2">
                <div className="fsa-field">
                  <label className="fsa-field__label" htmlFor="sections">
                    <b>ObjectType *</b>
                    </label> */}
                {/* <DropDownList
                    data={sectionFilteredData}
                    filterable={true}
                    defaultItem={defaultItem}
                    textField="display_name"
                    dataItemKey="id"
                    value={sectionSelection}
                    onFilterChange={sectionFilterChange}
                    onChange={sectionDropListChange}
                  /> */}
                {/* <AsyncSelect
                      isClearable
                      defaultOptions
                      value={{label:indexSheetData.doctype}}
                      loadOptions={(inputValue,callback) => {
                        if (inputValue === '') {
                          let options = []
                          doctype.map((res) => {
                              options.push({ label: res.display_name, value: res.id });
                              setTimeout(() => {
                                callback(options);
                              }, 1000);
                          });
                          callback(options);
                        } else {
                          let options = []
                            docTypeFilterData(inputValue).map((res) => {
                              options.push({ label: res.display_name, value: res.id });
                              setTimeout(() => {
                                callback(options);
                              }, 1000);
                          });
                          callback(options);
                        }
                      }}
                      onChange={onChangeDocType}
                    />

              {indexSheetData.doctype === "" && isRequiredError===true && handleIsRequiredError()}
                </div>
              </div> */}

                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor="sections">
                      <b>Status Start Date </b>
                    </label>
                    <input
                      name="statusstartdate"
                      value={indexSheetData.statusstartdate !== '' && indexSheetData.statusstartdate !== undefined && moment(indexSheetData.statusstartdate).format('yyyy-MM-DD')}
                      className="fsa-input fsa-field__item"
                      type="date"
                      onChange={handleInputChange}
                    />
                    {/* {(indexSheetData.termdate === "" || indexSheetData.termdate === undefined)&& isRequiredError===true && handleIsRequiredError()} */}
                  </div>
                </div>
                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor={'Emp status'}>
                      Employment Status
                    </label>
                    <AsyncSelect
                      isClearable
                      defaultOptions
                      value={Object.keys(indexSheetData).length !== 0 && { label: indexSheetData.employmentstatus ? indexSheetData.employmentstatus : 'Select Status' }}
                      loadOptions={(inputValue, callback) => {
                        if (inputValue === '') {
                          let options = [];
                          employmentStatusList.map((res) => {
                            options.push({ label: res.display_name, value: res.id });
                            setTimeout(() => {
                              callback(options);
                            }, 1000);
                          });
                          callback(options);
                        } else {
                          let options = [];
                          employmentStatusFilter(inputValue).map((res) => {
                            options.push({ label: res.display_name, value: res.id });
                            setTimeout(() => {
                              callback(options);
                            }, 1000);
                          });
                          callback(options);
                        }
                      }}
                      onChange={(e) => {
                        onChangeDropdownInput(e, 'employmentstatus');
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }} className="fsa-grid__1/2">
                  <div className="" style={{ display: 'flex', alignItems: 'center ', width: '50%' }}>
                    <label style={{ width: '50%', margin: '0' }} htmlFor="sections">
                      <b>Term: </b>
                    </label>
                    <div className="fsa-field__item" style={{ display: 'flex', alignItems: 'center ', justifyContent: 'space-evenly' }}>
                      <input
                        type="radio"
                        name="term"
                        value={'Yes'}
                        checked={indexSheetData?.term && indexSheetData?.term?.toLowerCase() === 'yes' ? true : false}
                        onChange={handleInputChange}
                      />
                      Yes
                      <input
                        type="radio"
                        name="term"
                        value={'No'}
                        checked={indexSheetData?.term && indexSheetData?.term?.toLowerCase() === 'no' ? true : false}
                        onChange={handleInputChange}
                      />{' '}
                      No
                    </div>
                  </div>
                </div>

                <div className="fsa-grid__1/2">
                  <div className="fsa-field">
                    <label className="fsa-field__label" htmlFor={'status reason'}>
                      Status Reason
                    </label>

                    <AsyncSelect
                      isClearable
                      defaultOptions
                      value={Object.keys(indexSheetData).length !== 0 && { label: indexSheetData.statusreason ? indexSheetData.statusreason : 'Select Reason' }}
                      loadOptions={(inputValue, callback) => {
                        if (inputValue === '') {
                          let options = [];
                          statusReasonList.map((res) => {
                            options.push({ label: res.display_name, value: res.value });
                            setTimeout(() => {
                              callback(options);
                            }, 1000);
                          });
                          callback(options);
                        } else {
                          let options = [];
                          statusReasonFilter(inputValue).map((res) => {
                            options.push({ label: res.display_name, value: res.value });
                            setTimeout(() => {
                              callback(options);
                            }, 1000);
                          });
                          callback(options);
                        }
                      }}
                      onChange={(e) => {
                        onChangeDropdownInput(e, 'statusreason');
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }} className="fsa-grid__1/2">
                  <div className="fsa-field" style={{ width: '100%' }}>
                    <label style={{ width: '50%', margin: '0' }} htmlFor="sections">
                      <b>Version Number: </b>
                    </label>
                    <div className="fsa-field__item">
                      <AsyncSelect
                        defaultOptions
                        value={{ label: indexSheetData?.VersionNumber }}
                        // loadOptions={(inputValue, callback) => {
                        //   if (inputValue === '') {
                        //     let options = [];
                        //     let VList = ['0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1.0'];
                        //     VList.map((res) => {
                        //       console.log(res);
                        //       options.push({ label: res, value: res });
                        //       setTimeout(() => {
                        //         callback(options);
                        //       }, 1000);
                        //     });
                        //     callback(options);
                        //   } else {
                        //     let options = [];
                        //     let VList = ['0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1.0'];
                        //     VList.map((res) => {
                        //       console.log(res);
                        //       options.push({ label: res, value: res });
                        //       setTimeout(() => {
                        //         callback(options);
                        //       }, 1000);
                        //     });
                        //     callback(options);
                        //   }
                        // }}
                        onChange={(e) => {
                          onChangeVerNum(e, 'VersionNumber');
                        }}
                      />
                      {indexSheetData?.VersionNumber !== undefined || (indexSheetData?.VersionNumber !== '' && isRequiredError === true && handleIsRequiredError())}
                      {indexSheetData?.VersionNumber !== '1.0' && isRequiredError === true && <span className={'requiredErrorMsgStyle'}> "SMCUSAHR version should be 1.0"</span>}
                    </div>
                  </div>
                </div>
              </div>
              <Modal.Footer style={{ padding: '20px 0' }}>
                <input type="submit" style={{ marginLeft: 'auto' }} className="fsa-btn fsa-btn--primary" value={'Submit'} />
                <button type="reset" style={{ marginRight: 'auto' }} className="fsa-btn fsa-btn--secondary" onClick={closePopupActions}>
                  Cancel
                </button>
              </Modal.Footer>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function capitalizeFirstCharacter(str) {
  if (str.length === 0) return str; // Return an empty string if the input is empty
  const [firstChar, ...rest] = str;
  return firstChar.toUpperCase() + rest.join('');
}
export default IndexSheetModalV2;
