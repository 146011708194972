import React from 'react';

export default function ErrorGrowl({ isErrorGrowlHidden, setIsErrorGrowlHidden, message }) {
  const closeGrowl = () => {
    setIsErrorGrowlHidden(true);
  };

  return (
    <div className="fsa-growl-container" style={{ zIndex: 99999999999 }}>
      <div className="fsa-growl fsa-growl--error" id="UNIQUE-ID-832353AD65DB511A" aria-hidden={isErrorGrowlHidden} tabIndex="0" role="dialog">
        <div className="fsa-growl__hd">
          <button
            className="fsa-growl__close"
            data-behavior="growl-dismiss"
            onClick={() => closeGrowl}
            type="button"
            title="Close Notification"
            aria-label="Close Notification"
          ></button>
        </div>
        <div className="fsa-growl__bd">
          <b>
            <p>{message} </p>
          </b>
        </div>
      </div>
    </div>
  );
}
