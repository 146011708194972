import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import './Orchestration.scss';
import styles from './Orchestration.module.scss';
import '../../../GlobalStyle.scss';

import TableOrchestration from './TableOrchestration';
import OrchestrationData from './OrchestrationData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import Footer from '../../../newcomponents/Footer/Footer';
import { searchWorkgroups } from '../../../services/inBasketService';
import AsyncSelect from 'react-select/async';
import {
  createRoutingOrchestration,
  updateOneRoutingOrchestration,
  deleteRoutingOrchestration,
  searchRoutingOrchestration,
  getWorkgroupDisplayNameByID,
} from './OrchestrationService';
import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Routing Orchestration', link: '' },
];
export default function Orchestration() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [orchestrationDetails, setOrchestrationData] = useState(OrchestrationData);

  const [formData, setFormData] = useState({
    activeInd: true,
    buowName: '',
    calculatedContentId: '',
    contextId: '',
    object: '',
    parSeq: '',
    step: '',
    stepName: '',
    targetUserOrWorkgroup: '',
    userOrWorkgroupId: '',
    id: '',
  });

  async function fetchRoutingOrchestrationData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchRoutingOrchestrationData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      buowName: '',
      calculatedContentId: '',
      contextId: '',
      object: '',
      parSeq: '',
      step: '',
      stepName: '',
      targetUserOrWorkgroup: '',
      userOrWorkgroupId: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    if (data.userOrWorkgroupId !== '' && data.userOrWorkgroupId !== null) {
      if (data.userOrWorkgroupId !== '' && !data.userOrWorkgroupId.includes('user')) {
        setLoading(true);

        await getWorkgroupDisplayNameByID(data.userOrWorkgroupId).then((response) => {
          setFormData({
            activeInd: data.activeInd,
            buowName: data.buowName,
            calculatedContentId: data.calculatedContentId,
            contextId: data.contextId,
            object: data.object,
            parSeq: data.parSeq,
            step: data.step,
            stepName: data.stepName,
            targetUserOrWorkgroup: data.targetUserOrWorkgroup,
            userOrWorkgroupId: { label: response.data.workgroupDisplayName, value: response.data.workgroupId },
            id: data.id,
            // userOrWorkgroupId: { label: data.workGroup.workgroupDisplayName, value: data.userOrWorkgroupId },
          });
          setLoading(false);
        });
      } else {
        setFormData({
          activeInd: data.activeInd,
          buowName: data.buowName,
          calculatedContentId: data.calculatedContentId,
          contextId: data.contextId,
          object: data.object,
          parSeq: data.parSeq,
          step: data.step,
          stepName: data.stepName,
          targetUserOrWorkgroup: data.targetUserOrWorkgroup,
          userOrWorkgroupId: { label: data.userOrWorkgroupId, value: data.userOrWorkgroupId },
          id: data.id,
        });
      }
    } else {
      setFormData({
        activeInd: data.activeInd,
        buowName: data.buowName,
        calculatedContentId: data.calculatedContentId,
        contextId: data.contextId,
        object: data.object,
        parSeq: data.parSeq,
        step: data.step,
        stepName: data.stepName,
        targetUserOrWorkgroup: data.targetUserOrWorkgroup,
        userOrWorkgroupId: null,
        id: data.id,
      });
    }

    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewRoutingOrchestrationData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(orchestrationDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setOrchestrationData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewRoutingOrchestrationData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
    if (value.length > 2) onSearch(value);
    if (value.length === 0) onSearch(value);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };
    deleteRoutingOrchestration(sendData).then(async (response) => {
      if (response) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Record deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div className={styles.orchestrationPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div className={styles.orchestrationPageContent}>
        <h1 className={styles.pageTitle}>Routing Orchestration</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent searchText={searchKeyword} handleSearch={handleSearch} onSearch={onSearch} clearSearch={clearSearch}></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Record" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
          </div>
        </div>
        <div className={[styles.orchestrationTable, 'glbOrchestrationTable'].join(' ')}>
          <TableOrchestration
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={orchestrationDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditRoutingOrchModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchRoutingOrchestrationData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Delete Orchestration'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditRoutingOrchModal = (props) => {
  // const [searchData, setSearchData] = useState([]);
  const [isRequiredError, setisRequiredError] = useState(false);
  // const [searchDataBUOW, setSearchDataBUOW] = useState([]);

  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      // width: '222px',
    }),
    menu: (base) => ({
      ...base,
      // width: '222px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      // minWidth: '222px',
    }),
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchData([]);
    } else {
      await searchWorkgroups(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  const handleDropdownChange = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        userOrWorkgroupId: '',
      }));
    props.setFormData((prevData) => ({
      ...prevData,
      userOrWorkgroupId: event,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  const handleSubmit = async (data) => {
    if (!data.contextId || !data.buowName || !data.object || !data.step || data.contextId.length > 50 || data.buowName.length > 35) setisRequiredError(true);
    else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);
      if (payload.userOrWorkgroupId !== '' && payload.userOrWorkgroupId !== null) payload.userOrWorkgroupId = payload.userOrWorkgroupId.value;
      await createRoutingOrchestration(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Record created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchRoutingOrchestrationData();
          props.setRecordModalShow(false);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.contextId || !data.buowName || !data.object || data.step == null || data.contextId.length > 50 || data.buowName.length > 35) setisRequiredError(true);
    else {
      let payload = _.cloneDeep(data);
      if (payload.userOrWorkgroupId !== '' && payload.userOrWorkgroupId !== null) payload.userOrWorkgroupId = payload.userOrWorkgroupId.value;
      props.isLoading(true);

      await updateOneRoutingOrchestration(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Record updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchRoutingOrchestrationData();
          props.setRecordModalShow(false);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  return (
    <Modal
      {...props}
      className={[styles.orchestrationModal, 'glbOrchestrationModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Record' : 'Edit Record'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.orchestrationModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Context Id *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadContextIdList}
                  value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                  onChange={onChangeContextId}
                />
                {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Name *</label>
                <input required name="buowName" value={props.formData.buowName} onChange={handleInputChange} disabled={!props.isNew} />
                {(props.formData.buowName === null || props.formData.buowName === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.buowName.length > 35 && isRequiredError === true && handleMaxLimitError(35)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object *</label>
                <input name="object" required value={props.formData.object} onChange={handleInputChange} disabled={!props.isNew} />
                {(props.formData.object === null || props.formData.object === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Step *</label>
                <input name="step" required value={props.formData.step} onChange={handleInputChange} type="number" disabled={!props.isNew} />
                {(props.formData.step === null || props.formData.step === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Step Name</label>
                <input name="stepName" value={props.formData.stepName} onChange={handleInputChange} />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Parallel or Sequence</label>
                <select className={styles.dropdownStyle} id="selectField" name="parSeq" value={props.formData.parSeq} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Mode--
                  </option>
                  <option value="Parallel">Parallel</option>
                  <option value="Sequential">Sequential</option>
                </select>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Target User or Workgroup</label>
                <select className={styles.dropdownStyle} id="selectField" name="targetUserOrWorkgroup" value={props.formData.targetUserOrWorkgroup} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Mode--
                  </option>
                  <option value="U">U</option>
                  <option value="W">W</option>
                  <option value="C">C</option>
                </select>
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Calculated Content ID</label>
                <input name="calculatedContentId" value={props.formData.calculatedContentId} onChange={handleInputChange} />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>User or Workgroup</label>
                <AsyncSelect styles={customStyles} isClearable loadOptions={loadOptions} value={props?.formData?.userOrWorkgroupId} onChange={handleDropdownChange} />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv} style={{ display: 'none' }}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => props.setRecordModalShow(false)} />

            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
