import React, { useEffect, useState, useRef, Fragment } from "react";
import { Dialog } from '@progress/kendo-react-dialogs';
import "./InformationModal.scss"
import jobDescription from '../../../SuspenseQueueList/SuspenseJobsDescription.json';

export default function InformationModal({
    jobId,
    jobStatus,
    jobStatusNote,
    fileName,
    jobCreationDate,
    uploadingUser,
    popupOpen,
    closePopup,
    refreshQueue,
    resubmit
}) {
    const job = jobDescription.filter(job => job.jobStatus === jobStatus)[0];
    
    const [zoom, setZoom] = useState(1.0);
    const closePopupActions = () => {
        closePopup();
        setZoom(1.0);
    };

    const handleRefreshButtonClicked = () => {
        refreshQueue();
        closePopupActions();
    }

    const handleResubmitButtonClicked = () => {
        resubmit(jobId).then(
        refreshQueue(),
        closePopupActions()
        );
    }

    return (
        popupOpen && (
        <>
            <Dialog
                className="information-window"
                // minimizeButton={() => null}
                // maximizeButton={() => null}
                title={<span title={jobStatus.includes("Error") ? "Job Status: " + jobStatus : fileName}>{jobStatus.includes("Error") ? "Job Status: " + jobStatus : fileName}</span>} 
                modal={true} 
                draggable={false} 
                initialTop={0}
                onClose={closePopupActions}
                aria-label="Information Modal"
            >
                {(
                    jobStatus.includes("Error") ? 
                        <div 
                            className="information-body"
                            style={{textAlign:"center"}}
                        >
                            <div>
                                <span style={{marginRight:"15px"}}>
                                    <p>If you are seeing this message then something has gone wrong on our end.<br/>
                                    Please <b>refresh the Suspense Queue.</b> </p>
                                </span>
                            </div>

                            <div style={{marginTop:"10px"}}>
                                <span >
                                    <p>If this status continues to persist then <b>contact support at:</b> <a href="mailto:support.info@email.com">support.info@email.com</a> </p>
                                </span>
                            </div>

                            <button 
                                style={{marginTop:"2em"}} 
                                className="fsa-btn fsa-btn--secondary float-right"
                                onClick={closePopupActions}
                            > 
                                Cancel
                            </button>

                            <button 
                                style={{marginTop:"2em", marginRight:"10px"}} 
                                className="fsa-btn fsa-btn--primary float-right"
                                onClick={handleRefreshButtonClicked}
                            > 
                                Refresh
                            </button>
                        </div> 
                    : jobStatus === "Object Unclassified" ?
                        <div 
                            className="information-body"
                            style={{textAlign:"center"}}
                        >
                            <div>
                                <span style={{marginRight:"15px"}}>
                                    <p>{jobStatusNote ? jobStatusNote : job.description} </p>
                                </span>
                            </div>

                            <div style={{marginTop:"10px"}}>
                                <span >
                                    <p>If a form definition has been created for this object, please click the <b>Resubmit</b> button to attempt to reclassify within the OCR application. </p>
                                </span>
                            </div>

                            <button 
                                style={{marginTop:"2em"}} 
                                className="fsa-btn fsa-btn--secondary float-right"
                                onClick={closePopupActions}
                            > 
                                Cancel
                            </button>

                            <button 
                                style={{marginTop:"2em", marginRight:"10px"}} 
                                className="fsa-btn fsa-btn--primary float-right"
                                onClick={handleResubmitButtonClicked}
                            > 
                                Resubmit
                            </button>
                        </div> 
                    :
                        <div 
                            className="information-body"
                        >
                            <div>
                                <span style={{marginRight:"15px"}}>
                                    <b>Job Status:</b> {jobStatus} 
                                </span>
                                <span style={{marginRight:"15px"}}>
                                    <b>Job Creation Date:</b> {jobCreationDate} 
                                </span>
                                <span>
                                    <b>Uploading User:</b> {uploadingUser} 
                                </span>
                            </div>

                            <div style={{marginTop:"5px"}}>
                                <span >
                                    <b>Description:</b> {jobStatusNote ? jobStatusNote : job.description}
                                </span>
                            </div>

                            <div style={{marginTop:"5px"}}>
                                <p>
                                    This is a temporary status while the OCR application performs its tasks. 
                                    Feel free to click the <b>Refresh</b> button to get the latest job status for your queue.
                                </p>
                            </div>

                            <button 
                                style={{marginTop:"2em"}} 
                                className="fsa-btn fsa-btn--secondary float-right"
                                onClick={closePopupActions}
                            > 
                                Cancel
                            </button>

                            <button 
                                style={{marginTop:"2em", marginRight:"10px"}} 
                                className="fsa-btn fsa-btn--primary float-right"
                                onClick={handleRefreshButtonClicked}
                            > 
                                Refresh
                            </button>
                        </div> 
                )}
            </Dialog>
        </> 
        )
    );
}
