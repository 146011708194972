import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './SearchFolderOptionsTable.module.scss';
import '../../../GlobalStyle.scss';
import TableUsersTable from './TableSearchOptions';
import SearchTablesData from './SearchFolder.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { createSearchOptionsFolderTable, deleteSearchFolder, searchForOptionsServicesTable, updateSearchFolderTable, getRoles } from './SearchOptionsService';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';
//Resusable Components Start
import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { Switch } from '@progress/kendo-react-inputs';
// import Footer from '../../../newcomponents/Footer/Footer';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';

//Resusable Components End

var _ = require('lodash');
const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'System Folder', link: '' },
];

export default function SearchFolderTable() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: userInfoContext,
    systemFolderLevel: null,
    name: '',
    lowCard: false,
    description: null,
    isVisible: null,
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [searchTableDetails, setSearchTableDetails] = useState(SearchTablesData);

  async function fetchSearchOptionsData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForOptionsServicesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    fetchSearchOptionsData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchForOptionsServicesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      systemFolderLevel: null,
      name: '',
      lowCard: false,
      description: null,
      isVisible: null,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      userId: DeleteData.id,
    };
    deleteSearchFolder(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchSearchOptionsData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('System Folder deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    console.log(data);
    setFormData({
      contextId: userInfoContext,
      systemFolderLevel: data.systemFolderLevel,
      name: data.name,
      lowCard: data.lowCard,
      description: data.description,
      id: data.id,
      isVisible: data.isVisible,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForOptionsServicesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUsersTableData = JSON.parse(JSON.stringify(searchTableDetails));
    newUsersTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setSearchTableDetails(newUsersTableData);
    await fetchNewUsersTableData();
  };

  // Code needs to change
  const fetchNewUsersTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchForOptionsServicesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchForOptionsServicesTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.usersTablePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.usersTablePageContent}>
        <h1 className={styles.pageTitle}>System Folder</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent searchText={searchText} handleSearch={handleSearch} onSearch={onSearch} clearSearch={clearSearch}></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create System Folder" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.usersTableTable, 'glbUsersTableTable'].join(' ')}>
          <TableUsersTable
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={searchTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditUsersTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchSearchOptionsData={fetchSearchOptionsData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle="System Folder"
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditUsersTable = (props) => {
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: userInfoContext,
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };
  const onChangeSystemFolder = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: removeSpace.trimStart(),
    }));
  };

  const [isRequiredError, setisRequiredError] = useState(false);
  useEffect(() => {
    setisRequiredError(false);
    setPasswordVisible(false);
  }, [props.formData, props.isNew]);

  const handleInputName = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: name !== 'description' ? (name === 'systemFolderLevel' ? parseInt(removeSpace.trimStart()) : value.trimStart()) : value.trimStart(),
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const handleSubmit = async () => {
    console.log(props.formData, 'props.formData');
    try {
      if (
        !props.formData.name ||
        props.formData.description?.length > 150 ||
        props.formData.name?.length > 50 ||
        props.formData.systemFolderLevel === null ||
        props.formData.systemFolderLevel === '' ||
        isNaN(props.formData.systemFolderLevel) === true ||
        props.formData.systemFolderLevel >= 10 ||
        props.formData.systemFolderLevel <= 1
      ) {
        setisRequiredError(true);
        return;
      }

      let payload = _.cloneDeep(props.formData);
      props.isLoading(true);
      await createSearchOptionsFolderTable(payload)
        .then((response) => {
          if (response.data) {
            props.fetchSearchOptionsData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('System Folder created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            setIsSuperUser(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = async () => {
    if (
      !props.formData.name ||
      props.formData.name?.length > 50 ||
      props.formData.systemFolderLevel === null ||
      props.formData.systemFolderLevel === '' ||
      isNaN(props.formData.systemFolderLevel) === true ||
      props.formData.systemFolderLevel >= 10 ||
      props.formData.systemFolderLevel <= 1 ||
      props.formData.description?.length > 150
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    console.log(payload);
    props.isLoading(true);
    await updateSearchFolderTable(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('System Folder updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchSearchOptionsData();
        props.setRecordModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const handleToggleButton = (event) => {
    const value = event.target.value;

    props.setFormData((prevData) => ({
      ...prevData,
      isVisible: value,
    }));
  };

  return (
    <Modal
      {...props}
      className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create System Folder' : 'Edit System Folder'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.usersTableModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>System Folder Level *</label>
                <input
                  className={props.isNew ? '' : styles.disabledInput}
                  disabled={props.isNew ? false : true}
                  name="systemFolderLevel"
                  value={props.formData.systemFolderLevel}
                  type="number"
                  min="2"
                  max="9"
                  onChange={handleInputName}
                />
                {(isNaN(props.formData.systemFolderLevel) === true || props.formData.systemFolderLevel === null || props.formData.systemFolderLevel === '') &&
                isRequiredError === true ? (
                  <div>{handleIsRequiredError()}</div>
                ) : (
                  (props.formData.systemFolderLevel >= 10 || props.formData.systemFolderLevel <= 1) &&
                  isRequiredError === true && <span className={'requiredErrorMsgStyle'}>Enter values between 2 to 9 only</span>
                )}
                {}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Name *</label>
                <input name="name" value={props.formData.name} onChange={handleInputName} />
                {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.name?.length > 50 && handleMaxLimitError(50)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={styles.radioInputOuterDiv}>
                  <div>LowCard: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="lowCard" value={true} checked={props.formData.lowCard === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="lowCard" value={false} checked={props.formData.lowCard === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
              {props.formData.lowCard === true && (
                <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                  <div className={styles.radioInputOuterDiv}>
                    <div>Preview in object details:&nbsp; </div>
                    <div style={{ display: 'flex' }}>
                      <Switch
                        // className={props.formData.isVisible ? 'k-switch-on k-switch-container' : 'k-switch-off k-switch-container'}
                        className={styles.abc}
                        onChange={(e) => {
                          handleToggleButton(e);
                        }}
                        onLabel={''}
                        offLabel={''}
                        checked={props.formData.isVisible}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                <label>Description </label>
                <textarea name="description" rows="5" value={props.formData.description} onChange={handleInputName} />
                {props.formData.description?.length > 150 && handleMaxLimitError(150)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.usersTableFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
