import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import './SystemConfiguration.scss';
import styles from './SystemConfiguration.module.scss';
import '../../../GlobalStyle.scss';

import TableSystemConfiguration from './TableSystemConfiguration';
import SystemConfigurationData from './SystemConfiguration.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
// import InitialData from './SystemConfigData.json';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { createSystemConfig, deleteSystemConfig, searchSystemConfig, updateSystemConfig } from './SystemConfigurationService';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import Footer from '../../../newcomponents/Footer/Footer';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'System Configuration', link: '' },
];

export default function SystemConfiguration() {
  // const [stepError, setStepError] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: '',
    logo: '',
    title: '',
    clipBoardName: '',
    name: '',
    authenticationType: '',
    ruleViewSetting: '',
    id: '',
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [systemConfigurationDetails, setSystemConfigurationData] = useState(SystemConfigurationData);

  async function fetchSystemConfigData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    fetchSystemConfigData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: '',
      logo: '',
      title: '',
      clipBoardName: '',
      authenticationType: '',
      ruleViewSetting: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };

    deleteSystemConfig(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchSystemConfigData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Record deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      logo: data.logo,
      title: data.title,
      clipBoardName: data.clipBoardName,
      authenticationType: data.authenticationType,
      ruleViewSetting: data.ruleViewSetting,
      id: data.id,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
    if (value.length > 2) onSearch(value);
    if (value.length === 0) onSearch(value);
  };

  const onSearch = async (searchText) => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newSystemConfigurationData = JSON.parse(JSON.stringify(systemConfigurationDetails));
    newSystemConfigurationData.forEach((itm) => {
      itm.sortType = '';
    });
    setSystemConfigurationData(newSystemConfigurationData);
    await fetchNewSystemConfigData();
  };

  // Code needs to change
  const fetchNewSystemConfigData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.systemConfigurationPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.systemConfigurationPageContent}>
        <h1 className={styles.pageTitle}>System Configuration</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent searchText={searchText} handleSearch={handleSearch} onSearch={onSearch} clearSearch={clearSearch}></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Record" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
          </div>
        </div>
        <div className={[styles.systemConfigurationTable, 'glbSystemConfigurationTable'].join(' ')}>
          <TableSystemConfiguration
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={systemConfigurationDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditSystemConfig
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchSystemConfigData={fetchSystemConfigData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'System Configuration'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditSystemConfig = (props) => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgFile, setSelectedImgFile] = useState(null);

  const [isRequiredError, setisRequiredError] = useState(false);
  useEffect(() => {
    setisRequiredError(false);
    if (props.isNew === true && selectedImgFile === null) setSelectedImg(null);
  }, [props.formData, props.isNew, selectedImgFile]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };

  const handleIcon = (e) => {
    const data = new FileReader();
    data.addEventListener('load', () => {
      props.setFormData((prevData) => ({
        ...prevData,
        logo: '',
      }));
      setSelectedImg(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setSelectedImgFile(e.target.files[0]);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!props.formData.contextId || props.formData.contextId?.length > 50 || !props.formData.title || !props.formData.authenticationType || !selectedImg) {
        setisRequiredError(true);
        return;
      }

      let payload = _.cloneDeep(props.formData);

      props.isLoading(true);

      if (selectedImg) {
        let logoBlob = selectedImg.split(',').pop();
        if (logoBlob) payload.logo = logoBlob;
      }

      await createSystemConfig(payload)
        .then((response) => {
          if (response.data) {
            props.fetchSystemConfigData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Record created successfully');
            setSelectedImg(null);

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');
            // setSelectedImg(null);

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    if (
      (!props.formData.contextId || props.formData.contextId?.length > 50 || !props.formData.title || !props.formData.authenticationType) &&
      (!selectedImg || !props.formData.logo)
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);
    if (selectedImg) {
      let logoBlob = selectedImg.split(',').pop();
      if (logoBlob) payload.logo = logoBlob;
    }
    props.isLoading(true);
    await updateSystemConfig(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Record updated successfully');
        setSelectedImg(null);

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchSystemConfigData();
        props.setRecordModalShow(false);
        setSelectedImg(null);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <Modal
      {...props}
      className={[styles.systemConfigurationModal, 'glbSystemConfigurationModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
        setSelectedImgFile(null);
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Record' : 'Edit Record'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setSelectedImg(null);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.systemConfigModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Context Id *</label>
                <input required name="contextId" value={props.formData.contextId} onChange={handleInputChange} disabled={props.isNew ? false : true} />
                {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
              </div>

              <div className={styles.uploadImageSection}>
                <label>Logo *</label>
                <div className={styles.imageContents}>
                  <div className={styles.iconInput}>
                    <input type="file" name="logo" accept="image/jpeg, image/png, image/webp" onChange={handleIcon} />

                    {selectedImg && <img className={styles.imageStyle} src={selectedImg} alt="preview" width="400" height="200" />}
                    {props.formData.logo && <img className={styles.imageStyle} src={`data:image/jpeg;base64,${props.formData.logo}`} alt="preview" width="400" height="200" />}
                  </div>
                  {props.isNew && (selectedImg === null || selectedImg === '') && isRequiredError === true && (
                    <div style={{ marginLeft: '1rem' }} className="mb-3">
                      <span>{handleIsRequiredError()}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Title *</label>
                <input required name="title" value={props.formData.title} onChange={handleInputChange} />
                {(props.formData.title === null || props.formData.title === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Clipboard Name</label>
                <input required name="clipBoardName" value={props.formData.clipBoardName} onChange={handleInputChange} />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Auth Type *</label>
                <select id="selectFieldAuthType" name="authenticationType" value={props.formData.authenticationType} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Type--
                  </option>
                  <option value={'okta'}>Okta</option>
                  <option value={'system'}>System</option>
                </select>
                {(props.formData.authenticationType === null || props.formData.authenticationType === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Rule View</label>
                <select id="selectField" name="ruleViewSetting" value={props.formData.ruleViewSetting} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select View--
                  </option>
                  <option value={'page'}>Page</option>
                  <option value={'doc'}>Document</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setSelectedImg(null);
                setisRequiredError(false);
              }}
            />

            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
