import _ from 'lodash';
export const groupData = _.cloneDeep([
  {
    id: 1,
    display_name: 'Employee',
  },
  {
    id: 2,
    display_name: 'Benefits',
  },
  {
    id: 3,
    display_name: 'I-9',
  },
  {
    id: 4,
    display_name: 'Immigration',
  },
  {
    id: 5,
    display_name: 'Garnishments',
  },
  {
    id: 6,
    display_name: 'Legal',
  },
  {
    id: 7,
    display_name: 'Workers Compensation',
  },
  {
    id: 8,
    display_name: 'FMLA/STD',
  },
  {
    id: 9,
    display_name: 'Ex-pats',
  },
  {
    id: 10,
    display_name: 'Post Accident',
  },
  {
    id: 11,
    display_name: 'Confidential',
  },
]);

export const sectionData = _.cloneDeep([
  // {
  //   id: 1,
  //   display_name: 'Background Check Information',
  // },
  // {
  //   id: 2,
  //   display_name: 'Disciplinary Actions',
  // },
  // {
  //   id: 3,
  //   display_name: 'Education & Training',
  // },
  // {
  //   id: 4,
  //   display_name: 'Job Changes',
  // },
  // {
  //   id: 5,
  //   display_name: 'Miscellaneous',
  // },
  // {
  //   id: 6,
  //   display_name: 'New Hire Information',
  // },
  // {
  //   id: 7,
  //   display_name: 'Performance Reviews',
  // },
  // {
  //   id: 8,
  //   display_name: 'Payroll',
  // },

  {
    id: 1,
    display_name: 'New Hire Information',
  },
  {
    id: 2,
    display_name: 'Job Changes',
  },
  {
    id: 3,
    display_name: 'Performance Reviews',
  },
  {
    id: 4,
    display_name: 'Disciplinary Actions',
  },
  {
    id: 5,
    display_name: 'Education & Training',
  },
  {
    id: 6,
    display_name: 'Miscellaneous',
  },
  {
    id: 7,
    display_name: 'Payroll',
  },
  {
    id: 8,
    display_name: 'Background Check Information',
  },
  {
    id: 9,
    display_name: 'Benefits',
  },
  {
    id: 10,
    display_name: 'I-9',
  },
  {
    id: 11,
    display_name: 'Immigration',
  },
  {
    id: 12,
    display_name: 'Garnishments',
  },
  {
    id: 13,
    display_name: 'Legal',
  },
  {
    id: 14,
    display_name: 'Workers Compensation',
  },
  {
    id: 15,
    display_name: 'FMLA/STD',
  },
]);

export const employmentStatusData = _.cloneDeep([
  {
    id: 1,
    display_name: 'Active',
  },
  {
    id: 2,
    display_name: 'Inactive',
  },
  {
    id: 3,
    display_name: 'Terminated',
  },
  {
    id: 4,
    display_name: 'Pre-Hire',
  },
]);

export const statusReasonData = _.cloneDeep([
  {
    id: 1,
    display_name: 'Termination',
    value: 'TERMINATION',
  },
  {
    id: 2,
    display_name: 'Resignation',
    value: 'RESIGNATION',
  },
  {
    id: 3,
    display_name: 'Retirement',
    value: 'RETIREMENT',
  },

  {
    id: 4,
    display_name: 'FMLA',
    value: 'FMLA',
  },
  {
    id: 5,
    display_name: 'STD',
    value: 'STD',
  },
  {
    id: 6,
    display_name: 'LTD',
    value: 'LTD',
  },
  {
    id: 7,
    display_name: 'LOA',
    value: 'LOA',
  },
  {
    id: 8,
    display_name: 'Abandonment',
    value: 'ABANDONMENT',
  },
]);
