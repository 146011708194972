import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import Log from "./logger";
// import { configure } from "@testing-library/react";
// import { createStore } from "redux";
import { Provider } from "react-redux";

import rootReducer from "./services/reducers/rootReducer";
import { createStore, compose, applyMiddleware } from "redux";
import {  persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["navigation"], //, "commonReducer"

  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  pReducer,
  composeEnhancers(compose(
    applyMiddleware(thunkMiddleware),
  ))
);

localStorage.setItem("debug", "DRMS_GUI:*");
ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
