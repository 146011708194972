import React, { Component } from 'react';
import axios from 'axios';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, Switch, TextArea, RadioGroup } from '@progress/kendo-react-inputs';
import { assignFileCode } from '../../services/recordsManagementService';

const radios = [
    { label: 'Scheduled', value: 'Scheduled' },
    { label: 'Unscheduled', value: 'Unscheduled' },
    { label: 'In Process', value: 'In Process' },
];


class SearchDetail extends Component {
    constructor(props) {
        super(props);
        this.FileCodeContract = {
            createParam: [],
        };
        this.state = this.getInitialState();
    }
    getInitialState = () => ({
        ...this.props,
        fileCodeTblList: [],
        filteredTblList: [],
        primaryOrganizationList: [],
        fcSelection: '',
        selected: 0,
    })

    handleSelect = (e) => {
        this.setState({ selected: e.selected });
    };

    componentDidMount = async () => {
        let url = '/recordsmanagementapi/records-management/filecodes';
        axios.get(url).then((response) => {
            console.log('filecodes from api', response);

            // Populating primary organizations ddl with unique values from this api call
            let primOrgsInTable = [...new Set(response.data.map(item => item.primaryorg))];
            console.log('primOrgsInTable', primOrgsInTable);

            this.setState({
                fileCodeTblList: response.data || [],
                filteredTblList: response.data || [],
                primaryOrganizationList: this.state.primaryOrganizationList.concat(primOrgsInTable),
            });
        }).catch((error) => {
            console.log('Error loading file code table: ', error);
        });

        let recordScheduleUrl = '/recordsmanagementapi/records-management/schedules';
        axios.get(recordScheduleUrl).then((recordScheduleResponse) => {
            console.log('recordScheduleResponse', recordScheduleResponse);
            this.setState({
                recordScheduleList: recordScheduleResponse.data || [],
            });
        });
    }

    resetState = () => {
        this.setState(this.getInitialState());
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onSearch = (e) => {
        e.preventDefault();
        let self = this;
        let data = this.state.fileCodeTblList;

        if (this.state.fileCodeName.length > 0) {
            console.log('filtering based on fileCode');
            data = data.filter(function (el) {
                return el.filecodeName === self.state.fileCodeName;
            });
        }
        this.setState({
            filteredTblList: data,
        });
    }

    handleGridRowClick = e => {
        this.setState({
            fcSelection: e.dataItem.filecodeId,
            fileCodeName: e.dataItem.filecodeName,

        });
    }

    render() {
        return (
            <div className="" >
                <form className="searchBar fc-search-form">
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="filecode">File Code Name:</label>
                                    <Input id="filecode" name="fileCodeName" value={this.state.fileCodeName} onChange={this.handleChange} className="fsa-input" />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="filecode">File Code ID:</label>
                                    <Input id="filecodeid" name="fileCodeId" value={this.state.fileCodeId} onChange={this.handleChange} className="fsa-input" />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="actReference">Act Reference:</label>
                                    <Input id="actReference" name="actReference" value={this.state.actReference} onChange={this.handleChange} className="fsa-input" />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="recordTitle">Record Title:</label>
                                    <Input id="recordTitle" name="recordTitle" value={this.state.recordTitle} onChange={this.handleChange} className="fsa-input" />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="ddlPrimaryOrganization">Primary Organization:</label>
                                    <DropDownList
                                        id={"ddlPrimaryOrganization"}
                                        name="primaryOrganization"
                                        data={this.state.primaryOrganizationList}
                                        // textField="recordScheduleName"
                                        // dataItemKey="eventComponentId"
                                        value={this.state.primaryOrganization}
                                        onChange={this.handleChange}
                                    // defaultItem={this.state.defaultRecordScheduleSelection}
                                    />
                                </div>
                                <div className="form-field">
                                    <div>Secondary Organization:</div>
                                    <DropDownList
                                        // data={this.state.recordScheduleList}                                        
                                        // textField="recordScheduleName"
                                        // dataItemKey="eventComponentId"
                                        value={this.state.secondaryOrganization}
                                        onChange={this.handleChange}
                                    // defaultItem={this.state.defaultRecordScheduleSelection}
                                    />
                                </div>
                                <div className="form-field">
                                    <div>Office Type:</div>
                                    <DropDownList
                                        // data={this.state.recordScheduleList}                                        
                                        // textField="recordScheduleName"
                                        // dataItemKey="eventComponentId"
                                        value={this.state.officeType}
                                        onChange={this.handleChange}
                                    // defaultItem={this.state.defaultRecordScheduleSelection}
                                    />
                                </div>

                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="vital">Vital:</label>
                                    <Switch
                                        id="vital"
                                        value={this.state.vitalSelection}
                                        onChange={this.vitalChange}
                                        onLabel={"Yes"} offLabel={"No"}
                                    />
                                </div>

                                <div className="form-field temp">
                                    <label className="fsa-field__label" >Record Type: </label>
                                    <Switch
                                        id="tempPermChange"
                                        name="tempPermSelection"
                                        value={this.state.tempPermSelection}
                                        onChange={this.recordTypeChange}
                                        onLabel={"Permanent"} offLabel={"Temporary"}
                                    />
                                </div>
                                <div className="form-field temp">
                                    <label className="fsa-field__label">Program Status: </label>
                                    <Switch
                                        id="programStatusChange"
                                        name="programStatusSelection"
                                        value={this.state.programStatusSelection}
                                        onChange={this.handleProgramStatusChange}
                                        defaultChecked={false}
                                        onLabel={"Active"} offLabel={"Inactive"}
                                    // checked={this.state.programStatusSelection === "Active" ? true : false}
                                    />
                                </div>
                                <div className="form-field fc">
                                    <label className="fsa-field__label">File Code Status: </label>
                                    <Switch
                                        id="fcStatusChange"
                                        name="fileCodeStatus"
                                        value={this.state.fileCodeStatus}
                                        onChange={this.fcStatusChange}
                                        defaultChecked={true}
                                        onLabel={"Scheduled"} offLabel={"Unscheduled"}
                                    />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="schedulingStatus">Scheduling Status:</label>
                                    <RadioGroup data={radios} disabled={this.state.disabled} defaultValue="Scheduled" value={this.state.checked} onChange={this.handleRadioChange} />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label">Keywords:</label>
                                    <Input id="keywords" name="keywords" value={this.state.keywords} onChange={this.handleChange} className="fsa-input" />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="systemOfOrigin">System of Origin:</label>
                                    <Input id="systemOfOrigin" name="systemOfOrigin" value={this.state.systemOfOrigin} onChange={this.handleChange} className="fsa-input" />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label">Disposition Authority:</label>
                                    <Input id="dispositionAuthority" name="dispositionAuthority" value={this.state.dispositionAuthority} onChange={this.handleChange} className="fsa-input" />
                                </div>

                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="schedulingStatus">Primary Description:</label>
                                    <TextArea rows={2} autoSize={true} value={this.state.primarydescription} onChange={this.textAreaChangePrimary} />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="schedulingStatus">Secondary Description:</label>
                                    <TextArea rows={2} autoSize={true} value={this.state.secondarydescription} onChange={this.textAreaChangeSecondary} />
                                </div>
                                <div className="form-field">
                                    <label className="fsa-field__label" htmlFor="schedulingStatus">Comments:</label>
                                    <TextArea rows={2} autoSize={true} value={this.state.comments} onChange={this.textAreaChangeComments} />
                                </div>

                                <div className="form-field">
                                    <div className="fsa-field">
                                        <label className="fsa-field__label" htmlFor="ddlRecordSchedule">Select a Record Schedule:</label>
                                        <DropDownList
                                            id="ddlRecordSchedule"
                                            data={this.state.recordScheduleList}
                                            textField="scheduleName"
                                            name="schedule"
                                            dataItemKey="scheduleUuid"
                                            value={this.state.recordScheduleSelection}
                                            onChange={this.handleChange}
                                            // defaultItem={this.state.defaultRecordScheduleSelection}
                                            defaultItem={{ 'scheduleName': '-Select-', 'scheduleUuid': 0 }}
                                        />
                                    </div>
                                </div>

                                <div className="form-field"><button className="fsa-btn" onClick={this.onSearch}>Search</button></div>
                                <div className="form-field"><button className="fsa-btn" onClick={this.onAssign}>Assign</button></div>

                            </form>

                            <div className='advanced flexrow'>
                                <Grid
                                    style={{ height: '600px' }}
                                    data={this.state.filteredTblList}
                                    onRowClick={this.handleGridRowClick}
                                    selectedField="selected"
                                >
                                    <Column field="filecodeName" title="File Code Name" />
                                    <Column field="filecodeId" title="File Code ID" />
                                    <Column field="versionnumber" title="Version" width="80px" />
                                    <Column field="primaryorg" title="Primary Organization" width="160px" />
                                    <Column field="recordtitle" title="Record Title" width="100px" />
                                    <Column field="dispositionAuthority" title="Disposition Authority" width="160px" />
                                    <Column field="actreference" title=" Act Reference" />
                                    <Column field="schedule.scheduleName" title="Schedule Name" />
                                    <Column
                                        title="Select"
                                        cell={props => (
                                            <td>
                                                <input type="checkbox" checked={props.dataItem[props.field]} />
                                            </td>
                                        )}
                                    />
                                </Grid>
                            </div>

            </div>
        );
    }
}

export default SearchDetail;