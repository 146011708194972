import React, { useEffect, useState } from 'react';
import styles from './ObjectMetadata.module.scss';
import AsyncSelect from 'react-select/async';
import EditPencilBlueIcon from '../../../fsa-style/img/svgs/EditPencilBlue.svg';
import { objectMetadataData, docDetailResponseData, metadataDisplayListData, mappedData } from '../ObjectTypeData';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const ObjectMetadata = () => {
  const [EditRefresh, setEditRefresh] = useState(false);

  const [metadataDisplayList, setMetadataDisplayList] = useState(metadataDisplayListData);
  const [objectMetadata, setObjectMetadata] = useState(objectMetadataData);
  const [editedFields, setEditedFields] = useState();

  const isCustomerInfo = (key) => {
    return (
      key.startsWith('producer[') ||
      key.startsWith('producer.') ||
      key.startsWith('entity.') ||
      key.startsWith('entity[') ||
      key.startsWith('participant.') ||
      key.startsWith('participant[') ||
      key.startsWith('member_info.') ||
      key.startsWith('member_info[') ||
      key.startsWith('guardian.') ||
      key.startsWith('guardian[') ||
      key.startsWith('title') ||
      key.startsWith('object_type_name') ||
      key.startsWith('edition_date')
    );
  };

  const EditDisplayList = (ele, idx, checkEdit) => {
    if (checkEdit === 'metadataDisplayList') {
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].isEditSelected = false); // if metadataDisplayList is selected then objectMetadata is input false
        }
      });
      Object.keys(metadataDisplayList).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (idx === index) {
          return (metadataDisplayList[key].isEditSelected = !metadataDisplayList[key].isEditSelected);
        } else {
          return (metadataDisplayList[key].isEditSelected = false);
        }
      });
    }
    if (checkEdit === 'objectMetadata') {
      Object.keys(metadataDisplayList).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        return (metadataDisplayList[key].isEditSelected = false); // if objectMetadata is selected then metadataDisplayList is input false
      });
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].isEditSelected = !objectMetadata[key].isEditSelected);
        }
      });
    }
  };

  const onChangeInput = (e, idx, checkEdit, currentField) => {
    if (checkEdit === 'metadataDisplayList') {
      let value = e.target.value;
      Object.keys(metadataDisplayList).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (idx === index && currentField) {
          setEditedFields((prevEditedFields) => ({
            ...prevEditedFields,
            [key]: (metadataDisplayList[key].value = value),
          }));
        }
      });
    }
    if (checkEdit === 'objectMetadata') {
      let value = e.target.value;
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].title = value);
        }
      });
    }
  };

  const onSaveDisplayList = (e, idx, checkEdit) => {
    if (checkEdit === 'metadataDisplayList') {
      if (e.key === 'Enter') {
        let value = e.target.value;
        Object.keys(metadataDisplayList).forEach((key, index) => {
          setEditRefresh(!EditRefresh);
          if (idx === index) {
            return (metadataDisplayList[key].isEditSelected = false), (metadataDisplayList[key].value = value);
          }
        });
      }
    }
    if (checkEdit === 'objectMetadata') {
      if (e.key === 'Enter') {
        let value = e.target.value;
        Object.keys(objectMetadata).forEach((key, index) => {
          setEditRefresh(!EditRefresh);
          if (key === 'object_type') {
            return (objectMetadata[key].isEditSelected = false), (objectMetadata[key].title = value);
          }
        });
      }
    }
  };

  const DocumentTitleOption = [
    { label: 'Offer Letter', value: 'Offer Letter' },
    { label: 'Candidate Information', value: 'Candidate Information' },
    { label: 'Fleet Vehicle Documentation', value: 'Fleet Vehicle Documentation' },
    { label: 'Continuing Ed-Prof Development', value: 'Continuing Ed-Prof Development' },
    { label: 'Employee Referral Bonus Documentation', value: 'Employee Referral Bonus Documentation' },
  ];

  const DocumentTitleOptions = DocumentTitleOption.map((color) => ({
    ...color,
    key: color.value,
  }));

  const filterDocumentTitleOption = (inputValue) => {
    return DocumentTitleOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterDocumentTitleOption(inputValue));
    }, 1000);
  };

  const [sectionList, setSectionList] = useState([
    {
      id: 1,
      display_name: 'Background Check Information',
    },
    {
      id: 2,
      display_name: 'Disciplinary Actions',
    },
    {
      id: 3,
      display_name: 'Education & Training',
    },
    {
      id: 4,
      display_name: 'Job Changes',
    },
    {
      id: 5,
      display_name: 'Miscellaneous',
    },
    {
      id: 6,
      display_name: 'New Hire Information',
    },
    {
      id: 7,
      display_name: 'Performance Reviews',
    },
    {
      id: 8,
      display_name: 'Payroll',
    },
  ]);

  const [sectionFilteredData, setSectionFilteredData] = useState(sectionList.slice());
  const [sectionSelection, setSectionSelection] = useState(null);
  const [sections, setSections] = useState();

  const defaultItemforSections = {
    display_name: docDetailResponseData.metadata.object_specific.sections,
  };

  const sectionDropListChange = (e) => {
    setSectionSelection(e.target.value);
    let key = 'sections';
    let value = e.target.value.display_name;
    setEditedFields((values) => ({ ...values, [key]: value }));
  };

  return (
    <div className={styles.objectMetadataContainer}>
      <div className={styles.objectMetaInnerRow}>
        <div>Document Title</div>
        <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions />
      </div>
      <div className={styles.objectMetaInnerRow}>
        <div>Sections</div>
        <DropDownList
          data={sectionFilteredData}
          filterable={true}
          defaultItem={defaultItemforSections}
          textField="display_name"
          dataItemKey="id"
          value={sectionSelection}
          // onFilterChange={sectionFilterChange}
          onChange={sectionDropListChange}
          style={{ width: 'calc(100% - 15%)' }}
        />
      </div>

      <div>
        {metadataDisplayList &&
          Object.keys(metadataDisplayList).map((key, idx) => {
            if (key === 'sections') {
              return null;
            }
            if (!isCustomerInfo(key)) {
              return (
                <div className={styles.objectMetaInnerRow} key={key}>
                  <div className="keyfont">{metadataDisplayList[key].display_name}</div>
                  <div className="valuefont">
                    {metadataDisplayList[key].isEditSelected === true ? (
                      <input
                        value={metadataDisplayList[key].value !== '.' && metadataDisplayList[key].value}
                        onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name)}
                        onKeyPress={(e) => onSaveDisplayList(e, idx, 'metadataDisplayList')}
                        style={{ width: 'calc(100% - 15%)' }}
                      />
                    ) : (
                      <>{metadataDisplayList[key].value}</>
                    )}

                    <img
                      style={{
                        marginLeft: '2px',
                        padding: '2px 0px 4px 10px',
                        height: '2.5rem',
                        cursor: 'pointer',
                      }}
                      alt="pencilIcon"
                      src={EditPencilBlueIcon}
                      onClick={() => EditDisplayList(metadataDisplayList, idx, 'metadataDisplayList')}
                    />
                  </div>
                </div>
              );
            }
          })}
      </div>
      <div className={styles.objectMetaInnerRowBtn}>
        <button
          // onClick={onSaveButtonClick}
          className="fsa-btn"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  );
};

export default ObjectMetadata;
