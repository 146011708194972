import React, { useEffect, useState, useRef } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { CircularProgress } from '@material-ui/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './UpdateObjectFileCode.scss';
import SearchFileCodeFilters from './SearchFileCodeFilters';
import FileCodeList from './FileCodeList';
import { assignFileCode, searchFileCodes } from '../../../services/recordsManagementService';
import { getDocDetail } from '../../../services/metadataSubmissionService';
import ErrorGrowl from '../../SuspenseQueue/ErrorGrowl';
import SuccessProcessGrowl from '../../SuspenseQueue/SuccessProcessGrowl';
import { useWindowSize } from '../../../services/helper';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import FullscreenIcon from '../../../fsa-style/img/svgs/fullScreenIcon.svg';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
var _ = require('lodash');
export default function UpdateObjectFileCodeModal({
  ids,
  objectID,
  toggleUpdateObjFileCodePopup,
  setRecords,
  records,
  objFileCodePopupOpen,
  loadingFileCodes,
  setLoadingFileCodes,
  windowHeight, //Only needed for modal inside a modal (detail view)
  windowWidth, //Only needed for modal inside a modal (detail view)
  objectMetadata, //Only needed for detail view assign file code
  setObjectMetadata,
}) {
  const [isSearchingFileCode, setIsSearchingFileCode] = useState(false);
  const [isAssigningFileCode, setIsAssigningFileCode] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const [fileCode, setFileCode] = useState(null);
  const [width, height] = useWindowSize();
  const [expand, setExpand] = useState('DEFAULT');
  const [isLoading, setIsLoading] = useState(false);

  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };

  useEffect(() => {
    if (loadingFileCodes && objFileCodePopupOpen) {
      searchFileCodes({ filecodestatus: 'Active' }).then((response) => {
        setDataItems(response.data.length > 25 ? response.data.slice(0, 25) : response.data);
        setLoadingFileCodes(false);
      });
    }
  });

  const assignFileCodeToDocs = () => {
    setIsLoading(true);
    let uniqueIdsArray = [];
    for (let i = 0; i < ids.length; i++) {
      let isAggObject = ids[i].hasOwnProperty('aggregations');
      if (isAggObject === true) {
        let idArray = ids[i].aggregations.asMap.employee_data.hits.hits;
        for (let j = 0; j < idArray.length; j++) {
          uniqueIdsArray.push(idArray[j].id);
        }
      } else {
        uniqueIdsArray = [];
        uniqueIdsArray = ids;
      }
    }

    setIsAssigningFileCode(true);
    uniqueIdsArray = _.uniq(_.map(uniqueIdsArray));

    assignFileCode(fileCode, uniqueIdsArray).then((response) => {
      if (response && response.status === 200) {
        if (objectMetadata) {
          let newObjectMetadata = objectMetadata;
          getDocDetail(objectID).then((response1) => {
            newObjectMetadata.record_management = response1.data.metadata.record_management;
          });
          if (!newObjectMetadata.record_management) {
            newObjectMetadata.record_management = {};
          }
          newObjectMetadata.record_management.file_code = fileCode;
          setObjectMetadata(newObjectMetadata);
        }
        let currentRecords = records;
        const keys = Object.keys(response.data);
        keys.map(function (keyName, keyIndex) {
          let row = currentRecords.metadata.find((item) => item._id === keyName);
          if (row) {
            if (!row.record_management) {
              row.record_management = {};
            }
            row.record_management.file_code = fileCode;
            currentRecords.metadata = currentRecords.metadata.map((item) => (item._id === row._id ? row : item));
          }
        });
        // if (keys.length < ids.length) {
        //   setIsErrorGrowlHidden(false);
        //   setTimeout(() => {
        //     setIsErrorGrowlHidden(true);
        //   }, 5000);
        // } else {
        setIsSuccessGrowlHidden(false);
        setIsLoading(false);
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
          toggleUpdateObjFileCodePopup(false);
        }, 5000);
        // }
        setRecords(currentRecords);
      } else {
        setIsErrorGrowlHidden(false);
        setIsLoading(false);
        setTimeout(() => {
          setIsErrorGrowlHidden(true);
          toggleUpdateObjFileCodePopup(false);
        }, 5000);
      }
      setIsAssigningFileCode(false);
    });
  };

  return (
    <div>
      {/* {objFileCodePopupOpen && loadingFileCodes && (
        <div className="center">
          <GlobalLoader />
        </div>
      )} */}

      {objFileCodePopupOpen && (
        <Window
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
          minimizeButton={() => null}
          maximizeButton={() => null}
          restoreButton={() => null}
          modal={true}
          draggable={false}
          left={windowWidth ? windowWidth * 0.15 : width * 0.2}
          initialTop={windowHeight ? windowHeight * 0.01 : height * 0.05}
          onClose={() => {
            toggleUpdateObjFileCodePopup(false);
          }}
          height={windowHeight ? windowHeight * 0.9 : height * 0.9}
          width={windowWidth ? windowWidth * 0.6 : width * 0.6}
          stage={expand}
          title={'Update File Code'}
          className="updateFileCode"
          closeButton={() => (
            <>
              <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
                {/* <b>X</b> */}
                <img src={FullscreenIcon} alt="FullScreen icon" />
              </button>
              <button
                aria-label="Close Detail View Window"
                style={{ backgroundColor: 'Transparent', border: 'none' }}
                onClick={() => {
                  return toggleUpdateObjFileCodePopup(false), setExpand('DEFAULT');
                }}
              >
                {/* <b>X</b> */}
                <img src={crossIcon} alt="cross icon" />
              </button>
            </>
          )}
        >
          {isLoading &&  <GlobalLoader />}
          {loadingFileCodes ? (
            <div className="center">
                <GlobalLoader />
            </div>
          ) : (
            <div className="d-flex flex-column">
              {/* <h2 className="updateObjFileCodeTitle">Update Object File Code</h2> */}
              <div className="updateObjFileContent">
                <div className="searchFileCode" style={{width:'25%'}}>
                  <div style={{ borderRight: '1.5px solid #D9D9D9' }} className='w-100'>
                    <div className='w-100'>
                      <h3 style={{ marginBottom: '0px', marginTop: '0px' }}>Search File Codes</h3>
                      {/* <div className="fsa-divider"></div> */}
                    </div>
                    <SearchFileCodeFilters setFileCode={setFileCode} setIsSearchingFileCode={setIsSearchingFileCode} setDataItems={setDataItems}></SearchFileCodeFilters>
                  </div>
                </div>
                <div style={{ maxWidth: '74%', width: '100%' }}>
                  <div style={{ minWidth: '100%', overflowX: 'hidden', minHeight: '100px' }}>
                    {' '}
                    <h3 style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '27px', marginBottom: '20px' }}>Select a File Code</h3>
                    {/* <div style={{ minWidth: "100%", marginBottom: "1em" }} className="fsa-divider"></div> */}
                    <div className="fileCodeViewer">
                      {isAssigningFileCode || isSearchingFileCode ? (
                        <div className="fsa-progress fsa-progress--indeterminate w-100" aria-live="polite">
                          <div className="fsa-progress__details">
                            <div className="fsa-progress__label">
                              {isAssigningFileCode ? 'Assigning File Code' + fileCode + ' to one or more Documents' : 'Retrieving File Codes'}
                            </div>
                          </div>
                          <div className="fsa-progress__bar" aria-hidden="true">
                            <div className="fsa-progress__primary"></div>
                            <div className="fsa-progress__secondary"></div>
                          </div>
                        </div>
                      ) : (
                        <FileCodeList setFileCode={setFileCode} dataItems={dataItems} expand={expand}></FileCodeList>
                      )}
                    </div>
                  </div>
                  <div className="resultBox">Showing top {dataItems.length} results</div>
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex',justifyContent: 'center', width: '100%', alignItems: 'baseline' }}>
                  <div className="alert--warning" style={{ width: '52%' }}>
                    <FontAwesomeIcon style={{ fontSize: '17px', marginTop: '10px' }} color="#494440" icon={faExclamationTriangle} />
                    <font color={'#2F2C2A'}>
                      <b>This action will override one or more objects' current File Code.</b>
                    </font>
                  </div>
                  <button disabled={fileCode === null} style={{ marginRight: '0.25em', marginTop: '20px' }} onClick={() => assignFileCodeToDocs()} className="fsa-btn">
                    <span>Update</span>
                  </button>
                  <button onClick={() => {toggleUpdateObjFileCodePopup(false);setExpand('DEFAULT');}} className="fsa-btn fsa-btn--secondary" style={{ marginTop: '20px' }}>
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          )}
           <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={'File code could not be assigned to one or more documents.'} />
      <SuccessProcessGrowl
        isSuccessGrowlHidden={isSuccessGrowlHidden}
        setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
        message={'Successfully assigned file code to one or more documents.'}
      />
        </Window>
      )}
     
    </div>
  );
}
