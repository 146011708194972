import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
  const userRole =
    JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Admin') ||
    JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Application Administrator');
  return <Route {...rest} render={(props) => (userRole === true ? <Component {...props} /> : <Redirect to={'/login'} />)} />;
};

export default AdminRoute;
