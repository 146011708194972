import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './MetadataModal.module.scss';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { useRef } from 'react';

const values = [
  {
    display_name: 'Document Name',
  },
  { display_name: 'Document Title' },
  { display_name: 'Employee Number' },
  { display_name: 'First Name' },
  { display_name: 'Last Name' },
  { display_name: 'Middle Name' },
  { display_name: 'Sections' },
  { display_name: 'Employee Number' },
  { display_name: 'Document Name' },
  { display_name: 'Document Title' },
  { display_name: 'Employee Number' },
  { display_name: 'First Name' },
];

const MetaDataModal = ({
  metadataModalOpen,
  closeMetadatModal,
  setMetadataModalOpen,
  metadataSelection,
  onInputTitleChange,
  fieldsData,
  onChangeAsyncData,
  onCloseList,
  defaultItem,
  metaFilterChange,
  advSearchStateChange,
  clearData,
  handleChange,
  searchFormObjArray,
  renderSpecsDiv,
  closeHandler,
  prevStateHandler,
  metaClearOnSearch,
}) => {
  const [dropdownData, setDropdownData] = useState({
    display_name: '',
  });
  const [dropdownLabel, setDropdownLabel] = useState(false);
  const [inputTitle, setInputTitle] = useState([]);
  const modelRef = useRef();

  const metaDropListChange = (event) => {
    onChangeAsyncData(event.obj);
    if (event == null)
      return setDropdownData((prevData) => ({
        ...prevData,
        display_name: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    setDropdownData((prevData) => ({
      ...prevData,
      display_name: updatedValue,
    }));

    if (value !== null) {
      if (!inputTitle.includes(value)) {
        if (inputTitle.filter((x) => x.name === value).length === 0) {
          let newObj = { name: value, value: '', inputData: event.obj };
          setInputTitle([...inputTitle, newObj]);
          setDropdownLabel(true); //Used to removed input text
        } else {
          setInputTitle([...inputTitle]);
          setDropdownLabel(true); //Used to removed input text
        }
      }
    }
  };

  const clearMetadata = async () => {
    setInputTitle([]);
    setDropdownLabel(true);
    await clearData();
  };

  const handleInputChange = (obj, event) => {
    const newValue = event.target.value;

    setInputTitle((prevInputTitle) => {
      return prevInputTitle.map((item) => (item.name === obj.name ? { ...item, Value: newValue } : item));
    });
    handleChange(obj);
  };

  let removeValue = (index) => {
    const newInputTitle = inputTitle.filter((_, i) => i !== index);
    setInputTitle(newInputTitle);
    setDropdownLabel(true);
  };

  const loadMetadata = async (inputValue, callback) => {
    if (inputValue === '') {
      let options = [];
      options = await fieldsData
        .sort((a, b) => {
          // Convert names to lowercase for case-insensitive sorting
          const nameA = a.display_name.toLowerCase();
          const nameB = b.display_name.toLowerCase();

          if (nameA < nameB) {
            return -1; // nameA comes before nameB
          }
          if (nameA > nameB) {
            return 1; // nameA comes after nameB
          }
          return 0; // names are equal
        })
        .map((res) => {
          const roles = JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.some(
            (itm) =>
              itm.toLowerCase().includes('admin') ||
              itm.toLowerCase().includes('application administrator') ||
              itm.toLowerCase().includes('record manager') ||
              itm.toLowerCase().includes('full text search user')
          );
          if (res.display_name === 'Full Text Search' && roles === false) {
            return null;
          }

          return { label: res.display_name, value: res.display_name, obj: res };
        })
        .filter((itm) => {
          return itm !== null;
        });
      setTimeout(() => {
        callback(options);
      }, 1000);
    } else {
      // let options = []
      // options = await fieldsData.map((res) => {
      //   console.log(res)
      //   return { label: res.display_name, value: res.display_name };
      // });
      // setTimeout(() => {
      //   callback(options);
      // }, 1000)

      // let options = fieldsData.filter((ele) => ele.display_name.includes(inputValue))
      // console.log(options, inputValue)

      let dataList = fieldsData.sort((a, b) => {
        // Convert names to lowercase for case-insensitive sorting
        const nameA = a.display_name.toLowerCase();
        const nameB = b.display_name.toLowerCase();

        if (nameA < nameB) {
          return -1; // nameA comes before nameB
        }
        if (nameA > nameB) {
          return 1; // nameA comes after nameB
        }
        return 0; // names are equal
      });

      let options = dataList
        .filter((item) => item.display_name.toLowerCase().startsWith(inputValue))
        .map((obj) => ({ value: obj.display_name, label: obj.display_name, obj: obj }));

      callback(options);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '80%',
      minHeight: '80%',
      width: '100%',
    }),

    menu: (base) => ({
      ...base,
      width: '100%',
      minHeight: '20em',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',

      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    menuList: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
    }),
    option: (base, state) => ({
      ...base,
      height: 33,
      lineHeight: 'normal',
    }),
  };

  const handleSendData = () => {
    onInputTitleChange(inputTitle);
    advSearchStateChange();
  };

  // useEffect(() => {
  //   // console.log(modelRef.current);
  //   if(modelRef?.current?.backdrop?.classList.contains("show") === true) {
  //     console.log(modelRef?.current?.backdrop?.classList.contains("show"), "current");
  //   } else {
  //     console.log(modelRef?.current?.className, "bakcdrop");
  //     prevStateHandler()
  //   }
  // }, [metadataModalOpen])

  // useEffect(() => {
  //   if(metadataModalOpen) prevStateHandler();
  //   console.log(metadataModalOpen);
  // }, [metadataModalOpen])

  return (
    <>
      <Modal
        show={metadataModalOpen}
        onHide={() => {
          closeMetadatModal();
        }}
        className={[styles.metadataModal, 'glbMetadataModal'].join(' ')}
        centered
        size="lg"
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Metadata Search</h2>
            </div>
            <div className={styles.modalHeaderIcon}>
              <img
                src={crossIcon}
                alt="closeIcon"
                onClick={() => {
                  closeHandler();
                  closeMetadatModal();
                }}
              ></img>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.metadataModalBody}>
          <div>
            <div className={metadataSelection !== null ? 'form-field' : 'form-field defaultItem'}>
              <div className="ml-3 mb-1">
                <span>Metadata:</span>
              </div>

              <div className="ml-3 mr-3 mb-3">
                <AsyncSelect
                  defaultOptions
                  styles={customStyles}
                  // isClearable
                  loadOptions={loadMetadata}
                  value={
                    dropdownLabel
                      ? { label: 'Select Metadata' }
                      : dropdownData?.display_name !== ''
                      ? { label: dropdownData?.display_name, value: dropdownData?.display_name }
                      : { label: 'Select Metadata' }
                  }
                  onChange={(e) => metaDropListChange(e)}
                />
              </div>

              {/* 
              <DropDownList
                data={fieldsData}
                id="metadataSelectionDropDown1"
                value={metadataSelection}
                filterable={true}
                textField="display_name"
                dataItemKey="display_name"
                onFilterChange={metaFilterChange}
                defaultItem={defaultItem}
                //loading={this.state.loading}
                onChange={(e) => onChangeAsyncData(e)}
                onClose={onCloseList}
              //onBlur={addParam}
              /> */}

              {/* {inputTitle !== null
                ? inputTitle.map((title, index) => (
                  <div className={styles.inputDiv} key={index} style={{width: '50%'}}>
                    <div className={[styles.inputFieldSection, 'col-12'].join(' ')}>
                      <div className={styles.inputFieldInternalDiv} style={{width: '100%'}}>
                        <div className={styles.labelDiv}>
                          <label className={styles.labelName}>{title.name}</label>
                          <span className={styles.removeIcon} onClick={() => removeValue(index)}>
                            x
                          </span>
                        </div>

                        <input name={title.name} type="text" value={title.Value} onChange={(e) => handleInputChange(title, e)} />
                      </div>
                    </div>
                  </div>
                ))
                : ''} */}

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {searchFormObjArray.map((userParams) => (userParams.friendlyName !== 'Producer Identified' ? renderSpecsDiv(userParams) : null))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.metadataFooter}>
          <div className={styles.footerInternalDiv}>
            <button
              className={styles.clearBtn}
              onClick={() => {
                metaClearOnSearch();
                handleSendData();
                closeMetadatModal();
              }}
            >
              <span>Search</span>
            </button>

            <button
              className={styles.metadataBtn}
              onClick={() => {
                clearMetadata();
                // handleSendData()
              }}
            >
              Clear All Metadata
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MetaDataModal;
