import React from 'react';

export default function SuccessProcessGrowl({ isSuccessGrowlHidden, setIsSuccessGrowlHidden, message }) {
  const closeGrowl = () => {
    setIsSuccessGrowlHidden(true);
  };

  return (
    <div className="fsa-growl-container" style={{ zIndex: 9999999 }}>
      <div className="fsa-growl fsa-growl--success" id="UNIQUE-ID-8A386E512C033F57" aria-hidden={isSuccessGrowlHidden} tabIndex="0" role="dialog">
        <div className="fsa-growl__hd">
          <button
            className="fsa-growl__close"
            data-behavior="growl-dismiss"
            onClick={() => closeGrowl}
            type="button"
            title="Close Notification"
            aria-label="Close Notification"
          ></button>
        </div>
        <div className="fsa-growl__bd">
          <b>
            <p>{message}</p>
          </b>
        </div>
      </div>
    </div>
  );
}
