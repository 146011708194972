import React from 'react';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import styles from './CustomSearch.module.scss';
import PropTypes from 'prop-types';

const SearchAdminPanelComponent = ({ searchText, onSearch, clearSearch, handleSearch, placeholder }) => {
  return (
    <>
      <div className={styles.searchContent} style={{ gap: 10 }}>
        <input
          className={styles.keywordSearchInput}
          placeholder={placeholder}
          value={searchText}
          onChange={handleSearch}
        />
        <CustomButton title="Search" disabled={!searchText} className={[styles.keywordSearchBtn].join(' ')} onClick={onSearch} />

        {/* {searchText && <CustomButton title="Clear All" className={[styles.clearAllBtnStyle, 'ml-2'].join(' ')} onClick={clearSearch} />} */}
      </div>
    </>
  );
};

export default SearchAdminPanelComponent;
