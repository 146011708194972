import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import calculateSize from 'calculate-size';
import { orderBy } from '@progress/kendo-data-query';
import styles from './TableRolesTable.module.scss';
import DeleteIcon from '../../../fsa-style/img/trashIcon.svg';
import EditTableIcon from '../../../fsa-style/img/EditTableIcon.svg';

const headerStyle = (column, selectAll, handleSelectAllChange) => {
  if (column.field === 'selected') {
    return (
      <div className={styles.tableHeaderWithCheckbox}>
        <div className={styles.tableHeaderTitle}>
          <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} /> Select
        </div>
      </div>
    );
  }

  return (
    <div className={styles.tableHeaderStyle}>
      <div className={styles.tableHeaderTitle} style={{ cursor: 'default' }}>
        {column.title}
      </div>
    </div>
  );
};

export const calculateWidth = (data, field, columnName) => {
  let maxWidth = 0;
  const size = calculateSize(columnName.replaceAll('_', ' '), {
    font: 'Arial',
    fontSize: '26px',
  });
  maxWidth = size.width;
  data.forEach((item) => {
    const contentSize = calculateSize(item[field], {
      font: 'Arial',
      fontSize: '24px',
    });
    if (contentSize.width > maxWidth) {
      maxWidth = contentSize.width;
      if (maxWidth > 500) maxWidth = 500;
    }
  });
  return maxWidth;
};

export default function TableRolesTable(props) {
  const { data = [], fields, total = data.length, page, pageSize, onPageChange } = props;
  const [sort, setSort] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fields.forEach((column) => {
      return (column.sortType = ''), { ...column };
    });
  }, []);

  const onSelectSort = (column) => {
    return (
      <div className={styles.tableHeaderStyle} onClick={() => onSortData(column)}>
        <div className={styles.tableHeaderTitle}>
          <div>{column.title}</div>
          {column.showMenuIcon && <div>{column.sortType === '' ? '' : column.sortType === 'ASC' ? '⬆' : '⬇'}</div>}
        </div>
      </div>
    );
  };

  const onSortData = (column) => {
    fields.forEach((obj) => {
      if (obj.field === column.field) {
        if (obj.sortType === '') {
          obj.sortType = 'ASC';
          props.onSortData(column);
        } else if (obj.sortType === 'ASC') {
          obj.sortType = 'DESC';
          props.onSortData(column);
        } else {
          obj.sortType = '';
          props.onRemoveSort();
        }
      } else {
        obj.sortType = '';
      }
    });
    // onSelectSort(column);
  };

  useEffect(() => {
    // Set "checked" property to false for each data item initially
    if (page === 0) {
      const initialData = data.slice(0, pageSize).map((obj, idx) => ({
        ...obj,
        checked: false,
        deleteIcon: DeleteIcon,
        editIcon: EditTableIcon,
        permissions: obj.permissions.map((item) => item.name),
      }));
      setTableData(initialData);
    } else {
      const initialData = data.slice(pageSize * page, pageSize + pageSize * page).map((obj, idx) => ({
        ...obj,
        checked: false,
        deleteIcon: DeleteIcon,
        editIcon: EditTableIcon,
        permissions: obj.permissions.map((item) => item.name),
      }));
      setTableData(initialData);
    }
  }, [data, page, pageSize]);

  return (
    <>
      <Grid
        {...props}
        total={total}
        data={orderBy(tableData, sort)}
        page={page}
        pageSize={pageSize}
        skip={page * pageSize}
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 15, 20, 25],
          previousNext: true,
        }}
        onPageChange={onPageChange}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridNoRecords>No results</GridNoRecords>

        {data &&
          fields.map((column, index) => (
            <Column
              // headerCell={headerStyle}
              headerCell={() => onSelectSort(column)}
              field={column.field}
              title={column.title}
              key={index}
              cell={column.cell}
              width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
            />
          ))}
        <Column
          headerCell={headerStyle}
          field="edit"
          cell={({ dataItem }) => (
            <td>
              <div className="actionColumn">
                <img src={dataItem.editIcon} style={{ cursor: 'pointer' }} onClick={() => props.EditRowDataFunc(dataItem)} alt="editIcon"></img>
              </div>
            </td>
          )}
          title="Edit"
          width={100}
          filterable={false}
        />
        <Column
          headerCell={headerStyle}
          field="delete"
          cell={({ dataItem }) => (
            <td>
              <div className="actionColumn">
                <img
                  src={dataItem.deleteIcon}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    props.DeleteRowDataFunc(dataItem);
                  }}
                  alt="deleteIcon"
                ></img>
              </div>
            </td>
          )}
          title="Delete"
          width={100}
          filterable={false}
        />
      </Grid>
    </>
  );
}
