// import { DatePicker } from "@progress/kendo-react-dateinputs";
// import Multiselect from 'react-select';
import React, { useState } from 'react';
import './SearchUpdateFileCodeFilters.scss';
// import Moment from 'moment';
import { searchFileCodes } from "../../../../services/recordsManagementService";

export default function SearchUpdateFileCodeFilter({
    setDataItems,
    setIsSearchingFileCode,
    setFileCode
}) {

    const [primaryOrg, setPrimaryOrg] = useState(null);
    const [secondaryorg, setSecondaryOrg] = useState(null);
    const [schedId, setSchedId] = useState(null);
    const [itemId, setItemId] = useState(null);
    const [recordTitle, setRecordTitle] = useState(null);
    const [systemOfOrigin, setSystemOfOrigin] = useState(null);
    const [keywordSearch, setKeywordSearch] = useState(null);

    const dropDownStyle = {
        multiValue: (styles) => {
            const color = '#205493';
            return {
                ...styles,
                backgroundColor: color,
                color: 'white',
            };
        },
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'white',
        })
    };

    const retrieveFileCodesByCriteria = () => {
        const criteria = {};
        criteria.filecodestatus = "Active";
        if (schedId) criteria.filecodeName = schedId;
        if (itemId) criteria.filecodeId = itemId;
        if (recordTitle) criteria.recordtitle = recordTitle;
        if (systemOfOrigin) criteria.systemoforigin = systemOfOrigin;
        if (keywordSearch) criteria.keywords = keywordSearch;
        if (primaryOrg) criteria.primaryorg = primaryOrg;
        setIsSearchingFileCode(true);
        searchFileCodes(criteria).then(response => {
            setDataItems(response.data.length > 25 ? response.data.slice(0, 25) : response.data);
            setIsSearchingFileCode(false);
        });
    }

    const clearSearchCriteria = () => {
        setPrimaryOrg(null);
        setSecondaryOrg(null);
        setSchedId("");
        setItemId("");
        setRecordTitle("");
        setSystemOfOrigin("");
        setKeywordSearch("");
        setIsSearchingFileCode(true);
        setFileCode(null);
        searchFileCodes({}).then(response => {
            setDataItems(response.data.length > 25 ? response.data.slice(0, 25) : response.data);
            setIsSearchingFileCode(false);
        })
    }

    const assignPrimaryOrgCriteria = (e) => {
        setPrimaryOrg(e.target.value === "Any" ? null : e.target.value);
    }

    return (
        <div style={{ marginTop: "1em" }}>
            <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="jobStatusFilter"><b>Primary Organization</b> </label>
                <select id="primaryOrganization" value={primaryOrg ? primaryOrg : "Any"} onChange={(e) => assignPrimaryOrgCriteria(e)} style={{ minWidth: "14.1em" }} className="fsa-select" name="Primary Organization" aria-describedby="Primary Organization Dropdown">
                    <option value={null} selected="selected">Any</option>
                    <option value="FSA">SMC</option>
                    <option value="NRCS">NRCS</option>
                    <option value="RMA">RMA</option>
                    <option value="FBC">FBC</option>
                </select>
            </div>
            <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="userFilter"><b>Secondary Organization</b> </label>
                <select style={{ minWidth: "14.1em" }} className="fsa-select" name="Secondary Organization" aria-describedby="Secondary Organization Dropdown">
                    <option value="Option A" selected="selected">Any</option>
                    <option value="Option B">SMC</option>
                    <option value="Option C">NRCS</option>
                    <option value="Option D">RMA</option>
                    <option value="Option E">FBC</option>
                </select>
            </div>
            <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="scheduleIdFilter"><b>Schedule ID</b> </label>
                <input
                    value={schedId}
                    onInput={e => setSchedId(e.target.value)}
                    className="fsa-input"
                    type="text"
                    id="scheduleIdFilter"
                    aria-describedby="Schedule ID Filter"
                    aria-required="true"
                    name="scheduleIdFilter"
                />
            </div>
            <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="scheduleIdFilter"><b>Item ID</b> </label>
                <input
                    value={itemId}
                    onInput={e => setItemId(e.target.value)}
                    className="fsa-input"
                    type="text"
                    id="itemIdFilter"
                    aria-describedby="Item ID Filter"
                    aria-required="true"
                    name="itemIdFilter"
                />
            </div>
            <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="scheduleIdFilter"><b>Record Title</b> </label>
                <input
                    value={recordTitle}
                    onInput={e => setRecordTitle(e.target.value)}
                    className="fsa-input"
                    type="text"
                    id="recordTitleFilter"
                    aria-describedby="Record Title Filter"
                    aria-required="true"
                    name="recordTitleFilter"
                />
            </div>
            <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="scheduleIdFilter"><b>System of Origin</b> </label>
                <input
                    value={systemOfOrigin}
                    onInput={e => setSystemOfOrigin(e.target.value)}
                    className="fsa-input"
                    type="text"
                    id="systemOfOriginFilter"
                    aria-describedby="System of Origin Filter"
                    aria-required="true"
                    name="systemOfOriginFilter"
                />
            </div>
            <div className="fsa-field mt-4 updateFilterInput">
                <label className="fsa-field__label" htmlFor="docTypeFilter"><b>Keyword Search</b> </label>
                <input
                    value={keywordSearch}
                    onInput={e => setKeywordSearch(e.target.value)}
                    className="fsa-input"
                    type="text"
                    id="keywordSearchFilter"
                    aria-describedby="Keyword Search Filter"
                    aria-required="true"
                    name="keywordSearchFilter"
                />
            </div>
            <button
                style={{
                    marginTop: "1em",
                    marginRight: "10px"
                }}
                className="fsa-btn"
                onClick={() => retrieveFileCodesByCriteria()}
            >
                Search
            </button>
            <button
                style={{ marginTop: "1em" }}
                className="fsa-btn fsa-btn--secondary "
                onClick={() => clearSearchCriteria()}
            >
                Clear
            </button>


        </div>
    );
};