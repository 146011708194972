import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './UpdateTransferModal.module.scss';
import '../../../../GlobalStyle.scss';
import crossIcon from '../../../../fsa-style/img/crossIcon.svg';
import ResizeIcon from '../../../../fsa-style/img/svgs/ResizeIcon.svg';
import { Field, FieldWrapper, Form } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import Table from "./Table/Table";
import UpdateTransferFields from './UpdateTransferFields.json'

const UpdateTransferModal = (props) => {
    const [isRequiredError, setisRequiredError] = useState(false);
    const { handleUpdateTransfer } = props

    // const { onClose } = props;
    const initialValues = {}
    const transferData = [
        {
            branch: 'myBranch',
            transferType: 'NARA',
            transferName: 'myTransferName',
            NARATransferNumber: 'ABC123',
            agencyTransferNumber: 'XFR-YYYY-nnnnnn',
            generalDescription: 'myDescription',
            fileCode: 'DEF456',
            username: 'myUsername',
            creationDate: new Date(),
            dateOfTransfer: new Date(),
            transferMedia: 'Removable Media',
            transferFileSize: 0,
            includeFullTextFiles: false,
            transferAcceptanceDate: new Date()
        },
        {
            branch: 'myBranch',
            transferType: 'NARA',
            transferName: 'myTransferName',
            NARATransferNumber: 'ABC123',
            agencyTransferNumber: 'XFR-YYYY-nnnnnn',
            generalDescription: 'myDescription',
            fileCode: 'DEF456',
            username: 'myUsername',
            creationDate: new Date(),
            dateOfTransfer: new Date(),
            transferMedia: 'Removable Media',
            transferFileSize: 0,
            includeFullTextFiles: false,
            transferAcceptanceDate: new Date()
        },
        {
            branch: 'myBranch',
            transferType: 'NARA',
            transferName: 'myTransferName',
            NARATransferNumber: 'ABC123',
            agencyTransferNumber: 'XFR-YYYY-nnnnnn',
            generalDescription: 'myDescription',
            fileCode: 'DEF456',
            username: 'myUsername',
            creationDate: new Date(),
            dateOfTransfer: new Date(),
            transferMedia: 'Removable Media',
            transferFileSize: 0,
            includeFullTextFiles: false,
            transferAcceptanceDate: new Date()
        }
    ]

    const [selectedTransfer, setSelectedTransfer] = useState(null);

    const onRowClick = (e) => {
        setSelectedTransfer(e);
    }

    const onSubmit = () => {
        // onClose()
    }


    return (
        <Modal
            {...props}
            className={[styles.updateTransfer, 'glbUpdateTransferModalStyle'].join(' ')}
            backdropClassName="orc-modal-custom-backdrop"
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
            onHide={() => {
                props.onHide();
            }}
        >
            <div>
                <Modal.Header>
                    <div className={styles.modalHeader}>
                        <div>
                            <b className={styles.headingText}>Update Object Transfer</b>
                        </div>
                        <div
                            className={styles.modalHeaderIcon}

                        >
                            <img className={styles.resizeIcon} src={ResizeIcon} alt='resize icon' />
                            <img src={crossIcon} alt="cross icon" onClick={() => {
                                handleUpdateTransfer(false);
                                setisRequiredError(false);
                            }} />

                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className={styles.updateTransferModalBody}>
                   
                        <Form
                            initialValues={initialValues}
                            render={(formRenderProps) => {
                                return (
                                    <div className={'row'}>
                                        <div className={'col-3'}>
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <h3 style={{ marginTop: 0 }}>Search File Codes</h3>
                                                    {/* <div className="fsa-divider" /> */}
                                                </div>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <FieldWrapper className={'col-12'}>
                                                    <label className="fsa-field__label">Username</label>
                                                    <Field name={'username'} className="fsa-input fsa-field__item" component={Input} />
                                                </FieldWrapper>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <FieldWrapper className={'col-12'}>
                                                    <label className="fsa-field__label">Transfer Name</label>
                                                    <Field name={'transferName'} className="fsa-input fsa-field__item" component={Input} />
                                                </FieldWrapper>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <FieldWrapper className={'col-12'}>
                                                    <label className="fsa-field__label">Agency Transfer Name</label>
                                                    <Field name={'agencyTransferNumber'} className="fsa-input fsa-field__item" component={Input} />
                                                </FieldWrapper>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <FieldWrapper className={'col-12'}>
                                                    <label className="fsa-field__label">NARA Provided Transfer Number</label>
                                                    <Field name={'NARATransferNumber'} className="fsa-input fsa-field__item" component={Input} />
                                                </FieldWrapper>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <FieldWrapper className={'col-12'}>
                                                    <label className="fsa-field__label">File Code</label>
                                                    <Field name={'fileCode'} className="fsa-input fsa-field__item" component={Input} />
                                                </FieldWrapper>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <FieldWrapper className={'col-12'}>
                                                    <label className="fsa-field__label">Hold Number</label>
                                                    <Field name={'holdNumber'} className="fsa-input fsa-field__item" component={Input} />
                                                </FieldWrapper>
                                            </div>
                                            <div className={'row mt-4'}>
                                                <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                                                    <button className="fsa-btn">Search</button>
                                                    <button onClick={formRenderProps.onFormReset} className="fsa-btn lite-btn">Clear</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'col-9'} style={{ borderLeft: "1px solid #D9D9D9 " }}>
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <h3 style={{ marginTop: 0 }}>Hold Search Results</h3>
                                                    {/* <div className="fsa-divider" /> */}
                                                </div>
                                            </div>
                                            <div className={'row mt-3'}>
                                                <div className={'col-12'} >
                                                    <Table
                                                        data={transferData}
                                                        fields={UpdateTransferFields}
                                                        onRowClick={onRowClick}
                                                        selectType={'radio'}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'row mt-4'}>
                                                <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                                                    <button onClick={onSubmit} className="fsa-btn">Submit</button>
                                                    <button onClick={() => handleUpdateTransfer(false)} className="fsa-btn lite-btn">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                        />


                </Modal.Body>
                {/* <Modal.Footer className={styles.updateTransferFooter}>
                    <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>


                        {props.isNew ? (
                            <CustomButton title="Save" className={styles.saveRecordBtn} />
                        ) : (
                            <CustomButton title="Update" className={styles.saveRecordBtn} />
                        )}

                        <CustomButton
                            title="Cancel"
                            className={styles.cancelRecordBtn}
                            onClick={() => {
                                props.setShowUpdateHold(false);
                                setisRequiredError(false);
                            }}
                        />
                    </div>
                </Modal.Footer> */}
            </div>
        </Modal>

    );
};

export default UpdateTransferModal;