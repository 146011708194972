import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './UserInfoModal.module.scss';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import { updateUserPreference } from '../UserPreference/UserPreferenceService';
import { handleIsRequiredError } from '../../../utils/helper/handleReuiredErrorFunc';

const EditUserInfo = (props) => {
  const [isRequiredError, setIsRequiredError] = useState(false);
  const customStyles2 = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadFolderList = async (inputValue, callback) => {
    let constData = [2, 3, 4, 5, 6, 7, 8, 9];
    let options = await constData.map((res) => {
      return { label: res, value: res };
    });

    callback(options);
  };

  const onChangeFolderLevel = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        defaultSearchGroupLevel: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      defaultSearchGroupLevel: updatedValue,
    }));
  };
  const handleUpdate = async () => {
    let payload = {
      defaultSearchGroupLevel: props.formData.defaultSearchGroupLevel,
      id: props.formData.id,
    };

    if (!props.formData.defaultSearchGroupLevel) {
      setIsRequiredError(true);
      return;
    }
    props.isLoading(true);

    await updateUserPreference(payload).then((response) => {
      if (response.data) {
        props.isLoading(false);
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('User Profile updated successfully');
        props.setModalShow(false);

        let userData = JSON.parse(sessionStorage.getItem('user_information'));
        userData.userPreference = response.data;
        sessionStorage.setItem('user_information', JSON.stringify(userData));
        props.refreshData();

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        props.isLoading(false);
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error in update profile');
      }
    });
    // }
  };

  return (
    <Modal
      {...props}
      className={[styles.userInfoModal, 'glbUserInfoModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      ariaLabelledBy="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Edit User Profile</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setModalShow(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.userInfoModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>
                  Context Id <span className={styles.mandatoryField}> *</span>
                </label>
                <AsyncSelect
                  styles={customStyles2}
                  isClearable
                  isDisabled
                  value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                />
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>
                  User Name <span className={styles.mandatoryField}> *</span>
                </label>
                <input className={styles.disabledInput} disabled name="userName" value={props.formData?.userName} />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>
                  First Name <span className={styles.mandatoryField}> *</span>
                </label>
                <input name="firstName" className={styles.disabledInput} value={props.formData?.firstName} disabled />
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>
                  Last Name <span className={styles.mandatoryField}> *</span>
                </label>
                <input name="lastName" className={styles.disabledInput} value={props.formData?.lastName} disabled />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>
                  Email <span className={styles.mandatoryField}> *</span>
                </label>
                <input className={styles.disabledInput} name="email" value={props.formData?.email} disabled />
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Default Search Folder Level</label>
                <AsyncSelect
                  styles={customStyles2}
                  isClearable
                  loadOptions={loadFolderList}
                  value={
                    props?.formData?.defaultSearchGroupLevel !== ''
                      ? { label: props?.formData?.defaultSearchGroupLevel, value: props?.formData?.defaultSearchGroupLevel }
                      : { label: '---Select---' }
                  }
                  onChange={onChangeFolderLevel}
                />
                {(props?.formData?.defaultSearchGroupLevel === null || props?.formData?.defaultSearchGroupLevel === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                <label>
                  Role Name(s) <span className={styles.mandatoryField}> *</span>
                </label>
                <AsyncSelect
                  isMulti
                  styles={customStyles2}
                  isClearable
                  defaultOptions
                  value={props?.formData?.roleName?.map((role) => ({
                    label: role,
                    value: role,
                  }))}
                  isDisabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.userInfoFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleUpdate} />

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setModalShow(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default EditUserInfo;
