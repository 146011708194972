import React, { useEffect, useState, useRef, useContext } from 'react';
import '../FileCode/FileCode.scss';
import { Window } from '@progress/kendo-react-dialogs';
import TransferFields from './TransferFields.json';
import Table from '../Table/Table';
import KeywordSearch from '../search/KeywordSearch';
import TransferForm from './TransferForm';
import SearchTransferForm from './SearchTransferForm';
import UserContext from '../UserContext/UserContext';
import { createTransfer, getTransfer, updateTransfer } from '../../services/transferServices';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import EditTransfer from './EditTransfer';

// TODO use transfer data. Currently using mock data for display and POC
export default function TransferManagement() {
  const [isTransferFormOpen, setTransferFormOpen] = useState(false);
  const [modalLocation, setModalLocation] = useState();
  const elementRef = useRef();
  const [selected, setSelected] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const { userPermissions } = React.useContext(UserContext);
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [createTransferOpen, setCreateTransferOpen] = useState(false);
  const [editTransferOpen, setEditTransferOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getTransferData();

    if (elementRef) {
      const divElement = elementRef?.current?.getBoundingClientRect();
      setModalLocation(divElement);
    }
    if (searchText.length === 0) {
      getTransferData();
    }
  }, [page, pageSize]);

  useEffect(() => {
    getTransferData();
  }, [searchText]);

  const getTransferData = () => {
    setLoading(true);
    let payload = {};
    if (searchText !== '') payload.transferName = searchText;
    getTransfer(payload).then((response) => {
      setData(response);
      setLoading(false);
    });
  };

  const handleKeywordSearch = async (keywords) => {
    setSearchText((prevData) => keywords);
  };

  const handleCreateModal = () => {
    setCreateTransferOpen(!createTransferOpen);
    setSelected(null);
  };
  const handleEditModal = () => {
    setEditTransferOpen(!editTransferOpen);
  };

  const onRowClick = (e) => {
    setSelected(e.dataItem);
    handleEditModal();
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    const newPage = page.skip / page.take;
    setPage(newPage);
  };

  const onSearch = (data) => {
    // TODO search transfer
    console.log('filtering transfer', data);
    let payload = {};
    Object.keys(data).forEach((key) => {
      console.log(data[key], 'key');
      if (data[key] !== '') {
        payload[key] = data[key];
      }
    });
    setSelectedFilters(data);
    getTransfer(payload).then((response) => {
      setData(response);
      setLoading(false);
    });
  };

  const onClearFilter = () => {
    setSelectedFilters({});
    getTransferData({});
  };

  const saveTransfer = (data) => {
    // TODO save new or existing transfer
    setLoading(true);

    console.log('saving transfer', data);
    const userInfo = JSON.parse(sessionStorage.getItem('user_information'));
    const config = {
      headers: {
        'Content-Type': 'application/json',
        userName: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.userName : '',
        usdaeauthid: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.usdaeauthid : '',
        Authorization: sessionStorage.getItem('userToken'),
      },
    };

    createTransfer(data, config).then((response) => {
      console.log(response, 'res');
      if (response.data) {
        setMessage('New Transfer created successfully');
        setIsSuccessGrowlHidden(false);
        handleCreateModal();
        getTransferData();
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
        setLoading(false);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setLoading(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');
        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
      }
    });
  };
  const updateTransferFunc = async (data) => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('userJwt'),
      userName: userInfo.userName,
      usdaeauthid: userInfo.usdaeauthid,
    };

    if (data.holdNumber === '') delete data.holdNumber;

    await updateTransfer(data, headers).then((response) => {
      if (response.data) {
        setMessage('Transfer updated successfully');
        setIsSuccessGrowlHidden(false);
        handleEditModal();
        getTransferData();
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
        setLoading(false);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setLoading(false);

        setMessage(response.error.message ? response.error.message : 'Something went wrong');
        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
      }
    });
  };

  return (
    <div className="content">
      <div className="pageContent">
        {loading && <GlobalLoader />}
        <h1 className="HeaderOne-StyleOne">Manage Transfers</h1>
        <div className="flex">
          <KeywordSearch handleKeywordSearch={handleKeywordSearch} elementRef={elementRef} modalLocation={modalLocation}>
            <SearchTransferForm onSubmit={onSearch} onClose={getTransferData} onClear={onClearFilter} existingTransfer={selectedFilters} />
          </KeywordSearch>

          <div className="fc-grid-head-row">
            <div className="h3-div-2">Transfer Search Results</div>
            {userPermissions['Create_Transfer'] === true && (
              <div className="form-field">
                <button className="fsa-btn lite-btn" onClick={handleCreateModal}>
                  Create Transfer
                </button>
              </div>
            )}
          </div>

          {createTransferOpen && (
            <Window
              minimizeButton={() => null}
              maximizeButton={() => null}
              title={'Create Transfer'}
              onClose={handleCreateModal}
              initialHeight={600}
              initialWidth={900}
              restoreButton={() => null}
              modal={true}
              draggable={false}
              className="glbModalHeader"
            >
              <TransferForm existingTransfer={selected} onClose={handleCreateModal} onSubmit={saveTransfer} onUpdate={updateTransferFunc} />
            </Window>
          )}
          {editTransferOpen && (
            <Window
              minimizeButton={() => null}
              maximizeButton={() => null}
              title={'Edit Transfer'}
              onClose={handleEditModal}
              initialHeight={660}
              initialWidth={900}
              restoreButton={() => null}
              modal={true}
              draggable={false}
              className="glbModalHeader"
            >
              <EditTransfer existingTransfer={selected} onClose={handleEditModal} onSubmit={saveTransfer} onUpdate={updateTransferFunc} />
            </Window>
          )}
        </div>
        <Table
          data={data}
          fields={TransferFields}
          onRowClick={onRowClick}
          onSubmit={saveTransfer}
          onPageChange={onPageChange}
          page={page}
          pageSize={pageSize}
          total={data.length}
        />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
    </div>
  );
}
