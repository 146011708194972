import React, { useState, useRef, useEffect } from 'react';
import styles from './AliceSenseTab.module.scss';
import Sad from '../../../fsa-style/img/svgs/sentimental_analysis/sad.svg';
import Fear from '../../../fsa-style/img/svgs/sentimental_analysis/angry-face.svg';
import Joy from '../../../fsa-style/img/svgs/sentimental_analysis/smile.svg';
import Close from '../../../fsa-style/img/svgs/sentimental_analysis/close.svg';
import Anger from '../../../fsa-style/img/svgs/sentimental_analysis/angry.svg';
import Truthful from '../../../fsa-style/img/svgs/sentimental_analysis/Icon.svg';
import Disgust from '../../../fsa-style/img/svgs/sentimental_analysis/confused.svg';
import Neutral from '../../../fsa-style/img/svgs/sentimental_analysis/neutral.svg';
// import Grade from '../../fsa-style/img/svgs/sentimental_analysis/Group-3095.svg';
import Surprise from '../../../fsa-style/img/svgs/sentimental_analysis/surprised.svg';
import Expand from '../../../fsa-style/img/svgs/sentimental_analysis/expand.svg';
import Minimize from '../../../fsa-style/img/svgs/sentimental_analysis/minimize.svg';
// import Indicator from '../../fsa-style/img/svgs/sentimental_analysis/indicator.svg';
import English from '../../../fsa-style/img/svgs/sentimental_analysis/united-states.svg';
import VerticleProgressBar from '../../../components/VerticleProgressBar/ProgressBar';
import NotTruthfulIcon from '../../../fsa-style/img/svgs/sentimental_analysis/truthful-circle.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setAliceHistory } from '../../../services/actions/action';
import { v4 as uuidv4 } from 'uuid';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
// import AliceSenseData from './AliceSense.json';
import { getSentimentData, getPdfOcr, addNewQuetion, failedSentimentCall } from '../../../services/inBasketService';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// const AliceSenseData = {
//   AliceSense: 'ITEM G-28 (11984771)',
//   Category: 'Constituent Correspondence',
// };

let sentimentData = [
  { columnKey: 'Sentiment', columnValue: 'Joy', confidenceLevel: '0', imgpath: Joy, opacity: '0.2' },
  { columnKey: 'Sentiment', columnValue: 'Surprise', confidenceLevel: '0', imgpath: Surprise, opacity: '0.2' },
  { columnKey: 'Sentiment', columnValue: 'Neutral', confidenceLevel: '0', imgpath: Neutral, opacity: '0.2' },
  { columnKey: 'Sentiment', columnValue: 'Sadness', confidenceLevel: '0', imgpath: Sad, opacity: '0.2' },
  { columnKey: 'Sentiment', columnValue: 'Anger', confidenceLevel: '0', imgpath: Anger, opacity: '0.2' },
  { columnKey: 'Sentiment', columnValue: 'Disgust', confidenceLevel: '0', imgpath: Disgust, opacity: '0.2' },
  { columnKey: 'Sentiment', columnValue: 'Fear', confidenceLevel: '0', imgpath: Fear, opacity: '0.2' },
];

const AliceSenseTab = ({ docNumber }) => {
  const { aliceHistory } = useSelector((state) => state);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState(aliceHistory);
  const [sentimentDetails, setSentimentDetails] = useState(sentimentData);
  const [selectedSentiment, setSelectedSentiment] = useState('');
  const [gradeList, setGradeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [truthfulList, setTruthfulList] = useState([]);
  const [summaryList, setSummaryList] = useState([]);
  const [highestSentimentVal, setHighestSentimentVal] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const messageEndRef = useRef(null);
  const dispatch = useDispatch();

  const handleEnterButtonCLick = (e) => {
    if (e.key === 'Enter') {
      handleChatButtonClick();
    }
  };

  const handleChatButtonClick = () => {
    const newChatMessage = {
      sender: 'user',
      message: chatMessages,
      timestamp: new Date().toISOString(),
    };

    setChatHistory((prevChatHistory) => [...prevChatHistory, newChatMessage]);

    setLoadingButton(true);
    const str = docNumber;
    const regex = /\d+/;
    if (!str) return;
    const match = str.match(regex);
    const numericValue = match ? parseInt(match[0]) : null;
    // setTimeout(() => {
    let documentsPayload = {
      documentId: numericValue,
    };
    getPdfOcr(documentsPayload).then((documentResponse) => {
      setChatMessages('');
      // Quetion
      const newChatMessage = {
        sender: 'user',
        message: chatMessages,
        timestamp: new Date().toISOString(),
      };
      // setChatHistory((prevChatHistory) => [...prevChatHistory, newChatMessage]);

      let payload = {
        question: chatMessages,
        context: documentResponse.data,
      };
      addNewQuetion(payload).then((response) => {
        console.log(addNewQuetion, response);
        if (response) {
          // setChatMessages('');
          // Answer
          const randomResponse = response;
          const newAliceMessage = {
            sender: 'Alice',
            message: randomResponse.data.body.answer,
            timestamp: new Date().toISOString(),
          };

          setChatHistory((prevChatHistory) => [...prevChatHistory, newAliceMessage]);
          setLoadingButton(false);
        }
      });
    });
    // }, 2000)
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setChatMessages(userInput);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView();
    if (chatHistory.length !== aliceHistory.length) {
      dispatch(setAliceHistory(chatHistory));
    }
  }, [chatHistory]);

  useEffect(() => {
    const str = docNumber;
    const regex = /\d+/;
    if (!str) return;
    const match = str.match(regex);
    const numericValue = match ? parseInt(match[0]) : null;
    async function data() {
      let payload = {
        id: numericValue,
      };
      setIsLoading(true);
      await getSentimentData(payload)
        .then(async (response) => {
          if (response.data) {
            let list = response.data;
            if (list) await calculateSentimentAnalysis(list);
            setIsLoading(false);
          } else if (response.error) {
            failedSentimentCall(payload)
              .then(async (res) => {
                console.log(res);
                let list1 = res.data;
                if (list1) await calculateSentimentAnalysis(list1);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.message);
        });
    }
    setChatHistory([]);
    data();
  }, []);

  const calculateSentimentAnalysis = async (list) => {
    let sortedList, differnce, data, secontSortedItem, sortedListColVal;

    const sentimentList = list.filter((obj) => obj.columnKey === 'Sentiment');
    const gradeList = list.filter((obj) => obj.columnKey === 'Grade');
    const languageList = list.filter((obj) => obj.columnKey === 'Language');
    const truthfulList = list.filter((obj) => obj.columnKey === 'Truthfulness' || obj.columnKey === 'Truthfullness' || obj.columnKey === 'Truthful');
    const summaryList = list.filter((obj) => obj.columnKey === 'Summary');

    setGradeList(gradeList);
    setLanguageList(languageList);
    setTruthfulList(truthfulList);
    setSummaryList(summaryList);

    let newSortedData = JSON.parse(JSON.stringify(sentimentList));

    sortedList = newSortedData.sort((firstItem, secondItem) => secondItem?.confidenceLevel - firstItem?.confidenceLevel);

    if (sortedList.length === 1) {
      secontSortedItem = 0;
    } else {
      secontSortedItem = sortedList[1]?.confidenceLevel;
    }
    differnce = sortedList[0]?.confidenceLevel * 100 - secontSortedItem * 100;
    setHighestSentimentVal(sortedList[0]?.confidenceLevel);

    let glbSentimentData = sentimentDetails.map((defaultObj, index) => {
      sentimentList.map((responseObj, i) => {
        if (responseObj.columnKey === 'Sentiment') {
          if (responseObj.columnValue !== null) {
            let capitalColVal = responseObj.columnValue.charAt(0).toUpperCase() + responseObj.columnValue.slice(1);
            if (defaultObj.columnValue === capitalColVal) {
              if (responseObj.confidenceLevel <= 1 && responseObj.confidenceLevel > 0) {
                defaultObj.confidenceLevel = (responseObj.confidenceLevel * 100).toFixed(2);
              } else {
                defaultObj.confidenceLevel = responseObj.confidenceLevel;
              }

              // Set Opacity
              sortedListColVal = sortedList[0].columnValue.charAt(0).toUpperCase() + sortedList[0].columnValue.slice(1);
              if (differnce <= 10) {
                if (sortedList.length !== 1) {
                  if (sortedListColVal === defaultObj.columnValue) defaultObj.opacity = '1';
                  else if (sortedList[1]?.columnValue === defaultObj.columnValue.toLocaleLowerCase()) defaultObj.opacity = '1';
                  else if (responseObj.confidenceLevel === '0') defaultObj.opacity = '0.2';
                  else defaultObj.opacity = '0.5';
                } else {
                  if (sortedListColVal === defaultObj.columnValue) {
                    defaultObj.opacity = '1';
                  } else {
                    defaultObj.opacity = '0.2';
                  }
                }
              } else {
                if (sortedListColVal === defaultObj.columnValue) defaultObj.opacity = '1';
                else if (responseObj.confidenceLevel === '0') defaultObj.opacity = '0.2';
                else defaultObj.opacity = '0.5';
              }
            }
          } else {
            defaultObj.confidenceLevel = 0;
            defaultObj.opacity = '0.2';
          }
        }
      });
      return { ...defaultObj };
    });

    // Set sentiment title
    if (differnce <= 10) {
      if (sortedList.length === 1) {
        setSelectedSentiment(sortedList[0]?.columnValue);
      } else {
        setSelectedSentiment(sortedList[0]?.columnValue + '/' + sortedList[1]?.columnValue);
      }
    } else {
      setSelectedSentiment(sortedList[0]?.columnValue);
    }

    data = await Promise.all(glbSentimentData);
    setSentimentDetails(data);
    setIsLoading(false);
  };
  return (
    <div style={{ overflow: 'hidden', overflowX: 'auto' }}>
      {isLoading ? (
        <GlobalLoader
          text={
            <>
              SENSE - β is analyzing the file.
              <br />
              Please wait....
            </>
          }
        />
      ) : (
        <div style={{ background: '#fff', borderRadius: '9px' }}>
          <div className={styles.aliceSenseTabBodyContent}>
            <div className={styles.aliceSenseBodyPanel}>
              <div>
                <h4 className={[styles.aliceSenseHeadingFont, 'm-0'].join(' ')}>Summary:</h4>
              </div>
              <div className={styles.aliceSenseNormalFont}>{summaryList && summaryList[0]?.columnValue}</div>
            </div>
            <div className={styles.aliceSenseBodyPanel}>
              <div className="d-flex justify-content-between col-md-12 p-0 m-0">
                <div className="col-md-12 p-0 m-0">
                  <div className="d-flex justify-content-between">
                    <h4 className={[styles.aliceSenseHeadingFont, 'm-0 pb-5 text-capitalize'].join(' ')}>Sentiment: {selectedSentiment}</h4>
                    <div>
                      <div className={styles.aliceBodyPanelIndicatorIcon}>
                        {highestSentimentVal <= 1 && highestSentimentVal > 0 ? (
                          <VerticleProgressBar value={parseInt(highestSentimentVal * 100)} />
                        ) : (
                          <VerticleProgressBar value={parseInt(highestSentimentVal)} />
                        )}
                      </div>
                      <div className="modal-body-custom-font text-center" style={{ fontFamily: 'sans-serif', fontSize: '9px', fontWeight: '700', color: '#57931B' }}>
                        Confidence
                      </div>
                    </div>
                  </div>
                  <div className={styles.bodyPanelThirdIcons}>
                    {sentimentDetails?.map((obj, i) => {
                      return (
                        <div className={[styles.bodyPanelFaceIcon, 'm-2'].join(' ')}>
                          <div>
                            <img src={obj?.imgpath} alt={obj?.columnValue} style={{ opacity: obj.opacity }} />
                          </div>
                          <div className={styles.senseBodyTinyFont}>
                            {obj?.columnValue} <p className="m-0 p-0">({obj?.confidenceLevel}%)</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.aliceBodyPanelLast}>
              <div>
                <h4 className={[styles.aliceSenseHeadingFont, 'm-0 mb-2'].join(' ')}>Language:</h4>
                <div className="d-flex">
                  <div className={[styles.aliceBodyPanelFlagIcon, 'm-2 mr-5'].join(' ')}>
                    <div>
                      <img src={English} alt="English" />
                    </div>
                    <div className={styles.senseBodyTinyFont}>{languageList && languageList[0]?.columnValue}</div>
                  </div>
                  <div className={[styles.aliceBodyPanelCircleIcon, 'm-2'].join(' ')}>
                    <div className="d-flex justify-content-center">
                      {gradeList.length !== 0 && (
                        <div style={{ width: 40, height: 40 }}>
                          <CircularProgressbar
                            value={gradeList[0]?.columnValue}
                            maxValue={16}
                            strokeWidth={20}
                            styles={{
                              path: {
                                stroke: '#31A2F9',
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.senseBodyTinyFont}>Grade {gradeList && gradeList[0]?.columnValue}</div>
                  </div>
                </div>
              </div>
              <div>
                <h4 className={[styles.aliceSenseHeadingFont, 'm-0 mb-2'].join(' ')}>Truthfulness:</h4>
                <div className="d-flex justify-content-between">
                  <div className="mr-5">
                    <div className={[styles.aliceBodyPanelCircleIcon, 'm-2'].join(' ')}>
                      {truthfulList &&
                      (truthfulList[0]?.columnValue === 'Truthful' ||
                        truthfulList[0]?.columnValue === 'Truthfullness' ||
                        truthfulList[0]?.columnValue === 'Truthfulness' ||
                        truthfulList[0]?.columnValue === 'Probably True' ||
                        truthfulList[0]?.columnValue === 'True') ? (
                        <img src={Truthful} alt="Truthful" />
                      ) : (
                        <img src={NotTruthfulIcon} alt="Truthful" />
                      )}
                    </div>
                    <div className={styles.senseBodyTinyFont}>{truthfulList && truthfulList[0]?.columnValue}</div>
                  </div>
                  <div className="mt-2">
                    <div className={[styles.aliceBodyPanelIndicatorIcon, 'm-2'].join(' ')}>
                      {/* <img src={Indicator} alt="Indicator" /> */}
                      {truthfulList.length !== 0 &&
                        (truthfulList[0]?.confidenceLevel <= 1 && truthfulList[0]?.confidenceLevel > 0 ? (
                          <VerticleProgressBar value={parseInt(truthfulList[0]?.confidenceLevel * 100)} />
                        ) : (
                          <VerticleProgressBar value={parseInt(truthfulList[0]?.confidenceLevel)} />
                        ))}
                      {/* <VerticleProgressBar value={truthfulList && parseInt(truthfulList[0]?.confidenceLevel)} /> */}
                    </div>
                    <div className="modal-body-custom-font" style={{ fontFamily: 'sans-serif', fontSize: '9px', fontWeight: '700', color: '#57931B' }}>
                      Confidence
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.AliceChatContainer}>
              <div className={styles.aliceBodyChatPanel}>
                {chatHistory !== null && (
                  <>
                    {chatHistory.map((message, index) => (
                      // <div key={uuidv4()} className={`chat-message ${message.sender === 'user' ? 'user-message' : 'alice-message'}`}>
                      <div key={uuidv4()} className={[styles.chatMessage, `${message.sender === 'user' ? styles.userMessage : styles.aliceMessage}`].join(' ')}>
                        <div className={styles.messageBubble}>{message.message}</div>
                      </div>
                    ))}
                  </>
                )}
                <div ref={messageEndRef}></div>
              </div>
            </div>
            <div className={styles.footerContent}>
              <div className={styles.ovalInputContainer}>
                <input
                  type="text"
                  className={styles.ovalInput}
                  value={chatMessages}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    handleEnterButtonCLick(e);
                  }}
                  placeholder="Type message..."
                  disabled={loadingButton ? true : false}
                />
                {loadingButton ? (
                  <button className={windowWidth < 1280 ? styles.ovalButtonMedia : styles.ovalButton} style={{ cursor: 'default', opacity: 0.8 }} disabled={true}>
                    Loading...
                  </button>
                ) : (
                  <button
                    className={
                      !chatMessages.length
                        ? windowWidth < 1280
                          ? styles.disabledOvalButtonMedia
                          : styles.disabledOvalButton
                        : windowWidth < 1280
                        ? styles.ovalButtonMedia
                        : styles.ovalButton
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={handleChatButtonClick}
                    disabled={!chatMessages.length}
                  >
                    Ask Alice
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AliceSenseTab;
